import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { AssetImportableModel, AssetModel } from '@asset/models/asset.model';

@Component({
  selector: 'zero-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageHeaderComponent {
  @Input() asset?: AssetModel | AssetImportableModel;
  @Output() navigateBack: EventEmitter<void> = new EventEmitter<void>();
}
