import { Component, Input } from '@angular/core';
import { UtilsService } from '@core/services/utils.service';
import { SystemName } from '@asset/enums/system-name';

@Component({
  selector: 'zero-system-chip',
  templateUrl: './system-chip.component.html',
  styleUrls: ['./system-chip.component.scss'],
})
export class SystemChipComponent {
  @Input() name!: SystemName;
  @Input() scope = false;
  @Input() selectable = false;

  constructor(public utils: UtilsService) {}
}
