import { InjectionToken, inject } from '@angular/core';
import { DDB_BASEURL } from '@ddb/tokens/ddb-baseurl.token';

export const USERS_ROLES_ENDPOINT = new InjectionToken<string>(
  'USERS_ROLES_ENDPOINT',
  {
    providedIn: 'root',
    factory: () => `${inject(DDB_BASEURL)}/users_roles`,
  },
);
