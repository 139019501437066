<mgt-people-picker
  #peoplePicker
  [showMax]="10"
  [placeholder]="placeholder"
  [selectionMode]="selectionMode"
  (selectionChanged)="handleSelectionChanged($event)"
  type="person"
  user-type="user"
>
</mgt-people-picker>
