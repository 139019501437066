import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SystemName } from '@asset/enums/system-name';
import { SubsystemName } from '@core/models/subsystem';
import { UtilsService } from '@core/services/utils.service';

@Component({
  selector: 'zero-key[name]',
  templateUrl: './key.component.html',
  styleUrls: ['./key.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyComponent {
  @Input() name!: SystemName | SubsystemName;
  @Input() scope = false;

  constructor(private readonly utilsService: UtilsService) {}

  readonly safeCSSClassName = this.utilsService.safeCSSClassName;
}
