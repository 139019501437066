import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthStore } from '@core/state/auth.store';

@Component({
  selector: 'zero-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
  constructor(private readonly authStore: AuthStore) {}

  public handleLoginClick(): void {
    this.authStore.login();
  }
}
