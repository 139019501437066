import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { AnyCarbonStage } from '@core/types/any-carbon-stage';
import { UtilsService } from '@core/services/utils.service';
import { map } from 'rxjs/operators';

interface CarbonParamTableHeaderStateModel {
  totalCarbonParameters: AnyCarbonStage[];
}

@Component({
  selector: 'zero-carbon-table-header',
  templateUrl: './carbon-table-header.component.html',
  styleUrls: ['./carbon-table-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ComponentStore],
})
export class CarbonTableHeaderComponent {
  @Input() set totalCarbonParameters(value: AnyCarbonStage[] | null) {
    if (value) this.setCarbonParameters(value);
  }

  carbonParameters$ = this.componentStore
    .select(({ totalCarbonParameters }) => totalCarbonParameters)
    .pipe(map(this.utilsService.carbonParameters));

  constructor(
    private componentStore: ComponentStore<CarbonParamTableHeaderStateModel>,
    public utilsService: UtilsService,
  ) {
    this.componentStore.setState({ totalCarbonParameters: [] });
  }

  private readonly setCarbonParameters = this.componentStore.updater<
    AnyCarbonStage[]
  >((state, value) => ({
    ...state,
    totalCarbonParameters: value,
  }));
}
