import * as i0 from '@angular/core';
import { Injectable, Component, NgModule } from '@angular/core';
import { PropertiesPluginIdentifier, AnalyticsPluginIdentifier } from '@microsoft/applicationinsights-common';
import { isFunction, arrForEach, BaseTelemetryPlugin, getLocation, generateW3CId, _throwInternal } from '@microsoft/applicationinsights-core-js';
import { NavigationEnd } from '@angular/router';
class ApplicationinsightsAngularpluginErrorService {
  constructor() {
    this.errorServices = [];
    if (ApplicationinsightsAngularpluginErrorService.instance === null) {
      ApplicationinsightsAngularpluginErrorService.instance = this;
    }
  }
  set plugin(analyticsPlugin) {
    this.analyticsPlugin = analyticsPlugin;
  }
  addErrorHandler(errorService) {
    if (errorService && isFunction(errorService.handleError)) {
      this.errorServices.push(errorService);
    }
  }
  removeErrorHandler(errorService) {
    if (errorService && isFunction(errorService.handleError)) {
      const idx = this.errorServices.indexOf(errorService);
      if (idx !== -1) {
        this.errorServices.splice(idx, 1);
      }
    }
  }
  handleError(error) {
    if (this.analyticsPlugin) {
      this.analyticsPlugin.trackException({
        exception: error
      });
    }
    if (this.errorServices && this.errorServices.length > 0) {
      arrForEach(this.errorServices, errorService => {
        if (isFunction(errorService.handleError)) {
          errorService.handleError(error);
        }
      });
    }
  }
}
ApplicationinsightsAngularpluginErrorService.instance = null;
ApplicationinsightsAngularpluginErrorService.ɵfac = function ApplicationinsightsAngularpluginErrorService_Factory(t) {
  return new (t || ApplicationinsightsAngularpluginErrorService)();
};
ApplicationinsightsAngularpluginErrorService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: ApplicationinsightsAngularpluginErrorService,
  factory: ApplicationinsightsAngularpluginErrorService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ApplicationinsightsAngularpluginErrorService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [];
  }, null);
})();

// eslint-disable-next-line @angular-eslint/component-class-suffix
class AngularPlugin extends BaseTelemetryPlugin {
  constructor() {
    super();
    this.priority = 186;
    this.identifier = 'AngularPlugin';
    this._angularCfg = null;
    this._eventSubscription = null;
    let self = this;
    self._doTeardown = (unloadCtx, unloadState, asyncCallback) => {
      if (this.analyticsPlugin && ApplicationinsightsAngularpluginErrorService.instance !== null) {
        ApplicationinsightsAngularpluginErrorService.instance.plugin = null;
        if (self._angularCfg) {
          if (self._angularCfg.errorServices && Array.isArray(self._angularCfg.errorServices)) {
            arrForEach(self._angularCfg.errorServices, errorService => {
              ApplicationinsightsAngularpluginErrorService.instance.removeErrorHandler(errorService);
            });
          }
        }
      }
      if (self._eventSubscription) {
        self._eventSubscription.unsubscribe();
        self._eventSubscription = null;
      }
      self.analyticsPlugin = null;
      self.propertiesPlugin = null;
      self._angularCfg = null;
    };
  }
  initialize(config, core, extensions, pluginChain) {
    super.initialize(config, core, extensions, pluginChain);
    const self = this;
    const ctx = self._getTelCtx();
    const extConfig = ctx.getExtCfg(self.identifier, {
      router: null,
      errorServices: null
    });
    self.propertiesPlugin = core.getPlugin(PropertiesPluginIdentifier)?.plugin;
    self.analyticsPlugin = core.getPlugin(AnalyticsPluginIdentifier)?.plugin;
    if (self.analyticsPlugin) {
      if (ApplicationinsightsAngularpluginErrorService.instance !== null) {
        ApplicationinsightsAngularpluginErrorService.instance.plugin = self.analyticsPlugin;
        if (extConfig.errorServices && Array.isArray(extConfig.errorServices)) {
          arrForEach(extConfig.errorServices, errorService => {
            ApplicationinsightsAngularpluginErrorService.instance.addErrorHandler(errorService);
          });
        }
      }
    }
    if (extConfig.router) {
      let isPageInitialLoad = true;
      const pageViewTelemetry = {
        uri: extConfig.router.url
      };
      self.trackPageView(pageViewTelemetry);
      self._eventSubscription = extConfig.router.events.subscribe(event => {
        if (self.isInitialized()) {
          if (event instanceof NavigationEnd) {
            // for page initial load, do not call trackPageView twice
            if (isPageInitialLoad) {
              isPageInitialLoad = false;
              return;
            }
            const pvt = {
              uri: extConfig.router.url,
              properties: {
                duration: 0
              } // SPA route change loading durations are undefined, so send 0
            };
            self.trackPageView(pvt);
          }
        }
      });
    }
  }
  /**
   * Add Part A fields to the event
   *
   * @param event The event that needs to be processed
   */
  processTelemetry(event, itemCtx) {
    this.processNext(event, itemCtx);
  }
  trackPageView(pageView) {
    const self = this;
    if (self.analyticsPlugin) {
      const location = getLocation();
      if (self.propertiesPlugin && self.propertiesPlugin.context && self.propertiesPlugin.context.telemetryTrace) {
        self.propertiesPlugin.context.telemetryTrace.traceID = generateW3CId();
        self.propertiesPlugin.context.telemetryTrace.name = location && location.pathname || '_unknown_';
      }
      self.analyticsPlugin.trackPageView(pageView);
    } else {
      _throwInternal(self.diagLog(), 1 /* eLoggingSeverity.CRITICAL */, 64 /* _eInternalMessageId.TelemetryInitializerFailed */, 'Analytics plugin is not available, Angular plugin telemetry will not be sent: ');
    }
  }
}
AngularPlugin.ɵfac = function AngularPlugin_Factory(t) {
  return new (t || AngularPlugin)();
};
AngularPlugin.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: AngularPlugin,
  selectors: [["lib-applicationinsights-angularplugin-js"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function AngularPlugin_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AngularPlugin, [{
    type: Component,
    args: [{
      selector: 'lib-applicationinsights-angularplugin-js',
      template: ``
    }]
  }], function () {
    return [];
  }, null);
})();
class ApplicationinsightsAngularpluginJsModule {}
ApplicationinsightsAngularpluginJsModule.ɵfac = function ApplicationinsightsAngularpluginJsModule_Factory(t) {
  return new (t || ApplicationinsightsAngularpluginJsModule)();
};
ApplicationinsightsAngularpluginJsModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ApplicationinsightsAngularpluginJsModule
});
ApplicationinsightsAngularpluginJsModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [ApplicationinsightsAngularpluginErrorService]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ApplicationinsightsAngularpluginJsModule, [{
    type: NgModule,
    args: [{
      declarations: [AngularPlugin],
      imports: [],
      exports: [AngularPlugin],
      providers: [ApplicationinsightsAngularpluginErrorService]
    }]
  }], null, null);
})();

/*
 * Public API Surface of applicationinsights-angularplugin-js
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AngularPlugin, ApplicationinsightsAngularpluginErrorService, ApplicationinsightsAngularpluginJsModule };
