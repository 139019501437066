import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Optional,
} from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
  MatSnackBarConfig,
} from '@angular/material/snack-bar';

export const CONFIG: MatSnackBarConfig = { verticalPosition: 'top' };

export interface Data {
  type: 'info' | 'success' | 'warning' | 'error';
  title: string;
  message: string;
}

@Component({
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationModalComponent {
  constructor(
    @Optional()
    @Inject(MAT_SNACK_BAR_DATA)
    public readonly data: Data | null,
    private readonly ref: MatSnackBarRef<NotificationModalComponent>,
  ) {}

  public dismiss(): void {
    this.ref.dismiss();
  }
}
