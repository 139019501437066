import { inject, InjectionToken } from '@angular/core';
import { RUNTIME_CONFIGURATION } from '@core/tokens/runtime-configuration.token';

function siteEndpointFactory(): string {
  return `${inject(RUNTIME_CONFIGURATION).api.zero.baseUrl}/sites`;
}

export const SITE_ENDPOINT = new InjectionToken<string>('SITE_ENDPOINT', {
  factory: siteEndpointFactory,
});
