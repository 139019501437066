import { ErrorHandler, Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { RUNTIME_CONFIGURATION } from '@core/tokens/runtime-configuration.token';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import {
  ApplicationInsights,
  ITelemetryPlugin,
} from '@microsoft/applicationinsights-web';

@Injectable({ providedIn: 'root' })
export class ApplicationInsightsService {
  private readonly router = inject(Router);
  private readonly config = inject(RUNTIME_CONFIGURATION).appInsights;
  private readonly angularPlugin = new AngularPlugin();
  public readonly instance?: ApplicationInsights;

  constructor() {
    if (this.config.instrumentationKey) {
      this.instance = new ApplicationInsights({
        config: {
          instrumentationKey: this.config.instrumentationKey,
          extensions: [this.angularPlugin as unknown as ITelemetryPlugin],
          extensionConfig: {
            [this.angularPlugin.identifier]: {
              router: this.router,
              errorServices: [new ErrorHandler()],
            },
          },
        },
      });
      this.instance.loadAppInsights();
    }
  }
}
