import { InjectionToken, inject } from '@angular/core';
import { ZERO_BASEURL } from '@core/tokens/zero-baseurl.token';

export const ACCOUNTING_CENTRE_ENDPOINT = new InjectionToken(
  'ACCOUNTING_CENTRE_ENDPOINT',
  {
    providedIn: 'root',
    factory: () => `${inject(ZERO_BASEURL)}/ads/accounting-centre`,
  },
);
