import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { DEVICE_TYPE } from '@core/tokens/device-type.token';

@Component({
  selector: 'zero-small-screen',
  templateUrl: './small-screen.component.html',
  styleUrls: ['./small-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmallScreenComponent {
  public readonly deviceType = inject(DEVICE_TYPE);
}
