import { inject, InjectionToken } from '@angular/core';
import { RUNTIME_CONFIGURATION } from '@core/tokens/runtime-configuration.token';

function benchmarkEndpointFactory(): string {
  return `${
    inject(RUNTIME_CONFIGURATION).api.zero.baseUrl
  }/assessments/benchmark`;
}

export const BENCHMARK_ENDPOINT = new InjectionToken<string>(
  'BENCHMARK_ENDPOINT',
  { factory: benchmarkEndpointFactory },
);
