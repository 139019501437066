import { filter, map, OperatorFunction } from 'rxjs';
import {
  HttpDataState,
  SuccessfulHttpDataState,
} from '@core/types/http-data-state';

export function filterSuccess<T>(): OperatorFunction<
  HttpDataState<T>,
  SuccessfulHttpDataState<T>
> {
  return (source$) =>
    source$.pipe(
      filter(
        (dataState) =>
          dataState.status === 'success' && dataState.data !== undefined,
      ),
      map((dataState) => dataState as SuccessfulHttpDataState<T>),
    );
}
