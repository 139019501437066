import { inject, InjectionToken } from '@angular/core';
import { RUNTIME_CONFIGURATION } from '@core/tokens/runtime-configuration.token';

function assetEndpointFactory(): string {
  return `${inject(RUNTIME_CONFIGURATION).api.zero.baseUrl}/assets`;
}

export const ASSET_ENDPOINT = new InjectionToken<string>('ASSET_ENDPOINT', {
  factory: assetEndpointFactory,
});
