import { FoundationElement } from "../foundation-element/foundation-element.js";
/**
 * A TabPanel Component to be used with {@link @microsoft/fast-foundation#(Tabs:class)}
 *
 * @slot - The default slot for the tabpanel content
 *
 * @public
 */
export class TabPanel extends FoundationElement {
}
