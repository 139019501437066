export enum SubsystemName {
  BasementFrame = 'Ground Slab - Basement Frame',
  Foundation = 'Foundation',
  BasementPerimeter = 'Basement Perimeter',
  Floorplate = 'Floorplate',
  VerticalStructure = 'Vertical Structure',
  Stability = 'Stability',
  Roof = 'Roof',
  Stairs = 'Stairs',
  Heating = 'Heating',
  Cooling = 'Cooling',
  Ventilation = 'Ventilation',
  Process = 'Process',
  Electrical = 'Electrical',
  Telecomms = 'Telecomms - ICT',
  Lighting = 'Lighting',
  Renewables = 'Renewables',
  VerticalTransport = 'Vertical Transport',
  Security = 'Security',
  HotWater = 'Hot Water',
  ColdWater = 'Cold Water',
  WasteWater = 'Waste Water',
  Fire = 'Piped Fire Systems',
  Fuel = 'Piped Fuel',
  Specialist = 'Specialist',
  Facade = 'Facade',
  RoofFinishes = 'Roof Finishes',
  Partitions = 'Partitions',
  FloorFinishes = 'Floor Finishes',
  CeilingFinishes = 'Ceiling Finishes',
  WallFinishes = 'Wall Finishes',
  ArchitecturalMetalwork = 'Architectural Metalwork',
}
