export enum CarbonStage {
  A1_A3 = 'A1-A3',
  A4 = 'A4',
  A5 = 'A5',
  B1 = 'B1',
  B2 = 'B2',
  B3 = 'B3',
  B4 = 'B4',
  B5 = 'B5',
  B6 = 'B6',
  B7 = 'B7',
  C1 = 'C1',
  C2 = 'C2',
  C3 = 'C3',
  C4 = 'C4',
  D = 'D',
  BIOGENIC = 'Bio',
}
