<button
  (click)="navigateBack.emit()"
  aria-label="Navigate Back"
  mat-flat-button
  name="back-button"
  type="tab"
>
  <mat-icon>navigate_before</mat-icon>
  Back
</button>
<p *ngIf="asset">
  {{ asset.name | titlecase }}
  <ng-container *ngIf="asset.project_number"
    >| {{ asset.project_number | projectNumber }}
  </ng-container>
</p>
<h1>
  <ng-content></ng-content>
</h1>
