import { inject, InjectionToken } from '@angular/core';
import { RUNTIME_CONFIGURATION } from '@core/tokens/runtime-configuration.token';

export const ENERGY_MODEL_ENDPOINT = new InjectionToken<string>(
  'ENERGY_MODEL_ENDPOINT',
  {
    factory: () =>
      `${
        inject(RUNTIME_CONFIGURATION).api.zero.baseUrl
      }/assessments/detailed/energy`,
  },
);
