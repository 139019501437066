import { inject, InjectionToken } from '@angular/core';
import { RUNTIME_CONFIGURATION } from '@core/tokens/runtime-configuration.token';

function campaignEndpointFactory(): string {
  return `${inject(RUNTIME_CONFIGURATION).api.zero.baseUrl}/campaigns`;
}

export const CAMPAIGN_ENDPOINT = new InjectionToken<string>(
  'CAMPAIGN_ENDPOINT',
  { factory: campaignEndpointFactory },
);
