import * as i0 from '@angular/core';
import { Injectable, Directive, Input, NgModule, TemplateRef, Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
class TeleportService {
  constructor() {
    this.outlets = new BehaviorSubject('');
    this.asObservable = this.outlets.asObservable();
    this.ports = new Map();
  }
  outlet$(name) {
    return this.asObservable.pipe(filter(current => current === name), map(name => this.ports.get(name)));
  }
  newOutlet(name) {
    this.outlets.next(name);
  }
}
TeleportService.ɵfac = function TeleportService_Factory(t) {
  return new (t || TeleportService)();
};
TeleportService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: TeleportService,
  factory: TeleportService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TeleportService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class TeleportOutletDirective {
  constructor(vcr, service) {
    this.vcr = vcr;
    this.service = service;
  }
  ngOnChanges(changes) {
    // The `teleportOutlet` might be `null|undefined`, but we don't want nullable values to be used
    // as keys for the `ports` map.
    if (changes.teleportOutlet && typeof this.teleportOutlet === 'string') {
      this.service.ports.set(this.teleportOutlet, this.vcr);
      this.service.newOutlet(this.teleportOutlet);
    }
  }
  ngOnDestroy() {
    this.service.ports.delete(this.teleportOutlet);
  }
}
TeleportOutletDirective.ɵfac = function TeleportOutletDirective_Factory(t) {
  return new (t || TeleportOutletDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(TeleportService));
};
TeleportOutletDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TeleportOutletDirective,
  selectors: [["", "teleportOutlet", ""]],
  inputs: {
    teleportOutlet: "teleportOutlet"
  },
  features: [i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TeleportOutletDirective, [{
    type: Directive,
    args: [{
      selector: '[teleportOutlet]'
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }, {
      type: TeleportService
    }];
  }, {
    teleportOutlet: [{
      type: Input
    }]
  });
})();
class TeleportDirective {
  constructor(tpl, service) {
    this.tpl = tpl;
    this.service = service;
    this.subscription = null;
  }
  ngOnChanges(changes) {
    if (changes.teleportTo && typeof this.teleportTo === 'string') {
      this.dispose();
      this.subscription = this.service.outlet$(this.teleportTo).subscribe(outlet => {
        if (outlet) {
          this.viewRef = outlet.createEmbeddedView(this.tpl);
        }
      });
    }
  }
  ngOnDestroy() {
    this.dispose();
  }
  dispose() {
    this.subscription?.unsubscribe();
    this.subscription = null;
    this.viewRef?.destroy();
  }
}
TeleportDirective.ɵfac = function TeleportDirective_Factory(t) {
  return new (t || TeleportDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef), i0.ɵɵdirectiveInject(TeleportService));
};
TeleportDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TeleportDirective,
  selectors: [["", "teleportTo", ""]],
  inputs: {
    teleportTo: "teleportTo"
  },
  features: [i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TeleportDirective, [{
    type: Directive,
    args: [{
      selector: '[teleportTo]'
    }]
  }], function () {
    return [{
      type: i0.TemplateRef
    }, {
      type: TeleportService
    }];
  }, {
    teleportTo: [{
      type: Input
    }]
  });
})();
class TeleportModule {}
TeleportModule.ɵfac = function TeleportModule_Factory(t) {
  return new (t || TeleportModule)();
};
TeleportModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TeleportModule
});
TeleportModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TeleportModule, [{
    type: NgModule,
    args: [{
      declarations: [TeleportDirective, TeleportOutletDirective],
      exports: [TeleportDirective, TeleportOutletDirective]
    }]
  }], null, null);
})();
function isTemplateRef(value) {
  return value instanceof TemplateRef;
}
function isComponent(value) {
  return typeof value === 'function';
}
function isString(value) {
  return typeof value === 'string';
}
class DynamicViewComponent {}
DynamicViewComponent.ɵfac = function DynamicViewComponent_Factory(t) {
  return new (t || DynamicViewComponent)();
};
DynamicViewComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DynamicViewComponent,
  selectors: [["dynamic-view"]],
  inputs: {
    content: "content"
  },
  decls: 1,
  vars: 1,
  consts: [[3, "innerHTML"]],
  template: function DynamicViewComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelement(0, "div", 0);
    }
    if (rf & 2) {
      i0.ɵɵproperty("innerHTML", ctx.content, i0.ɵɵsanitizeHtml);
    }
  },
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DynamicViewComponent, [{
    type: Component,
    args: [{
      selector: 'dynamic-view',
      template: ` <div [innerHTML]="content"></div> `
    }]
  }], null, {
    content: [{
      type: Input
    }]
  });
})();
class TplRef {
  constructor(args) {
    this.args = args;
    this.wrapper = null;
    if (this.args.vcr) {
      this.viewRef = this.args.vcr.createEmbeddedView(this.args.tpl, this.args.context || {});
      this.viewRef.detectChanges();
    } else {
      this.viewRef = this.args.tpl.createEmbeddedView(this.args.context || {});
      this.viewRef.detectChanges();
      this.args.appRef.attachView(this.viewRef);
    }
  }
  detectChanges() {
    this.viewRef.detectChanges();
  }
  getElement() {
    const rootNodes = this.viewRef.rootNodes;
    if (rootNodes.length === 1 && rootNodes[0] === Node.ELEMENT_NODE) {
      this.element = rootNodes[0];
    } else {
      this.element = document.createElement('div');
      this.element.append(...rootNodes);
    }
    return this.element;
  }
  destroy() {
    if (this.viewRef.rootNodes[0] !== 1) {
      this.element?.parentNode.removeChild(this.element);
      this.element = null;
    }
    if (!this.args.vcr) {
      this.args.appRef.detachView(this.viewRef);
    }
    this.viewRef.destroy();
    this.viewRef = null;
  }
}
class StringRef {
  constructor(value) {
    this.value = value;
  }
  getElement() {
    return this.value;
  }
  detectChanges() {}
  destroy() {}
}
class CompRef {
  constructor(options) {
    this.options = options;
    if (options.vcr) {
      this.compRef = options.vcr.createComponent(options.component, {
        index: options.vcr.length,
        injector: options.injector || options.vcr.injector
      });
    } else {
      const factory = options.resolver.resolveComponentFactory(options.component);
      this.compRef = factory.create(options.injector);
      options.appRef.attachView(this.compRef.hostView);
    }
  }
  get ref() {
    return this.compRef;
  }
  setInput(input, value) {
    this.compRef.instance[input] = value;
    return this;
  }
  setInputs(inputs) {
    Object.keys(inputs).forEach(input => {
      this.compRef.instance[input] = inputs[input];
    });
    return this;
  }
  detectChanges() {
    this.compRef.hostView.detectChanges();
    return this;
  }
  appendTo(container) {
    container.appendChild(this.getElement());
    return this;
  }
  removeFrom(container) {
    container.removeChild(this.getElement());
    return this;
  }
  getRawContent() {
    return this.getElement().outerHTML;
  }
  getElement() {
    return this.compRef.location.nativeElement;
  }
  destroy() {
    this.compRef.destroy();
    !this.options.vcr && this.options.appRef.detachView(this.compRef.hostView);
    this.compRef = null;
  }
}
class ViewService {
  constructor(resolver, injector, appRef) {
    this.resolver = resolver;
    this.injector = injector;
    this.appRef = appRef;
  }
  createComponent(component, options = {}) {
    return new CompRef({
      component,
      vcr: options.vcr,
      injector: options.injector || this.injector,
      appRef: this.appRef,
      resolver: this.resolver
    });
  }
  createTemplate(tpl, options = {}) {
    return new TplRef({
      vcr: options.vcr,
      appRef: this.appRef,
      tpl,
      context: options.context
    });
  }
  createView(content, viewOptions = {}) {
    if (isTemplateRef(content)) {
      return this.createTemplate(content, viewOptions);
    } else if (isComponent(content)) {
      return this.createComponent(content, viewOptions);
    } else if (isString(content)) {
      return new StringRef(content);
    } else {
      throw 'Type of content is not supported';
    }
  }
}
ViewService.ɵfac = function ViewService_Factory(t) {
  return new (t || ViewService)(i0.ɵɵinject(i0.ComponentFactoryResolver), i0.ɵɵinject(i0.Injector), i0.ɵɵinject(i0.ApplicationRef));
};
ViewService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: ViewService,
  factory: ViewService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ViewService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i0.ComponentFactoryResolver
    }, {
      type: i0.Injector
    }, {
      type: i0.ApplicationRef
    }];
  }, null);
})();
class DynamicViewDirective {
  constructor(defaultTpl, vcr, viewService) {
    this.defaultTpl = defaultTpl;
    this.vcr = vcr;
    this.viewService = viewService;
  }
  ngOnInit() {
    this.resolveContentType();
  }
  ngOnChanges(changes) {
    if (changes.view && !changes.view.isFirstChange()) {
      this.resolveContentType();
    }
  }
  resolveContentType() {
    this.viewRef?.destroy();
    if (isString(this.view)) {
      this.viewRef = this.viewService.createComponent(DynamicViewComponent, {
        vcr: this.vcr,
        injector: this.injector
      });
      this.viewRef.setInput('content', this.view).detectChanges();
    } else {
      this.viewRef = this.viewService.createView(this.view || this.defaultTpl, {
        vcr: this.vcr,
        injector: this.injector ?? this.vcr.injector,
        context: this.context
      });
    }
  }
  ngOnDestroy() {
    this.viewRef?.destroy();
  }
}
DynamicViewDirective.ɵfac = function DynamicViewDirective_Factory(t) {
  return new (t || DynamicViewDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(ViewService));
};
DynamicViewDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: DynamicViewDirective,
  selectors: [["", "dynamicView", ""]],
  inputs: {
    view: [i0.ɵɵInputFlags.None, "dynamicView", "view"],
    injector: [i0.ɵɵInputFlags.None, "dynamicViewInjector", "injector"],
    context: [i0.ɵɵInputFlags.None, "dynamicViewContext", "context"]
  },
  features: [i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DynamicViewDirective, [{
    type: Directive,
    args: [{
      selector: '[dynamicView]'
    }]
  }], function () {
    return [{
      type: i0.TemplateRef
    }, {
      type: i0.ViewContainerRef
    }, {
      type: ViewService
    }];
  }, {
    view: [{
      type: Input,
      args: ['dynamicView']
    }],
    injector: [{
      type: Input,
      args: ['dynamicViewInjector']
    }],
    context: [{
      type: Input,
      args: ['dynamicViewContext']
    }]
  });
})();
class DynamicViewModule {}
DynamicViewModule.ɵfac = function DynamicViewModule_Factory(t) {
  return new (t || DynamicViewModule)();
};
DynamicViewModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: DynamicViewModule
});
DynamicViewModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DynamicViewModule, [{
    type: NgModule,
    args: [{
      declarations: [DynamicViewDirective, DynamicViewComponent],
      exports: [DynamicViewDirective]
    }]
  }], null, null);
})();

/*
 * Public API Surface of overview
 */

/**
 * Generated bundle index. Do not edit.
 */

export { CompRef, DynamicViewDirective, DynamicViewModule, StringRef, TeleportDirective, TeleportModule, TeleportOutletDirective, TplRef, ViewService, isComponent, isString, isTemplateRef };
