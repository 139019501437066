import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '@angular/cdk/layout';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MaterialModule } from '@material/material.module';
import { NgChartsModule } from 'ng2-charts';
import { TippyModule } from '@ngneat/helipopper';
import { LoadingModalComponent } from '@core/modals/loading-modal/loading-modal.component';
import { AcknowledgementModalComponent } from '@core/modals/acknowledgement-modal/acknowledgement-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SmallScreenComponent } from '@core/components/small-screen/small-screen.component';
import { MsalInterceptor, MsalModule, MsalService } from '@azure/msal-angular';
import { MonitorRequestTimeInterceptor } from '@core/interceptors/monitor-request-time.interceptor';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { SystemScopePipe } from '@core/pipes/system-scope.pipe';
import { AssetCardComponent } from '@core/components/asset-card/asset-card.component';
import { CreateAssetCardComponent } from '@core/components/create-asset-card/create-asset-card.component';
import { PeoplePickerComponent } from '@core/components/people-picker/people-picker.component';
import { PageHeaderComponent } from '@core/components/page-header/page-header.component';
import { CarbonStageChartComponent } from '@core/components/carbon-stage-chart/carbon-stage-chart.component';
import { AssemblyModalComponent } from '@core/modals/assembly-modal/assembly-modal.component';
import { ProjectNumberPipe } from '@core/pipes/project-number.pipe';
import { ReadMoreComponent } from '@core/components/read-more/read-more.component';
import { SystemChipComponent } from '@core/components/system-chip/system-chip.component';
import { ExternalLinkComponent } from '@core/components/external-link/external-link.component';
import { AssetImportCardComponent } from '@core/components/asset-import-card/asset-import-card.component';
import { CarbonTableHeaderComponent } from '@core/components/carbon-table-header/carbon-table-header.component';
import { CarbonTableComponent } from '@core/components/carbon-table/carbon-table.component';
import { CarbonTableRowComponent } from '@core/components/carbon-table-row/carbon-table-row.component';
import { CarbonTableCellComponent } from '@core/components/carbon-table-cell/carbon-table-cell.component';
import { CarbonTableCellsComponent } from '@core/components/carbon-table-cells/carbon-table-cells.component';
import { WarningComponent } from '@core/components/warning/warning.component';
import { RequiredFieldsComponent } from '@core/components/required-fields/required-fields.component';
import { FormHelpComponent } from '@core/components/form-help/form-help.component';
import { CreateAssessmentComponent } from '@core/components/create-assessment/create-assessment.component';
import { TotalParameterIconComponent } from '@core/components/total-parameter-icon/total-parameter-icon.component';
import { TagComponent } from '@core/components/tag/tag.component';
import { KeyComponent } from '@core/components/key/key.component';
import { LoadingSelectControlComponent } from '@core/controls/loading-select-control/loading-select-control.component';
import { CampaignControlComponent } from '@core/controls/campaign-control/campaign-control.component';
import { StatusPipe } from '@core/pipes/status.pipe';
import { ExcelUploadModalComponent } from '@core/components/excel-upload-modal/excel-upload-modal.component';
import { CheckboxComponent } from '@core/components/checkbox/checkbox.component';
import { OrderByPipe } from '@core/pipes/order-by.pipe';
import { CopyToClipboardComponent } from '@core/components/copy-to-clipboard/copy-to-clipboard.component';
import { AuthenticatedWelcomeComponent } from '@core/components/authenticated-welcome/authenticated-welcome.component';
import { LoginComponent } from '@core/components/login/login.component';
import { LandingPageComponent } from '@core/containers/landing-page/landing-page.component';
import { UnauthorizedPageComponent } from '@core/containers/unauthorized-page/unauthorized-page.component';
import { NotFoundPageComponent } from '@core/containers/not-found-page/not-found-page.component';
import { MaintenancePageComponent } from '@core/containers/maintenance-page/maintenance-page.component';
import { NotificationModalComponent } from './modals/notification-modal/notification-modal.component';

@NgModule({
  declarations: [
    NotificationModalComponent,
    AcknowledgementModalComponent,
    LoadingModalComponent,
    SmallScreenComponent,
    AssetCardComponent,
    CreateAssetCardComponent,
    AssetImportCardComponent,
    PeoplePickerComponent,
    PageHeaderComponent,
    CarbonStageChartComponent,
    AssemblyModalComponent,
    ReadMoreComponent,
    SystemChipComponent,
    ExternalLinkComponent,
    CarbonTableComponent,
    CarbonTableRowComponent,
    CarbonTableHeaderComponent,
    CarbonTableCellComponent,
    CarbonTableCellsComponent,
    WarningComponent,
    RequiredFieldsComponent,
    FormHelpComponent,
    CreateAssessmentComponent,
    TotalParameterIconComponent,
    TagComponent,
    KeyComponent,
    LoadingSelectControlComponent,
    CampaignControlComponent,
    ExcelUploadModalComponent,
    CheckboxComponent,
    CopyToClipboardComponent,
    LoginComponent,
    AuthenticatedWelcomeComponent,
    UnauthorizedPageComponent,
    NotFoundPageComponent,
    MaintenancePageComponent,
    LandingPageComponent,
    ProjectNumberPipe,
    SystemScopePipe,
    StatusPipe,
    OrderByPipe,
  ],
  exports: [
    SmallScreenComponent,
    AssetCardComponent,
    CreateAssetCardComponent,
    AssetImportCardComponent,
    PeoplePickerComponent,
    PageHeaderComponent,
    CarbonStageChartComponent,
    AssemblyModalComponent,
    ReadMoreComponent,
    SystemChipComponent,
    ExternalLinkComponent,
    CarbonTableComponent,
    CarbonTableRowComponent,
    CarbonTableHeaderComponent,
    CarbonTableCellComponent,
    CarbonTableCellsComponent,
    WarningComponent,
    RequiredFieldsComponent,
    FormHelpComponent,
    CreateAssessmentComponent,
    TotalParameterIconComponent,
    TagComponent,
    KeyComponent,
    LoadingSelectControlComponent,
    CampaignControlComponent,
    ExcelUploadModalComponent,
    CheckboxComponent,
    CopyToClipboardComponent,
    ProjectNumberPipe,
    SystemScopePipe,
    LandingPageComponent,
    UnauthorizedPageComponent,
    NotFoundPageComponent,
    MaintenancePageComponent,
    StatusPipe,
    OrderByPipe,
  ],
  imports: [
    CommonModule,
    MsalModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    LayoutModule,
    NgChartsModule,
    TippyModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MonitorRequestTimeInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    MsalService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {}
