import {
  Component,
  Input,
  OnChanges,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { CarbonStage } from '@core/enums/carbon-stage';
import { ChartConfiguration, LegendItem, Plugin } from 'chart.js';
import { StageChartData } from '@core/models/chart-data';
import { BaseChartDirective } from 'ng2-charts';
import { SystemName } from '@asset/enums/system-name';

@Component({
  selector: 'zero-carbon-stage-chart[data]',
  templateUrl: './carbon-stage-chart.component.html',
  styleUrls: ['./carbon-stage-chart.component.scss'],
})
export class CarbonStageChartComponent implements OnChanges, AfterViewInit {
  @ViewChild(BaseChartDirective) public chart?: BaseChartDirective;
  @ViewChild('legendContainer', { static: false })
  public legendContainer!: ElementRef;
  @Input()
  public data!: StageChartData;
  public readonly carbonStages = Object.values(CarbonStage);
  public selectedItemIndex = -1;
  public barChartLegend = false;
  public barChartPlugins: Plugin<'bar', any>[] = [
    {
      id: '',
      beforeInit(chart: any) {
        // increase the space between the legend and the chart
        const originalFit = chart.legend.fit;
        chart.legend.fit = function fit() {
          originalFit.bind(chart.legend)();
          this.height += 24;
        };
      },
    },
    {
      id: 'htmlLegend',
      afterDraw: (chart: any) => {
        // Reuse the built-in legendItems generator
        const items = chart.options.plugins.legend.labels.generateLabels(chart);
        // identify in scope and out of scope items
        const inScope = items.filter(
          (item: LegendItem) => item.fillStyle !== 'rgba(1,1,1,0)',
        );
        const outOfScope = items.filter(
          (item: LegendItem) => item.fillStyle === 'rgba(1,1,1,0)',
        );
        // set up title for subsystem or lifecycle chart
        let title = 'Subsystems';
        if (Object.values(SystemName).includes(items[0]?.text)) {
          title = 'In Scope';
        }

        const inScopeUl = this.getOrCreateLegendList('inScope', title);
        // Remove old legend items
        while (inScopeUl.firstChild) {
          inScopeUl.firstChild.remove();
        }
        inScope.forEach((item: LegendItem) => {
          this.generateLegendItems(item, inScopeUl, items, chart);
        });

        if (outOfScope.length > 0) {
          const outOfScopeUl = this.getOrCreateLegendList(
            'outOfScope',
            'Out of Scope',
          );
          // Remove old legend items
          while (outOfScopeUl.firstChild) {
            outOfScopeUl.firstChild.remove();
          }
          outOfScope.forEach((item: any) => {
            this.generateLegendItems(item, outOfScopeUl, items, chart);
          });
        }
      },
    },
  ];

  public barChartData?: ChartConfiguration<'bar'>['data'];

  public readonly barChartOptions: ChartConfiguration<'bar'>['options'] = {
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = Math.round((context.raw as number) * 10) / 10;
            return label.toString();
          },
          afterLabel: () => 'kgCO\u2082e/m\u00B2',
        },
      },
    },
    responsive: true,
    scales: {
      x: { stacked: true, title: { display: true, text: 'Stages' } },
      y: {
        stacked: true,
        title: { display: true, text: 'kgCO\u2082e/m\u00B2' },
      },
    },
  };

  // functions for the legend plugin
  public getOrCreateLegendList(id: string, title: string): HTMLUListElement {
    const legendContainer = this.legendContainer.nativeElement;
    let sectionContainer = document.getElementById(id);
    let listContainer = sectionContainer?.querySelector('ul');
    if (!sectionContainer) {
      sectionContainer = document.createElement('div');
      sectionContainer.setAttribute('id', id);
      sectionContainer.style.display = 'grid';
      sectionContainer.style.gap = '8px';
      sectionContainer.style.gridTemplateColumns = 'auto 1fr';
      legendContainer.appendChild(sectionContainer);
      const sectionTitle = document.createElement('h4');
      sectionTitle.textContent = title;
      sectionTitle.style.fontWeight = 'bold';
      sectionContainer.appendChild(sectionTitle);
    }
    if (!listContainer) {
      listContainer = document.createElement('ul');
      listContainer.style.display = 'flex';
      listContainer.style.flexDirection = 'row';
      listContainer.style.margin = '0';
      listContainer.style.padding = '0';
      listContainer.style.flexWrap = 'wrap';
      listContainer.style.gap = '10px';
      sectionContainer.appendChild(listContainer);
    }
    return listContainer;
  }

  public generateLegendItems(
    item: LegendItem,
    ulElement: Element,
    items: LegendItem[],
    chart: any,
  ): void {
    const li = document.createElement('li');
    li.style.alignItems = 'center';
    li.style.cursor = 'pointer';
    li.style.display = 'flex';
    li.style.flexDirection = 'row';
    li.style.marginLeft = '10px';

    li.onclick = () =>
      this.toggleVisibility(chart, items, item.datasetIndex as number);

    // Colored circle
    const boxSpan = document.createElement('span');
    boxSpan.style.background = item.fillStyle as string;
    boxSpan.style.borderColor = item.strokeStyle as string;
    boxSpan.style.borderRadius = '50%';
    boxSpan.style.borderStyle = 'solid';
    boxSpan.style.borderWidth = item.lineWidth + 'px';
    boxSpan.style.display = 'inline-block';
    boxSpan.style.height = '20px';
    boxSpan.style.width = '20px';
    boxSpan.style.marginRight = '10px';

    // Text
    const textContainer = document.createElement('p');
    textContainer.style.margin = '0';
    textContainer.style.padding = '0';
    textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

    const text = document.createTextNode(item.text);
    textContainer.appendChild(text);

    li.appendChild(boxSpan);
    li.appendChild(textContainer);
    ulElement.appendChild(li);
  }

  public toggleVisibility(
    chart: any,
    items: LegendItem[],
    clickedItemIndex: number,
  ) {
    if (this.selectedItemIndex === clickedItemIndex) {
      // Toggle all items on
      items.forEach((dataset: LegendItem) => {
        chart.setDatasetVisibility(dataset.datasetIndex, true);
      });
      this.selectedItemIndex = -1;
    } else {
      // Turn off all other items and turn on the clicked item
      items.forEach((dataset: LegendItem, index: number) => {
        if (index !== clickedItemIndex) {
          chart.setDatasetVisibility(dataset.datasetIndex, false);
        } else {
          chart.setDatasetVisibility(dataset.datasetIndex, true);
        }
      });
      this.selectedItemIndex = clickedItemIndex;
    }

    chart.update();
  }

  public ngAfterViewInit(): void {
    // change colours for dark mode
    const xgrid = this.chart?.chart?.config?.options?.scales?.['x']?.grid;
    const xticks = this.chart?.chart?.config.options?.scales?.['x']?.ticks;
    const ygrid = this.chart?.chart?.config?.options?.scales?.['y']?.grid;
    const yticks = this.chart?.chart?.config.options?.scales?.['y']?.ticks;
    // @ts-ignore
    const ytitle = this.chart?.chart?.config.options?.scales?.['y']?.title;
    // @ts-ignore
    const xtitle = this.chart?.chart?.config.options?.scales?.['x']?.title;
    if (document.body.classList.contains('mgt-dark')) {
      if (xgrid) {
        xgrid.color = 'rgba(255,255,255,0.3)';
      }
      if (xticks) {
        xticks.color = 'white';
      }
      if (ygrid) {
        ygrid.color = 'rgba(255,255,255,0.3)';
      }
      if (yticks) {
        yticks.color = 'white';
      }
      if (ytitle) {
        ytitle.color = 'white';
      }
      if (xtitle) {
        xtitle.color = 'white';
      }
    }
  }

  public ngOnChanges(): void {
    if (this.data) {
      this.barChartData = {
        labels: this.data.labels,
        datasets: this.data.datasets.map((d) => {
          const data = d.data.map((b) => b.total);
          return { ...d, data };
        }),
      };
    }
  }
}
