import * as i0 from '@angular/core';
import { InjectionToken, ElementRef, EventEmitter, Injector, PLATFORM_ID, Directive, Inject, Input, Output, NgModule, Injectable } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import tippy from 'tippy.js';
import { Observable, Subject, fromEvent, merge } from 'rxjs';
import { auditTime, map, switchMap, takeUntil, filter } from 'rxjs/operators';
import * as i1 from '@ngneat/overview';
import { isString as isString$1, isComponent, isTemplateRef } from '@ngneat/overview';
const TIPPY_CONFIG = new InjectionToken('Tippy config', {
  providedIn: 'root',
  factory() {
    return {};
  }
});
const TIPPY_REF = new InjectionToken('TIPPY_REF');
function coerceElement(element) {
  return element instanceof ElementRef ? element.nativeElement : element;
}
let supportsIntersectionObserver = false;
let supportsResizeObserver = false;
if (typeof window !== 'undefined') {
  supportsIntersectionObserver = 'IntersectionObserver' in window;
  supportsResizeObserver = 'ResizeObserver' in window;
}
function inView(host, options = {
  root: null,
  threshold: 0.3
}) {
  const element = coerceElement(host);
  return new Observable(subscriber => {
    if (!supportsIntersectionObserver) {
      subscriber.next();
      subscriber.complete();
      return;
    }
    const observer = new IntersectionObserver(entries => {
      // Several changes may occur in the same tick, we want to check the latest entry state.
      const entry = entries[entries.length - 1];
      if (entry.isIntersecting) {
        subscriber.next();
        subscriber.complete();
      }
    }, options);
    observer.observe(element);
    return () => observer.disconnect();
  });
}
function isElementOverflow(host) {
  // Don't access the `offsetWidth` multipe times since it triggers layout updates.
  const hostOffsetWidth = host.offsetWidth;
  return hostOffsetWidth > host.parentElement.offsetWidth || hostOffsetWidth < host.scrollWidth;
}
function overflowChanges(host) {
  const element = coerceElement(host);
  return dimensionsChanges(element).pipe(auditTime(150), map(() => isElementOverflow(element)));
}
function dimensionsChanges(target) {
  return resizeObserverStrategy(target);
}
function resizeObserverStrategy(target) {
  return new Observable(subscriber => {
    if (!supportsResizeObserver) {
      subscriber.next();
      subscriber.complete();
      return;
    }
    const observer = new ResizeObserver(() => subscriber.next(true));
    observer.observe(target);
    return () => observer.disconnect();
  });
}
function onlyTippyProps(allProps) {
  const tippyProps = {};
  const ownProps = ['variations', 'useHostWidth', 'defaultVariation', 'beforeRender', 'lazy', 'variation', 'isEnabled', 'className', 'onlyTextOverflow', 'data', 'content', 'context', 'hideOnEscape', 'customHost', 'injector', 'preserveView', 'vcr'];
  const overriddenMethods = ['onShow', 'onHidden', 'onCreate'];
  Object.keys(allProps).forEach(prop => {
    if (!ownProps.includes(prop) && !overriddenMethods.includes(prop)) {
      tippyProps[prop] = allProps[prop];
    }
  });
  return tippyProps;
}
function normalizeClassName(className) {
  const classes = isString(className) ? className.split(' ') : className;
  return classes.map(klass => klass?.trim()).filter(Boolean);
}
function isString(value) {
  return typeof value === 'string';
}
class TippyDirective {
  constructor(platformId, globalConfig, injector, viewService, vcr, zone, hostRef) {
    this.platformId = platformId;
    this.globalConfig = globalConfig;
    this.injector = injector;
    this.viewService = viewService;
    this.vcr = vcr;
    this.zone = zone;
    this.hostRef = hostRef;
    this.onlyTextOverflow = false;
    this.useHostWidth = false;
    this.hideOnEscape = false;
    this.detectChangesComponent = true;
    this.visible = new EventEmitter();
    this.isVisible = false;
    this.destroyed = new Subject();
    this.enabled = true;
    this.variationDefined = false;
  }
  ngOnChanges(changes) {
    if (isPlatformServer(this.platformId)) return;
    let props = Object.keys(changes).reduce((acc, change) => {
      if (change === 'isVisible') return acc;
      acc[change] = changes[change].currentValue;
      return acc;
    }, {});
    let variation;
    if (isChanged('variation', changes)) {
      variation = changes.variation.currentValue;
      this.variationDefined = true;
    } else if (!this.variationDefined) {
      variation = this.globalConfig.defaultVariation;
      this.variationDefined = true;
    }
    if (variation) {
      props = {
        ...this.globalConfig.variations[variation],
        ...props
      };
    }
    if (isChanged('isEnabled', changes)) {
      this.enabled = changes.isEnabled.currentValue;
      this.setStatus();
    }
    if (isChanged('isVisible', changes)) {
      this.isVisible ? this.show() : this.hide();
    }
    this.setProps({
      ...this.props,
      ...props
    });
  }
  ngOnInit() {
    if (this.useHostWidth) {
      this.props.maxWidth = this.hostWidth;
    }
  }
  ngAfterViewInit() {
    if (isPlatformServer(this.platformId)) return;
    this.zone.runOutsideAngular(() => {
      if (this.lazy) {
        if (this.onlyTextOverflow) {
          inView(this.host).pipe(switchMap(() => overflowChanges(this.host)), takeUntil(this.destroyed)).subscribe(isElementOverflow => {
            this.checkOverflow(isElementOverflow);
          });
        } else {
          inView(this.host).pipe(takeUntil(this.destroyed)).subscribe(() => {
            this.createInstance();
          });
        }
      } else if (this.onlyTextOverflow) {
        overflowChanges(this.host).pipe(takeUntil(this.destroyed)).subscribe(isElementOverflow => {
          this.checkOverflow(isElementOverflow);
        });
      } else {
        this.createInstance();
      }
    });
  }
  ngOnDestroy() {
    this.destroyed.next();
    this.instance?.destroy();
    this.destroyView();
  }
  destroyView() {
    this.viewOptions$ = null;
    this.viewRef?.destroy();
    this.viewRef = null;
  }
  show() {
    this.instance?.show();
  }
  hide() {
    this.instance?.hide();
  }
  enable() {
    this.instance?.enable();
  }
  disable() {
    this.instance?.disable();
  }
  setProps(props) {
    this.props = props;
    this.instance?.setProps(onlyTippyProps(props));
  }
  setStatus() {
    this.enabled ? this.instance?.enable() : this.instance?.disable();
  }
  get host() {
    return this.customHost || this.hostRef.nativeElement;
  }
  get hostWidth() {
    return `${this.host.getBoundingClientRect().width}px`;
  }
  createInstance() {
    if (this.content == null) {
      return;
    }
    this.zone.runOutsideAngular(() => {
      this.instance = tippy(this.host, {
        allowHTML: true,
        appendTo: document.body,
        ...onlyTippyProps(this.globalConfig),
        ...onlyTippyProps(this.props),
        onMount: instance => {
          this.zone.run(() => {
            this.isVisible = true;
            this.visible.next(true);
          });
          this.useHostWidth && this.listenToHostResize();
          this.globalConfig.onMount?.(instance);
        },
        onCreate: instance => {
          if (this.className) {
            for (const klass of normalizeClassName(this.className)) {
              instance.popper.classList.add(klass);
            }
          }
          this.globalConfig.onCreate?.(instance);
          if (this.isVisible === true) {
            instance.show();
          }
        },
        onShow: instance => {
          this.zone.run(() => {
            const content = this.resolveContent();
            if (isString$1(content)) {
              instance.setProps({
                allowHTML: false
              });
            }
            instance.setContent(content);
            this.hideOnEscape && this.handleEscapeButton();
          });
          if (this.useHostWidth) {
            // Don't access `hostWidth` multiple times since it's a getter that calls `getBoundingClientRect()`,
            // which triggers the whole layout update.
            const hostWidth = this.hostWidth;
            instance.popper.style.width = hostWidth;
            instance.popper.style.maxWidth = hostWidth;
            instance.popper.firstElementChild.style.maxWidth = hostWidth;
          }
          this.globalConfig.onShow?.(instance);
        },
        onHidden: instance => {
          this.destroyView();
          this.zone.run(() => {
            this.isVisible = false;
            this.visible.next(false);
          });
          this.globalConfig.onHidden?.(instance);
        }
      });
      this.setStatus();
      this.setProps(this.props);
      this.variation === 'contextMenu' && this.handleContextMenu();
    });
  }
  resolveContent() {
    if (!this.viewOptions$ && !isString$1(this.content)) {
      if (isComponent(this.content)) {
        this.instance.data = this.data;
        this.viewOptions$ = {
          injector: Injector.create({
            providers: [{
              provide: TIPPY_REF,
              useValue: this.instance
            }],
            parent: this.injector
          })
        };
      } else if (isTemplateRef(this.content)) {
        this.viewOptions$ = {
          context: {
            $implicit: this.hide.bind(this),
            data: this.data
          }
        };
      }
    }
    this.viewRef = this.viewService.createView(this.content, {
      vcr: this.vcr,
      ...this.viewOptions$
    });
    // We need to call detectChanges for onPush components to update the content
    if (this.detectChangesComponent && isComponent(this.content)) {
      this.viewRef.detectChanges();
    }
    let content = this.viewRef.getElement();
    if (isString$1(content) && this.globalConfig.beforeRender) {
      content = this.globalConfig.beforeRender(content);
    }
    return content;
  }
  handleContextMenu() {
    fromEvent(this.host, 'contextmenu').pipe(takeUntil(this.destroyed)).subscribe(event => {
      event.preventDefault();
      this.instance.setProps({
        getReferenceClientRect: () => ({
          width: 0,
          height: 0,
          top: event.clientY,
          bottom: event.clientY,
          left: event.clientX,
          right: event.clientX
        })
      });
      this.instance.show();
    });
  }
  handleEscapeButton() {
    this.pressButton$(document.body, 'Escape').pipe(takeUntil(merge(this.destroyed, this.visible.pipe(filter(v => !v))))).subscribe(() => this.hide());
  }
  pressButton$(element, codeButton) {
    return fromEvent(element, 'keydown').pipe(filter(({
      code
    }) => codeButton === code));
  }
  checkOverflow(isElementOverflow) {
    if (isElementOverflow) {
      if (!this.instance) {
        this.createInstance();
      } else {
        this.instance.enable();
      }
    } else {
      this.instance?.disable();
    }
  }
  listenToHostResize() {
    dimensionsChanges(this.host).pipe(takeUntil(merge(this.destroyed, this.visible))).subscribe(() => {
      this.instance.popper.style.width = this.hostWidth;
    });
  }
}
TippyDirective.ɵfac = function TippyDirective_Factory(t) {
  return new (t || TippyDirective)(i0.ɵɵdirectiveInject(PLATFORM_ID), i0.ɵɵdirectiveInject(TIPPY_CONFIG), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i1.ViewService), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.ElementRef));
};
TippyDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TippyDirective,
  selectors: [["", "tippy", ""]],
  inputs: {
    appendTo: "appendTo",
    delay: "delay",
    duration: "duration",
    hideOnClick: "hideOnClick",
    interactive: "interactive",
    interactiveBorder: "interactiveBorder",
    maxWidth: "maxWidth",
    offset: "offset",
    placement: "placement",
    popperOptions: "popperOptions",
    showOnCreate: "showOnCreate",
    trigger: "trigger",
    triggerTarget: "triggerTarget",
    zIndex: "zIndex",
    lazy: "lazy",
    variation: "variation",
    isEnabled: "isEnabled",
    className: "className",
    onlyTextOverflow: "onlyTextOverflow",
    data: "data",
    useHostWidth: "useHostWidth",
    hideOnEscape: "hideOnEscape",
    detectChangesComponent: "detectChangesComponent",
    content: [i0.ɵɵInputFlags.None, "tippy", "content"],
    customHost: [i0.ɵɵInputFlags.None, "tippyHost", "customHost"],
    isVisible: "isVisible"
  },
  outputs: {
    visible: "visible"
  },
  exportAs: ["tippy"],
  features: [i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TippyDirective, [{
    type: Directive,
    args: [{
      // eslint-disable-next-line @angular-eslint/directive-selector
      selector: '[tippy]',
      exportAs: 'tippy'
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [PLATFORM_ID]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TIPPY_CONFIG]
      }]
    }, {
      type: i0.Injector
    }, {
      type: i1.ViewService
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.ElementRef
    }];
  }, {
    appendTo: [{
      type: Input
    }],
    delay: [{
      type: Input
    }],
    duration: [{
      type: Input
    }],
    hideOnClick: [{
      type: Input
    }],
    interactive: [{
      type: Input
    }],
    interactiveBorder: [{
      type: Input
    }],
    maxWidth: [{
      type: Input
    }],
    offset: [{
      type: Input
    }],
    placement: [{
      type: Input
    }],
    popperOptions: [{
      type: Input
    }],
    showOnCreate: [{
      type: Input
    }],
    trigger: [{
      type: Input
    }],
    triggerTarget: [{
      type: Input
    }],
    zIndex: [{
      type: Input
    }],
    lazy: [{
      type: Input
    }],
    variation: [{
      type: Input
    }],
    isEnabled: [{
      type: Input
    }],
    className: [{
      type: Input
    }],
    onlyTextOverflow: [{
      type: Input
    }],
    data: [{
      type: Input
    }],
    useHostWidth: [{
      type: Input
    }],
    hideOnEscape: [{
      type: Input
    }],
    detectChangesComponent: [{
      type: Input
    }],
    content: [{
      type: Input,
      args: ['tippy']
    }],
    customHost: [{
      type: Input,
      args: ['tippyHost']
    }],
    visible: [{
      type: Output
    }],
    isVisible: [{
      type: Input
    }]
  });
})();
function isChanged(key, changes) {
  return key in changes;
}
class TippyModule {
  static forRoot(config = {}) {
    return {
      ngModule: TippyModule,
      providers: [{
        provide: TIPPY_CONFIG,
        useValue: config
      }]
    };
  }
}
TippyModule.ɵfac = function TippyModule_Factory(t) {
  return new (t || TippyModule)();
};
TippyModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TippyModule
});
TippyModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TippyModule, [{
    type: NgModule,
    args: [{
      declarations: [TippyDirective],
      exports: [TippyDirective]
    }]
  }], null, null);
})();
const tooltipVariation = {
  theme: null,
  arrow: false,
  animation: 'scale',
  trigger: 'mouseenter',
  offset: [0, 5]
};
const popperVariation = {
  theme: 'light',
  arrow: true,
  offset: [0, 10],
  animation: null,
  trigger: 'click',
  interactive: true
};
function withContextMenuVariation(baseVariation) {
  return {
    ...baseVariation,
    placement: 'right-start',
    trigger: 'manual',
    arrow: false,
    offset: [0, 0]
  };
}
class TippyService {
  constructor(globalConfig, view, injector) {
    this.globalConfig = globalConfig;
    this.view = view;
    this.injector = injector;
  }
  create(host, content, options = {}) {
    const config = {
      onShow: instance => {
        if (!instance.$viewOptions) {
          instance.$viewOptions = {};
          if (isTemplateRef(content)) {
            instance.$viewOptions.context = {
              $implicit: instance.hide.bind(instance),
              ...options.context
            };
          } else if (isComponent(content)) {
            instance.context = options.context;
            instance.data = options.data;
            instance.$viewOptions.injector = Injector.create({
              providers: [{
                provide: TIPPY_REF,
                useValue: instance
              }],
              parent: options.injector || this.injector
            });
          }
        }
        if (!instance.view) {
          instance.view = this.view.createView(content, {
            ...options,
            ...instance.$viewOptions
          });
        }
        instance.setContent(instance.view.getElement());
        options?.onShow?.(instance);
      },
      onHidden: instance => {
        if (!options.preserveView) {
          instance.view.destroy();
          instance.view = null;
        }
        options?.onHidden?.(instance);
      },
      ...onlyTippyProps(this.globalConfig),
      ...this.globalConfig.variations[options.variation || this.globalConfig.defaultVariation],
      ...onlyTippyProps(options),
      onCreate: instance => {
        if (options.className) {
          for (const klass of normalizeClassName(options.className)) {
            instance.popper.classList.add(klass);
          }
        }
        this.globalConfig.onCreate?.(instance);
        options.onCreate?.(instance);
      }
    };
    return tippy(host, config);
  }
}
TippyService.ɵfac = function TippyService_Factory(t) {
  return new (t || TippyService)(i0.ɵɵinject(TIPPY_CONFIG), i0.ɵɵinject(i1.ViewService), i0.ɵɵinject(i0.Injector));
};
TippyService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: TippyService,
  factory: TippyService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TippyService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TIPPY_CONFIG]
      }]
    }, {
      type: i1.ViewService
    }, {
      type: i0.Injector
    }];
  }, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TIPPY_CONFIG, TIPPY_REF, TippyDirective, TippyModule, TippyService, inView, overflowChanges, popperVariation, tooltipVariation, withContextMenuVariation };
