import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '@microsoft/microsoft-graph-types';
import { HttpService } from '@core/services/http.service';
import { GRAPH_ENDPOINT } from '@core/tokens/graph-endpoint.token';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GraphService {
  constructor(
    private readonly httpService: HttpService,
    @Inject(GRAPH_ENDPOINT) private readonly graphEndpoint: string,
    private readonly http: HttpClient,
  ) {}

  public getCurrentUser(): Observable<User> {
    return this.http
      .get<User>(`${this.graphEndpoint}/me`)
      .pipe(this.httpService.trackError());
  }

  public getUser(email: string): Observable<User> {
    return this.http
      .get<User>(`${this.graphEndpoint}/users/${email}`)
      .pipe(this.httpService.trackError());
  }
}
