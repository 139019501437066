import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Theme } from '@core/models/theme';

interface SettingsStoreState {
  theme: Theme;
}

@Injectable({ providedIn: 'root' })
export class SettingsStore extends ComponentStore<SettingsStoreState> {
  readonly theme$ = this.select(({ theme }) => theme);

  constructor() {
    super({ theme: 'light' });
  }

  readonly updateTheme = this.updater<Theme>((state, theme) => ({
    ...state,
    theme,
  }));
}
