import { HttpErrorResponse } from '@angular/common/http';
import { HttpDataState } from '@core/types/http-data-state';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, Subscription } from 'rxjs';

export type EntityKey = string | number | symbol;

export type HttpEntityState<T> = Record<EntityKey, HttpDataState<T>>;

export abstract class HttpEntityStore<T> extends ComponentStore<
  HttpEntityState<T>
> {
  readonly selectById = (entityId: EntityKey): Observable<HttpDataState<T>> =>
    this.select((state) => state[entityId] ?? { status: 'idle' });

  constructor() {
    super({});
  }

  abstract loadById(...args: unknown[]): Subscription;

  readonly setLoading = this.updater<EntityKey>((state, entityId) => ({
    ...state,
    [entityId]: <HttpDataState<T>>{
      ...state[entityId],
      status: 'loading',
    },
  }));

  readonly setSuccess = this.updater<[EntityKey, T]>(
    (state, [entityId, data]) => ({
      ...state,
      [entityId]: <HttpDataState<T>>{
        status: 'success',
        data,
        cachedAt: new Date(Date.now()),
      },
    }),
  );

  readonly setError = this.updater<[EntityKey, HttpErrorResponse]>(
    (state, [entityId, error]) => ({
      ...state,
      [entityId]: <HttpDataState<T>>{
        ...state[entityId],
        status: 'error',
        error,
      },
    }),
  );

  readonly clear = this.updater<EntityKey>((state, entityId) => {
    delete state[entityId];
    return state;
  });
}
