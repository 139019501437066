import { inject, InjectionToken } from '@angular/core';
import { RUNTIME_CONFIGURATION } from '@core/tokens/runtime-configuration.token';

function detailedEndpointFactory(): string {
  return `${
    inject(RUNTIME_CONFIGURATION).api.zero.baseUrl
  }/assessments/detailed`;
}

export const DETAILED_ENDPOINT = new InjectionToken<string>(
  'DETAILED_ENDPOINT',
  { factory: detailedEndpointFactory },
);
