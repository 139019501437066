import { Pipe, PipeTransform } from '@angular/core';

type OrderByType = string;

type OrderByDirection = 'asc' | 'desc';

@Pipe({ name: 'orderBy' })
export class OrderByPipe implements PipeTransform {
  transform(
    value: OrderByType[],
    direction: OrderByDirection = 'asc',
  ): OrderByType[] {
    return value.sort((a, b) => {
      if (a.match(/\d+/g) && b.match(/\d+/g)) {
        const aNumbers = a.match(/\d+/g)!.map(Number);
        const bNumbers = b.match(/\d+/g)!.map(Number);
        const aStrings = a.split(/\d+/g);
        const bStrings = b.split(/\d+/g);
        let i = 0;
        while (i < aNumbers.length || i < bNumbers.length) {
          if (
            i >= aNumbers.length ||
            isNaN(aNumbers[i]) ||
            i >= bNumbers.length ||
            isNaN(bNumbers[i])
          ) {
            return direction === 'asc' ? -1 : 1;
          }
          if (aNumbers[i] !== bNumbers[i]) {
            return direction === 'asc'
              ? aNumbers[i] - bNumbers[i]
              : bNumbers[i] - aNumbers[i];
          }
          if (aStrings[i] !== bStrings[i]) {
            return direction === 'asc'
              ? aStrings[i].localeCompare(bStrings[i])
              : bStrings[i].localeCompare(aStrings[i]);
          }
          i++;
        }
      }
      return direction === 'asc' ? a.localeCompare(b) : b.localeCompare(a);
    });
  }
}
