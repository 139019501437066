import { InjectionToken, inject } from '@angular/core';
import { ACCOUNTING_CENTRE_ENDPOINT } from '@ads/tokens/accounting-centre-endpoint.token';
import { BUSINESS_ENDPOINT } from '@ads/tokens/business-endpoint.token';
import { GROUPS_OF_GROUPS_ENDPOINT } from '@ads/tokens/groups-of-groups-endpoint.token';
import { REGION_ENDPOINT } from '@ads/tokens/region-endpoint.token';
import { CAMPAIGN_ENDPOINT } from '@core/tokens/campaign-endpoint.token';
import { PROJECT_ENDPOINT } from '@project/tokens/project-endpoint.token';
import { SITE_ENDPOINT } from '@asset/tokens/site-endpoint.token';
import { ASSET_ENDPOINT } from '@asset/tokens/asset-endpoint.token';
import { BENCHMARK_ENDPOINT } from '@benchmark-assessment/tokens/benchmark-endpoint.token';
import { OPERATIONAL_ENDPOINT } from '@operational-assessment/tokens/operational-endpoint.token';
import { EMBODIED_ENDPOINT } from '@embodied-assessment/tokens/embodied-endpoint.token';
import { DETAILED_ENDPOINT } from '@detailed-assessment/tokens/detailed-endpoint.token';
import { ENERGY_MODEL_ENDPOINT } from '@energy-model-assessment/tokens/energy-model-endpoint.token';

export const MONITOR_REQUEST_CONFIG = new InjectionToken<RegExp[]>(
  'MONITOR_REQUEST_CONFIG',
  {
    providedIn: 'root',
    factory: () => [
      new RegExp(inject(ACCOUNTING_CENTRE_ENDPOINT)),
      new RegExp(inject(BUSINESS_ENDPOINT)),
      new RegExp(inject(GROUPS_OF_GROUPS_ENDPOINT)),
      new RegExp(inject(REGION_ENDPOINT)),
      new RegExp(inject(CAMPAIGN_ENDPOINT)),
      new RegExp(inject(PROJECT_ENDPOINT)),
      new RegExp(inject(SITE_ENDPOINT)),
      new RegExp(inject(ASSET_ENDPOINT)),
      new RegExp(inject(BENCHMARK_ENDPOINT)),
      new RegExp(inject(OPERATIONAL_ENDPOINT)),
      new RegExp(inject(EMBODIED_ENDPOINT)),
      new RegExp(inject(DETAILED_ENDPOINT)),
      new RegExp(inject(ENERGY_MODEL_ENDPOINT)),
    ],
  },
);
