import { inject, InjectionToken } from '@angular/core';
import { RUNTIME_CONFIGURATION } from '@core/tokens/runtime-configuration.token';

function operationalEndpointFactory(): string {
  return `${
    inject(RUNTIME_CONFIGURATION).api.zero.baseUrl
  }/assessments/high_level/operational`;
}

export const OPERATIONAL_ENDPOINT = new InjectionToken<string>(
  'OPERATIONAL_ENDPOINT',
  { factory: operationalEndpointFactory },
);
