import { Pipe, PipeTransform } from '@angular/core';
import { SystemModel } from '@asset/models/system.model';

@Pipe({ name: 'systemScope' })
export class SystemScopePipe implements PipeTransform {
  transform(
    systems: Array<SystemModel>,
    scope: SystemModel['scope'],
  ): Array<SystemModel> {
    return systems.filter((system) => system.scope === scope);
  }
}
