import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { User } from '@microsoft/microsoft-graph-types';
import { RuntimeConfigurationModel } from '@core/models/runtime-configuration.model';
import { Router } from '@angular/router';

@Component({
  selector: 'zero-authenticated-welcome[profile][logoURL][env]',
  templateUrl: './authenticated-welcome.component.html',
  styleUrls: ['./authenticated-welcome.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthenticatedWelcomeComponent {
  @Input() profile!: User | null;
  @Input() authenticated: boolean | null = false;
  @Input() logoURL!: string;
  @Input() env!: RuntimeConfigurationModel['env'];

  private readonly router = inject(Router);

  public gotoCollectionPage(): void {
    this.router.navigate(['/collection']);
  }
}
