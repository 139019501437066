import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';

export const EXCEL_UPLOAD_MODAL_ID = 'excel-upload-modal';

export const EXCEL_MODAL_CONFIG: MatDialogConfig = {
  disableClose: true,
  id: EXCEL_UPLOAD_MODAL_ID,
  minWidth: '380px',
};

export interface ExcelUploadModalData {
  excelTemplateFileName: string;
  excelTemplateUrl: string;
}

export type ExcelUploadModalResult = File | undefined;

@Component({
  selector: 'zero-excel-upload-modal',
  templateUrl: './excel-upload-modal.component.html',
  styleUrls: ['./excel-upload-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExcelUploadModalComponent {
  @ViewChild('excelFileInputText')
  public excelFileInputText!: ElementRef<HTMLInputElement>;
  public excelFile?: File;

  constructor(
    private readonly dialogRef: MatDialogRef<ExcelUploadModalComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: ExcelUploadModalData,
  ) {}

  public handleExcelFileInputChange(event: Event): void {
    const excelFileInput = event.target as HTMLInputElement;
    const excelFile = excelFileInput.files?.[0];
    if (
      excelFile &&
      excelFile.type === 'application/vnd.oasis.opendocument.spreadsheet'
    ) {
      this.excelFileInputText.nativeElement.value = excelFile.name;
      this.excelFile = excelFile;
    }
  }

  public handleExcelFileDrop(event: DragEvent): void {
    event.preventDefault();
    const excelFile = event.dataTransfer?.files?.[0];
    if (
      excelFile &&
      excelFile.type === 'application/vnd.oasis.opendocument.spreadsheet'
    ) {
      this.excelFileInputText.nativeElement.value = excelFile.name;
      this.excelFile = excelFile;
    }
  }

  public handleCancel(): void {
    this.dialogRef.close();
  }

  public handleUpload(): void {
    this.dialogRef.close(this.excelFile);
  }
}
