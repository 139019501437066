import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { AssessmentType, humanize } from '@core/enums/assessment-type';

@Component({
  selector: 'zero-create-assessment[assessmentType][assessmentExists]',
  templateUrl: './create-assessment.component.html',
  styleUrls: ['./create-assessment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateAssessmentComponent {
  @Input() public assessmentType!: AssessmentType;
  @Input() public assessmentExists!: boolean;
  @Output() public createAssessment = new EventEmitter<AssessmentType>();
  public readonly assessmentTypeDisplayString = humanize;
}
