<section class="authenticated-welcome">
  <div class="content">
    <div>
      <img alt="Zero logo" class="zero-logo" src="{{ logoURL }}" />
      <h1>
        <ng-container *ngIf="authenticated"
          >{{ profile?.givenName }},
        </ng-container>
        welcome to
        <i><strong>Zero</strong></i
        >:
        <br />
        Arup's carbon tracking platform
      </h1>
    </div>

    <div class="actions">
      <ng-container *ngIf="authenticated">
        <button color="accent" mat-flat-button (click)="gotoCollectionPage()">
          Enter
        </button>
      </ng-container>
      <zero-login *ngIf="!authenticated"></zero-login>
    </div>
    <div class="external-links">
      <zero-external-link
        *ngIf="env !== 'test'"
        href="https://test.zero.arup.com"
        >Test the platform first</zero-external-link
      >
      <zero-external-link
        href="https://arup.sharepoint.com/sites/Sustainable-Development/SitePages/Our-Whole-Life-Carbon-(WLC)-Initiative-Buildings.aspx"
        >Learn more about Arup's whole life carbon
        commitment</zero-external-link
      >
    </div>
    <img alt="DDB logo" class="by-ddb-logo" src="assets/supported-by-ddb.svg" />
  </div>

  <svg
    class="background"
    fill="none"
    viewBox="0 0 779 848"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M344 521C377.072 548.255 434.202 626.618 398.144 722.032C353.072 841.3 524.48 851.867 532.215 909.119C538.403 954.921 527.027 964.062 520.565 962.907"
      stroke="var(--zero-background-svg)"
    />
    <path
      d="M581 632C597.885 650.419 627.051 703.377 608.642 767.858C585.632 848.46 673.141 855.601 677.09 894.293C680.249 925.246 674.441 931.423 671.142 930.642"
      stroke="var(--zero-background-svg)"
      stroke-width="3"
    />
    <path
      d="M732.071 412C708.038 438.217 666.522 513.598 692.725 605.381C725.479 720.11 600.918 730.275 595.297 785.348C590.801 829.406 599.068 838.199 603.763 837.088"
      stroke="var(--zero-background-svg)"
    />
    <path
      d="M629 538C640.981 564.217 661.678 639.598 648.615 731.381C632.286 846.11 694.383 856.275 697.185 911.348C699.427 955.406 695.306 964.199 692.965 963.088"
      stroke="var(--zero-background-svg)"
      stroke-width="2.03046"
    />
    <mask fill="white" id="path-5-inside-1_1843_42025">
      <path
        d="M616.424 187.891C622.496 198.127 630.353 207.193 639.623 214.658L656.205 194.068C649.395 188.583 643.623 181.923 639.161 174.402L616.424 187.891Z"
      />
    </mask>
    <path
      d="M616.424 187.891C622.496 198.127 630.353 207.193 639.623 214.658L656.205 194.068C649.395 188.583 643.623 181.923 639.161 174.402L616.424 187.891Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-5-inside-1_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-6-inside-2_1843_42025">
      <path
        d="M629.635 251.044C644.921 261.081 662.675 266.719 680.952 267.339C699.229 267.958 717.325 263.537 733.256 254.558L708.793 211.152C700.827 215.642 691.78 217.852 682.641 217.543C673.503 217.233 664.625 214.414 656.982 209.395L629.635 251.044Z"
      />
    </mask>
    <path
      d="M629.635 251.044C644.921 261.081 662.675 266.719 680.952 267.339C699.229 267.958 717.325 263.537 733.256 254.558L708.793 211.152C700.827 215.642 691.78 217.852 682.641 217.543C673.503 217.233 664.625 214.414 656.982 209.395L629.635 251.044Z"
      mask="url(#path-6-inside-2_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-7-inside-3_1843_42025">
      <path
        d="M736.045 235.635C747.308 228.914 757.133 220.036 764.957 209.51L724.969 179.787C721.057 185.05 716.144 189.489 710.513 192.85L736.045 235.635Z"
      />
    </mask>
    <path
      d="M736.045 235.635C747.308 228.914 757.133 220.036 764.957 209.51L724.969 179.787C721.057 185.05 716.144 189.489 710.513 192.85L736.045 235.635Z"
      mask="url(#path-7-inside-3_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-8-inside-4_1843_42025">
      <path
        d="M585.425 145.746C586.238 127.005 592.324 108.874 602.984 93.4385C613.643 78.0028 628.443 65.8894 645.682 58.4914L665.331 104.278C656.712 107.977 649.312 114.033 643.982 121.751C638.653 129.469 635.61 138.535 635.203 147.905L585.425 145.746Z"
      />
    </mask>
    <path
      d="M585.425 145.746C586.238 127.005 592.324 108.874 602.984 93.4385C613.643 78.0028 628.443 65.8894 645.682 58.4914L665.331 104.278C656.712 107.977 649.312 114.033 643.982 121.751C638.653 129.469 635.61 138.535 635.203 147.905L585.425 145.746Z"
      mask="url(#path-8-inside-4_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <path
      d="M569.879 207.324C560.578 189.331 555.97 169.281 556.484 149.033L616.105 150.711C615.877 160.808 618.173 170.803 622.787 179.787L569.879 207.324Z"
      stroke="var(--zero-background-svg)"
      stroke-width="0.669328"
    />
    <mask fill="white" id="path-10-inside-5_1843_42025">
      <path
        d="M654.878 75.2291C660.012 73.132 665.341 71.552 670.788 70.5124L676.419 100.014C672.933 100.679 669.522 101.69 666.236 103.032L654.878 75.2291Z"
      />
    </mask>
    <path
      d="M654.878 75.2291C660.012 73.132 665.341 71.552 670.788 70.5124L676.419 100.014C672.933 100.679 669.522 101.69 666.236 103.032L654.878 75.2291Z"
      mask="url(#path-10-inside-5_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-11-inside-6_1843_42025">
      <path
        d="M671.761 70.3327C674.755 69.7981 677.775 69.4275 680.809 69.2227L682.832 99.1884C680.89 99.3195 678.957 99.5566 677.041 99.8988L671.761 70.3327Z"
      />
    </mask>
    <path
      d="M671.761 70.3327C674.755 69.7981 677.775 69.4275 680.809 69.2227L682.832 99.1884C680.89 99.3195 678.957 99.5566 677.041 99.8988L671.761 70.3327Z"
      mask="url(#path-11-inside-6_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-12-inside-7_1843_42025">
      <path
        d="M682.93 48.5567C686.679 48.3577 690.438 48.4121 694.181 48.7196L691.722 78.6526C689.327 78.4558 686.921 78.421 684.521 78.5483L682.93 48.5567Z"
      />
    </mask>
    <path
      d="M682.93 48.5567C686.679 48.3577 690.438 48.4121 694.181 48.7196L691.722 78.6526C689.327 78.4558 686.921 78.421 684.521 78.5483L682.93 48.5567Z"
      mask="url(#path-12-inside-7_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask
      fill="black"
      height="41.8106"
      id="path-13-outside-8_1843_42025"
      maskUnits="userSpaceOnUse"
      width="41.8106"
      x="682.908"
      y="68.6093"
    >
      <rect
        fill="white"
        height="41.8106"
        width="41.8106"
        x="682.908"
        y="68.6093"
      />
      <path
        d="M693.539 78.4398C701.978 79.472 710.167 81.994 717.725 85.8886L709.235 102.364C703.627 99.4747 697.551 97.6033 691.289 96.8375L693.539 78.4398Z"
      />
    </mask>
    <path
      d="M693.539 78.4398C701.978 79.472 710.167 81.994 717.725 85.8886L709.235 102.364C703.627 99.4747 697.551 97.6033 691.289 96.8375L693.539 78.4398Z"
      mask="url(#path-13-outside-8_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-linejoin="round"
      stroke-width="1.33866"
    />
    <mask
      fill="black"
      height="39.5385"
      id="path-14-outside-9_1843_42025"
      maskUnits="userSpaceOnUse"
      width="38.6878"
      x="703.637"
      y="79.4975"
    >
      <rect
        fill="white"
        height="39.5385"
        width="38.6878"
        x="703.637"
        y="79.4975"
      />
      <path
        d="M718.621 86.3585C726.188 90.3938 732.972 95.7513 738.651 102.178L724.762 114.451C720.548 109.683 715.515 105.707 709.9 102.713L718.621 86.3585Z"
      />
    </mask>
    <path
      d="M718.621 86.3585C726.188 90.3938 732.972 95.7513 738.651 102.178L724.762 114.451C720.548 109.683 715.515 105.707 709.9 102.713L718.621 86.3585Z"
      mask="url(#path-14-outside-9_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-linejoin="round"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-15-inside-10_1843_42025">
      <path
        d="M642.8 172.02C637.485 161.807 636.068 150.006 638.814 138.806C641.56 127.607 648.282 117.769 657.731 111.12C667.181 104.471 678.716 101.461 690.197 102.65C701.678 103.838 712.325 109.144 720.161 117.583L710.009 126.975C704.444 120.982 696.883 117.214 688.73 116.37C680.577 115.526 672.385 117.663 665.674 122.385C658.964 127.107 654.19 134.093 652.24 142.046C650.29 150 651.296 158.38 655.071 165.633L642.8 172.02Z"
      />
    </mask>
    <path
      d="M642.8 172.02C637.485 161.807 636.068 150.006 638.814 138.806C641.56 127.607 648.282 117.769 657.731 111.12C667.181 104.471 678.716 101.461 690.197 102.65C701.678 103.838 712.325 109.144 720.161 117.583L710.009 126.975C704.444 120.982 696.883 117.214 688.73 116.37C680.577 115.526 672.385 117.663 665.674 122.385C658.964 127.107 654.19 134.093 652.24 142.046C650.29 150 651.296 158.38 655.071 165.633L642.8 172.02Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-15-inside-10_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-16-inside-11_1843_42025">
      <path
        d="M770.486 81.6724C786.272 98.425 795.77 120.126 797.369 143.088C798.968 166.05 792.568 188.858 779.257 207.636L738.609 178.823C745.264 169.434 748.464 158.03 747.665 146.549C746.865 135.068 742.116 124.217 734.224 115.841L770.486 81.6724Z"
      />
    </mask>
    <path
      d="M770.486 81.6724C786.272 98.425 795.77 120.126 797.369 143.088C798.968 166.05 792.568 188.858 779.257 207.636L738.609 178.823C745.264 169.434 748.464 158.03 747.665 146.549C746.865 135.068 742.116 124.217 734.224 115.841L770.486 81.6724Z"
      mask="url(#path-16-inside-11_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-17-inside-12_1843_42025">
      <path
        d="M724.735 113.783C729.901 119.419 733.802 126.094 736.179 133.36C738.556 140.626 739.353 148.317 738.517 155.916C737.68 163.515 735.23 170.848 731.33 177.424C727.43 183.999 722.17 189.666 715.902 194.044C709.634 198.421 702.503 201.409 694.987 202.808C687.471 204.206 679.743 203.983 672.32 202.152C664.897 200.321 657.951 196.926 651.947 192.193C645.943 187.46 641.019 181.499 637.506 174.709L651.217 167.614C653.72 172.451 657.227 176.697 661.504 180.069C665.781 183.44 670.729 185.859 676.017 187.163C681.304 188.467 686.809 188.626 692.163 187.63C697.518 186.634 702.597 184.505 707.062 181.387C711.527 178.268 715.274 174.232 718.052 169.548C720.83 164.864 722.576 159.64 723.171 154.227C723.767 148.814 723.199 143.336 721.506 138.16C719.813 132.984 717.034 128.229 713.354 124.214L724.735 113.783Z"
      />
    </mask>
    <path
      d="M724.735 113.783C729.901 119.419 733.802 126.094 736.179 133.36C738.556 140.626 739.353 148.317 738.517 155.916C737.68 163.515 735.23 170.848 731.33 177.424C727.43 183.999 722.17 189.666 715.902 194.044C709.634 198.421 702.503 201.409 694.987 202.808C687.471 204.206 679.743 203.983 672.32 202.152C664.897 200.321 657.951 196.926 651.947 192.193C645.943 187.46 641.019 181.499 637.506 174.709L651.217 167.614C653.72 172.451 657.227 176.697 661.504 180.069C665.781 183.44 670.729 185.859 676.017 187.163C681.304 188.467 686.809 188.626 692.163 187.63C697.518 186.634 702.597 184.505 707.062 181.387C711.527 178.268 715.274 174.232 718.052 169.548C720.83 164.864 722.576 159.64 723.171 154.227C723.767 148.814 723.199 143.336 721.506 138.16C719.813 132.984 717.034 128.229 713.354 124.214L724.735 113.783Z"
      mask="url(#path-17-inside-12_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-18-inside-13_1843_42025">
      <path
        d="M605.814 284.476C607.552 277.668 608.056 270.604 607.303 263.617L591.785 265.29C592.338 270.423 591.968 275.613 590.691 280.615L605.814 284.476Z"
      />
    </mask>
    <path
      d="M605.814 284.476C607.552 277.668 608.056 270.604 607.303 263.617L591.785 265.29C592.338 270.423 591.968 275.613 590.691 280.615L605.814 284.476Z"
      mask="url(#path-18-inside-13_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-19-inside-14_1843_42025">
      <path
        d="M626.663 252.596C624.472 242.024 619.413 232.259 612.042 224.37C604.671 216.481 595.27 210.773 584.871 207.87L576.963 236.204C582.163 237.655 586.863 240.509 590.549 244.453C594.234 248.398 596.764 253.281 597.859 258.567L626.663 252.596Z"
      />
    </mask>
    <path
      d="M626.663 252.596C624.472 242.024 619.413 232.259 612.042 224.37C604.671 216.481 595.27 210.773 584.871 207.87L576.963 236.204C582.163 237.655 586.863 240.509 590.549 244.453C594.234 248.398 596.764 253.281 597.859 258.567L626.663 252.596Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-19-inside-14_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-20-inside-15_1843_42025">
      <path
        d="M575.807 214.605C568.299 212.709 560.491 212.314 552.831 213.442L557.116 242.544C560.946 241.98 564.85 242.178 568.604 243.126L575.807 214.605Z"
      />
    </mask>
    <path
      d="M575.807 214.605C568.299 212.709 560.491 212.314 552.831 213.442L557.116 242.544C560.946 241.98 564.85 242.178 568.604 243.126L575.807 214.605Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-20-inside-15_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-21-inside-16_1843_42025">
      <path
        d="M601.16 315.011C592.997 322.495 582.887 327.524 571.993 329.517C561.099 331.511 549.863 330.39 539.579 326.282L550.49 298.964C555.632 301.018 561.25 301.579 566.697 300.582C572.144 299.585 577.199 297.071 581.281 293.329L601.16 315.011Z"
      />
    </mask>
    <path
      d="M601.16 315.011C592.997 322.495 582.887 327.524 571.993 329.517C561.099 331.511 549.863 330.39 539.579 326.282L550.49 298.964C555.632 301.018 561.25 301.579 566.697 300.582C572.144 299.585 577.199 297.071 581.281 293.329L601.16 315.011Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-21-inside-16_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <path
      d="M633.185 295.883C629.57 307.16 623.187 317.351 614.62 325.527L590.618 300.135C594.863 296.047 598.038 290.978 599.863 285.374L633.185 295.883Z"
      fill="var(--zero-background-svg)"
      stroke="var(--zero-background-svg)"
      stroke-width="0.669328"
    />
    <mask fill="white" id="path-23-inside-17_1843_42025">
      <path
        d="M542.727 315.455C539.708 314.188 536.824 312.622 534.116 310.782L544.081 296.116C545.814 297.293 547.66 298.295 549.592 299.106L542.727 315.455Z"
      />
    </mask>
    <path
      d="M542.727 315.455C539.708 314.188 536.824 312.622 534.116 310.782L544.081 296.116C545.814 297.293 547.66 298.295 549.592 299.106L542.727 315.455Z"
      mask="url(#path-23-inside-17_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-24-inside-18_1843_42025">
      <path
        d="M533.635 310.451C532.162 309.425 530.746 308.318 529.394 307.137L541.059 293.783C541.924 294.539 542.831 295.247 543.773 295.904L533.635 310.451Z"
      />
    </mask>
    <path
      d="M533.635 310.451C532.162 309.425 530.746 308.318 529.394 307.137L541.059 293.783C541.924 294.539 542.831 295.247 543.773 295.904L533.635 310.451Z"
      mask="url(#path-24-inside-18_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-25-inside-19_1843_42025">
      <path
        d="M519.882 314.88C518.233 313.397 516.687 311.805 515.253 310.115L528.776 298.645C529.693 299.727 530.683 300.746 531.738 301.695L519.882 314.88Z"
      />
    </mask>
    <path
      d="M519.882 314.88C518.233 313.397 516.687 311.805 515.253 310.115L528.776 298.645C529.693 299.727 530.683 300.746 531.738 301.695L519.882 314.88Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-25-inside-19_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask
      fill="black"
      height="24.7886"
      id="path-26-outside-20_1843_42025"
      maskUnits="userSpaceOnUse"
      width="24.1357"
      x="516.397"
      y="276.962"
    >
      <rect
        fill="white"
        height="24.7886"
        width="24.1357"
        x="516.397"
        y="276.962"
      />
      <path
        d="M527.929 297.976C524.836 294.022 522.471 289.55 520.941 284.769L531.364 281.435C532.499 284.983 534.254 288.301 536.549 291.235L527.929 297.976Z"
      />
    </mask>
    <path
      d="M527.929 297.976C524.836 294.022 522.471 289.55 520.941 284.769L531.364 281.435C532.499 284.983 534.254 288.301 536.549 291.235L527.929 297.976Z"
      fill="var(--zero-background-svg)"
    />
    <path
      d="M527.929 297.976C524.836 294.022 522.471 289.55 520.941 284.769L531.364 281.435C532.499 284.983 534.254 288.301 536.549 291.235L527.929 297.976Z"
      mask="url(#path-26-outside-20_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-linejoin="round"
      stroke-width="1.33866"
    />
    <mask
      fill="black"
      height="23.5341"
      id="path-27-outside-21_1843_42025"
      maskUnits="userSpaceOnUse"
      width="22.8812"
      x="513.029"
      y="265.32"
    >
      <rect
        fill="white"
        height="23.5341"
        width="22.8812"
        x="513.029"
        y="265.32"
      />
      <path
        d="M520.763 284.199C519.289 279.355 518.694 274.287 519.006 269.233L529.928 269.908C529.696 273.657 530.138 277.418 531.232 281.012L520.763 284.199Z"
      />
    </mask>
    <path
      d="M520.763 284.199C519.289 279.355 518.694 274.287 519.006 269.233L529.928 269.908C529.696 273.657 530.138 277.418 531.232 281.012L520.763 284.199Z"
      mask="url(#path-27-outside-21_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-linejoin="round"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-28-inside-22_1843_42025">
      <path
        d="M588.175 280.09C586.131 286.573 581.795 292.09 575.974 295.62C570.152 299.149 563.239 300.45 556.518 299.281C549.797 298.111 543.725 294.552 539.428 289.263C535.132 283.974 532.902 277.314 533.154 270.52L541.313 270.837C541.134 275.662 542.717 280.392 545.769 284.148C548.82 287.904 553.132 290.432 557.905 291.262C562.678 292.092 567.587 291.168 571.721 288.662C575.856 286.156 578.934 282.237 580.386 277.634L588.175 280.09Z"
      />
    </mask>
    <path
      d="M588.175 280.09C586.131 286.573 581.795 292.09 575.974 295.62C570.152 299.149 563.239 300.45 556.518 299.281C549.797 298.111 543.725 294.552 539.428 289.263C535.132 283.974 532.902 277.314 533.154 270.52L541.313 270.837C541.134 275.662 542.717 280.392 545.769 284.148C548.82 287.904 553.132 290.432 557.905 291.262C562.678 292.092 567.587 291.168 571.721 288.662C575.856 286.156 578.934 282.237 580.386 277.634L588.175 280.09Z"
      mask="url(#path-28-inside-22_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-29-inside-23_1843_42025">
      <path
        d="M497.154 264.503C497.558 250.919 502.652 237.894 511.571 227.641C520.489 217.387 532.683 210.537 546.079 208.255L551.02 237.253C544.322 238.394 538.225 241.819 533.766 246.946C529.306 252.073 526.759 258.585 526.557 265.377L497.154 264.503Z"
      />
    </mask>
    <path
      d="M497.154 264.503C497.558 250.919 502.652 237.894 511.571 227.641C520.489 217.387 532.683 210.537 546.079 208.255L551.02 237.253C544.322 238.394 538.225 241.819 533.766 246.946C529.306 252.073 526.759 258.585 526.557 265.377L497.154 264.503Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-29-inside-23_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-30-inside-24_1843_42025">
      <path
        d="M529.659 270.197C529.856 265.687 531.013 261.272 533.054 257.246C535.096 253.22 537.973 249.677 541.495 246.854C545.017 244.031 549.101 241.992 553.474 240.875C557.847 239.758 562.409 239.589 566.853 240.378C571.297 241.167 575.522 242.896 579.243 245.45C582.965 248.004 586.098 251.324 588.432 255.187C590.767 259.05 592.249 263.367 592.78 267.85C593.311 272.332 592.877 276.876 591.51 281.177L582.824 278.415C583.798 275.351 584.107 272.114 583.729 268.921C583.35 265.728 582.295 262.653 580.632 259.901C578.969 257.149 576.737 254.785 574.086 252.965C571.435 251.146 568.426 249.914 565.26 249.352C562.094 248.79 558.845 248.911 555.729 249.706C552.614 250.502 549.705 251.954 547.196 253.965C544.688 255.976 542.638 258.5 541.184 261.368C539.73 264.236 538.905 267.381 538.765 270.593L529.659 270.197Z"
      />
    </mask>
    <path
      d="M529.659 270.197C529.856 265.687 531.013 261.272 533.054 257.246C535.096 253.22 537.973 249.677 541.495 246.854C545.017 244.031 549.101 241.992 553.474 240.875C557.847 239.758 562.409 239.589 566.853 240.378C571.297 241.167 575.522 242.896 579.243 245.45C582.965 248.004 586.098 251.324 588.432 255.187C590.767 259.05 592.249 263.367 592.78 267.85C593.311 272.332 592.877 276.876 591.51 281.177L582.824 278.415C583.798 275.351 584.107 272.114 583.729 268.921C583.35 265.728 582.295 262.653 580.632 259.901C578.969 257.149 576.737 254.785 574.086 252.965C571.435 251.146 568.426 249.914 565.26 249.352C562.094 248.79 558.845 248.911 555.729 249.706C552.614 250.502 549.705 251.954 547.196 253.965C544.688 255.976 542.638 258.5 541.184 261.368C539.73 264.236 538.905 267.381 538.765 270.593L529.659 270.197Z"
      mask="url(#path-30-inside-24_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-31-inside-25_1843_42025">
      <path
        d="M715.711 316.788C714.699 312.334 712.898 308.097 710.392 304.278L701.909 309.844C703.75 312.65 705.074 315.763 705.817 319.035L715.711 316.788Z"
      />
    </mask>
    <path
      d="M715.711 316.788C714.699 312.334 712.898 308.097 710.392 304.278L701.909 309.844C703.75 312.65 705.074 315.763 705.817 319.035L715.711 316.788Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-31-inside-25_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-32-inside-26_1843_42025">
      <path
        d="M718.328 292.166C713.927 286.698 708.107 282.547 701.505 280.167C694.903 277.786 687.773 277.269 680.896 278.671L684.715 297.407C688.154 296.706 691.719 296.965 695.02 298.155C698.321 299.345 701.231 301.421 703.431 304.154L718.328 292.166Z"
      />
    </mask>
    <path
      d="M718.328 292.166C713.927 286.698 708.107 282.547 701.505 280.167C694.903 277.786 687.773 277.269 680.896 278.671L684.715 297.407C688.154 296.706 691.719 296.965 695.02 298.155C698.321 299.345 701.231 301.421 703.431 304.154L718.328 292.166Z"
      mask="url(#path-32-inside-26_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-33-inside-27_1843_42025">
      <path
        d="M677.647 285.253C672.741 286.381 668.107 288.465 664.009 291.388L675.111 306.956C677.16 305.495 679.477 304.452 681.93 303.889L677.647 285.253Z"
      />
    </mask>
    <path
      d="M677.647 285.253C672.741 286.381 668.107 288.465 664.009 291.388L675.111 306.956C677.16 305.495 679.477 304.452 681.93 303.889L677.647 285.253Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-33-inside-27_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-34-inside-28_1843_42025">
      <path
        d="M722.064 335.834C719.559 342.583 715.199 348.488 709.487 352.869C703.775 357.25 696.942 359.93 689.774 360.601L687.993 341.563C691.577 341.227 694.994 339.887 697.85 337.697C700.706 335.506 702.886 332.554 704.139 329.179L722.064 335.834Z"
      />
    </mask>
    <path
      d="M722.064 335.834C719.559 342.583 715.199 348.488 709.487 352.869C703.775 357.25 696.942 359.93 689.774 360.601L687.993 341.563C691.577 341.227 694.994 339.887 697.85 337.697C700.706 335.506 702.886 332.554 704.139 329.179L722.064 335.834Z"
      mask="url(#path-34-inside-28_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <path
      d="M734.829 315.413C736.043 322.914 735.379 330.599 732.895 337.78L711.706 330.278C712.913 326.705 713.243 322.894 712.666 319.167L734.829 315.413Z"
      fill="var(--zero-background-svg)"
      stroke="var(--zero-background-svg)"
      stroke-width="0.669328"
    />
    <mask fill="white" id="path-36-inside-29_1843_42025">
      <path
        d="M688.388 353.404C686.266 353.565 684.133 353.514 682.021 353.252L683.441 341.813C684.793 341.981 686.158 342.014 687.516 341.911L688.388 353.404Z"
      />
    </mask>
    <path
      d="M688.388 353.404C686.266 353.565 684.133 353.514 682.021 353.252L683.441 341.813C684.793 341.981 686.158 342.014 687.516 341.911L688.388 353.404Z"
      mask="url(#path-36-inside-29_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-37-inside-30_1843_42025">
      <path
        d="M681.644 353.203C680.488 353.045 679.341 352.824 678.209 352.542L681.002 341.359C681.726 341.54 682.46 341.681 683.2 341.782L681.644 353.203Z"
      />
    </mask>
    <path
      d="M681.644 353.203C680.488 353.045 679.341 352.824 678.209 352.542L681.002 341.359C681.726 341.54 682.46 341.681 683.2 341.782L681.644 353.203Z"
      mask="url(#path-37-inside-30_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-38-inside-31_1843_42025">
      <path
        d="M675 359.84C673.607 359.471 672.241 359.008 670.91 358.455L675.336 347.812C676.187 348.166 677.062 348.463 677.953 348.699L675 359.84Z"
      />
    </mask>
    <path
      d="M675 359.84C673.607 359.471 672.241 359.008 670.91 358.455L675.336 347.812C676.187 348.166 677.062 348.463 677.953 348.699L675 359.84Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-38-inside-31_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask
      fill="black"
      height="14.9883"
      id="path-39-outside-32_1843_42025"
      maskUnits="userSpaceOnUse"
      width="14.9883"
      x="664.877"
      y="334.684"
    >
      <rect
        fill="white"
        height="14.9883"
        width="14.9883"
        x="664.877"
        y="334.684"
      />
      <path
        d="M674.647 347.676C671.686 346.304 668.993 344.418 666.691 342.105L671.734 337.088C673.442 338.804 675.44 340.204 677.637 341.221L674.647 347.676Z"
      />
    </mask>
    <path
      d="M674.647 347.676C671.686 346.304 668.993 344.418 666.691 342.105L671.734 337.088C673.442 338.804 675.44 340.204 677.637 341.221L674.647 347.676Z"
      mask="url(#path-39-outside-32_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-linejoin="round"
      stroke-width="1.33866"
    />
    <mask
      fill="black"
      height="14.8213"
      id="path-40-outside-33_1843_42025"
      maskUnits="userSpaceOnUse"
      width="14.0024"
      x="659.764"
      y="328.617"
    >
      <rect
        fill="white"
        height="14.8213"
        width="14.0024"
        x="659.764"
        y="328.617"
      />
      <path
        d="M666.419 341.828C664.131 339.462 662.285 336.706 660.968 333.69L667.487 330.844C668.464 333.082 669.834 335.127 671.532 336.882L666.419 341.828Z"
      />
    </mask>
    <path
      d="M666.419 341.828C664.131 339.462 662.285 336.706 660.968 333.69L667.487 330.844C668.464 333.082 669.834 335.127 671.532 336.882L666.419 341.828Z"
      fill="var(--zero-background-svg)"
    />
    <path
      d="M666.419 341.828C664.131 339.462 662.285 336.706 660.968 333.69L667.487 330.844C668.464 333.082 669.834 335.127 671.532 336.882L666.419 341.828Z"
      mask="url(#path-40-outside-33_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-linejoin="round"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-41-inside-34_1843_42025">
      <path
        d="M704.207 319.477C704.944 323.833 704.071 328.31 701.748 332.077C699.425 335.844 695.811 338.645 691.576 339.959C687.341 341.274 682.774 341.014 678.72 339.227C674.667 337.44 671.404 334.247 669.537 330.242L674.351 328.008C675.678 330.853 677.995 333.12 680.873 334.389C683.751 335.658 686.995 335.843 690.003 334.909C693.01 333.975 695.577 331.986 697.226 329.311C698.876 326.636 699.496 323.457 698.972 320.363L704.207 319.477Z"
      />
    </mask>
    <path
      d="M704.207 319.477C704.944 323.833 704.071 328.31 701.748 332.077C699.425 335.844 695.811 338.645 691.576 339.959C687.341 341.274 682.774 341.014 678.72 339.227C674.667 337.44 671.404 334.247 669.537 330.242L674.351 328.008C675.678 330.853 677.995 333.12 680.873 334.389C683.751 335.658 686.995 335.843 690.003 334.909C693.01 333.975 695.577 331.986 697.226 329.311C698.876 326.636 699.496 323.457 698.972 320.363L704.207 319.477Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-41-inside-34_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-42-inside-35_1843_42025">
      <path
        d="M646.923 337.427C643.132 329.448 642.22 320.403 644.343 311.828C646.465 303.253 651.49 295.677 658.565 290.387L670.015 305.701C666.478 308.346 663.965 312.134 662.904 316.421C661.843 320.709 662.299 325.231 664.194 329.221L646.923 337.427Z"
      />
    </mask>
    <path
      d="M646.923 337.427C643.132 329.448 642.22 320.403 644.343 311.828C646.465 303.253 651.49 295.677 658.565 290.387L670.015 305.701C666.478 308.346 663.965 312.134 662.904 316.421C661.843 320.709 662.299 325.231 664.194 329.221L646.923 337.427Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-42-inside-35_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-43-inside-36_1843_42025">
      <path
        d="M667.417 331.09C666.195 328.423 665.557 325.525 665.545 322.591C665.533 319.657 666.148 316.755 667.349 314.078C668.55 311.401 670.309 309.011 672.509 307.07C674.708 305.128 677.297 303.678 680.102 302.818C682.907 301.957 685.864 301.706 688.774 302.081C691.684 302.456 694.48 303.449 696.975 304.992C699.471 306.536 701.607 308.594 703.242 311.031C704.878 313.467 705.973 316.224 706.456 319.118L700.612 320.093C700.268 318.032 699.488 316.068 698.323 314.332C697.158 312.597 695.636 311.131 693.859 310.031C692.081 308.932 690.089 308.225 688.016 307.958C685.943 307.69 683.837 307.869 681.839 308.482C679.841 309.095 677.997 310.127 676.43 311.511C674.863 312.894 673.61 314.596 672.755 316.503C671.899 318.41 671.461 320.478 671.469 322.568C671.478 324.658 671.933 326.722 672.803 328.622L667.417 331.09Z"
      />
    </mask>
    <path
      d="M667.417 331.09C666.195 328.423 665.557 325.525 665.545 322.591C665.533 319.657 666.148 316.755 667.349 314.078C668.55 311.401 670.309 309.011 672.509 307.07C674.708 305.128 677.297 303.678 680.102 302.818C682.907 301.957 685.864 301.706 688.774 302.081C691.684 302.456 694.48 303.449 696.975 304.992C699.471 306.536 701.607 308.594 703.242 311.031C704.878 313.467 705.973 316.224 706.456 319.118L700.612 320.093C700.268 318.032 699.488 316.068 698.323 314.332C697.158 312.597 695.636 311.131 693.859 310.031C692.081 308.932 690.089 308.225 688.016 307.958C685.943 307.69 683.837 307.869 681.839 308.482C679.841 309.095 677.997 310.127 676.43 311.511C674.863 312.894 673.61 314.596 672.755 316.503C671.899 318.41 671.461 320.478 671.469 322.568C671.478 324.658 671.933 326.722 672.803 328.622L667.417 331.09Z"
      mask="url(#path-43-inside-36_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-44-inside-37_1843_42025">
      <path
        d="M846.049 343.224C837.162 351.372 826.155 356.847 814.295 359.018C802.434 361.188 790.202 359.967 779.005 355.495L790.884 325.754C796.482 327.99 802.599 328.601 808.529 327.515C814.459 326.43 819.962 323.693 824.406 319.618L846.049 343.224Z"
      />
    </mask>
    <path
      d="M846.049 343.224C837.162 351.372 826.155 356.847 814.295 359.018C802.434 361.188 790.202 359.967 779.005 355.495L790.884 325.754C796.482 327.99 802.599 328.601 808.529 327.515C814.459 326.43 819.962 323.693 824.406 319.618L846.049 343.224Z"
      mask="url(#path-44-inside-37_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-45-inside-38_1843_42025">
      <path
        d="M782.432 343.707C779.146 342.327 776.006 340.623 773.058 338.619L783.907 322.652C785.794 323.934 787.804 325.025 789.907 325.908L782.432 343.707Z"
      />
    </mask>
    <path
      d="M782.432 343.707C779.146 342.327 776.006 340.623 773.058 338.619L783.907 322.652C785.794 323.934 787.804 325.025 789.907 325.908L782.432 343.707Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-45-inside-38_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-46-inside-39_1843_42025">
      <path
        d="M772.534 338.259C770.93 337.141 769.389 335.937 767.917 334.651L780.617 320.112C781.559 320.935 782.545 321.706 783.572 322.421L772.534 338.259Z"
      />
    </mask>
    <path
      d="M772.534 338.259C770.93 337.141 769.389 335.937 767.917 334.651L780.617 320.112C781.559 320.935 782.545 321.706 783.572 322.421L772.534 338.259Z"
      mask="url(#path-46-inside-39_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-47-inside-40_1843_42025">
      <path
        d="M757.561 343.081C755.766 341.467 754.082 339.733 752.521 337.893L767.243 325.406C768.243 326.584 769.32 327.693 770.469 328.726L757.561 343.081Z"
      />
    </mask>
    <path
      d="M757.561 343.081C755.766 341.467 754.082 339.733 752.521 337.893L767.243 325.406C768.243 326.584 769.32 327.693 770.469 328.726L757.561 343.081Z"
      mask="url(#path-47-inside-40_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask
      fill="black"
      height="25.3901"
      id="path-48-outside-41_1843_42025"
      maskUnits="userSpaceOnUse"
      width="26.043"
      x="753.992"
      y="302.506"
    >
      <rect
        fill="white"
        height="25.3901"
        width="26.043"
        x="753.992"
        y="302.506"
      />
      <path
        d="M766.321 324.677C762.954 320.372 760.379 315.504 758.714 310.299L770.061 306.669C771.296 310.532 773.207 314.144 775.705 317.338L766.321 324.677Z"
      />
    </mask>
    <path
      d="M766.321 324.677C762.954 320.372 760.379 315.504 758.714 310.299L770.061 306.669C771.296 310.532 773.207 314.144 775.705 317.338L766.321 324.677Z"
      mask="url(#path-48-outside-41_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-linejoin="round"
      stroke-width="1.33866"
    />
    <mask
      fill="black"
      height="24.7886"
      id="path-49-outside-42_1843_42025"
      maskUnits="userSpaceOnUse"
      width="24.1357"
      x="750.926"
      y="288.956"
    >
      <rect
        fill="white"
        height="24.7886"
        width="24.1357"
        x="750.926"
        y="288.956"
      />
      <path
        d="M758.52 309.678C756.915 304.405 756.267 298.887 756.606 293.385L768.497 294.119C768.245 298.201 768.726 302.296 769.917 306.209L758.52 309.678Z"
      />
    </mask>
    <path
      d="M758.52 309.678C756.915 304.405 756.267 298.887 756.606 293.385L768.497 294.119C768.245 298.201 768.726 302.296 769.917 306.209L758.52 309.678Z"
      mask="url(#path-49-outside-42_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-linejoin="round"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-50-inside-43_1843_42025">
      <path
        d="M831.913 305.205C829.688 312.263 824.968 318.27 818.629 322.112C812.291 325.955 804.765 327.371 797.448 326.098C790.131 324.825 783.52 320.95 778.842 315.192C774.164 309.433 771.737 302.183 772.011 294.786L780.894 295.132C780.699 300.385 782.423 305.534 785.745 309.623C789.067 313.712 793.762 316.464 798.958 317.368C804.154 318.272 809.499 317.266 814 314.538C818.501 311.809 821.853 307.543 823.433 302.531L831.913 305.205Z"
      />
    </mask>
    <path
      d="M831.913 305.205C829.688 312.263 824.968 318.27 818.629 322.112C812.291 325.955 804.765 327.371 797.448 326.098C790.131 324.825 783.52 320.95 778.842 315.192C774.164 309.433 771.737 302.183 772.011 294.786L780.894 295.132C780.699 300.385 782.423 305.534 785.745 309.623C789.067 313.712 793.762 316.464 798.958 317.368C804.154 318.272 809.499 317.266 814 314.538C818.501 311.809 821.853 307.543 823.433 302.531L831.913 305.205Z"
      mask="url(#path-50-inside-43_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-51-inside-44_1843_42025">
      <path
        d="M732.817 288.234C733.257 273.446 738.803 259.265 748.512 248.102C758.222 236.939 771.497 229.481 786.082 226.996L791.461 258.567C784.169 259.81 777.531 263.538 772.676 269.12C767.821 274.701 765.048 281.792 764.829 289.186L732.817 288.234Z"
      />
    </mask>
    <path
      d="M732.817 288.234C733.257 273.446 738.803 259.265 748.512 248.102C758.222 236.939 771.497 229.481 786.082 226.996L791.461 258.567C784.169 259.81 777.531 263.538 772.676 269.12C767.821 274.701 765.048 281.792 764.829 289.186L732.817 288.234Z"
      mask="url(#path-51-inside-44_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-52-inside-45_1843_42025">
      <path
        d="M768.206 294.434C768.42 289.524 769.68 284.717 771.902 280.334C774.124 275.952 777.257 272.094 781.091 269.02C784.926 265.946 789.372 263.727 794.133 262.511C798.895 261.295 803.861 261.111 808.699 261.97C813.538 262.829 818.137 264.711 822.188 267.492C826.24 270.273 829.651 273.887 832.193 278.093C834.734 282.298 836.348 286.999 836.926 291.879C837.504 296.759 837.032 301.706 835.543 306.389L826.087 303.381C827.147 300.046 827.483 296.522 827.072 293.045C826.66 289.569 825.511 286.221 823.7 283.225C821.889 280.229 819.46 277.655 816.573 275.674C813.687 273.693 810.411 272.352 806.965 271.74C803.518 271.128 799.98 271.26 796.589 272.126C793.197 272.992 790.03 274.573 787.298 276.762C784.567 278.952 782.336 281.7 780.752 284.822C779.169 287.944 778.272 291.368 778.119 294.866L768.206 294.434Z"
      />
    </mask>
    <path
      d="M768.206 294.434C768.42 289.524 769.68 284.717 771.902 280.334C774.124 275.952 777.257 272.094 781.091 269.02C784.926 265.946 789.372 263.727 794.133 262.511C798.895 261.295 803.861 261.111 808.699 261.97C813.538 262.829 818.137 264.711 822.188 267.492C826.24 270.273 829.651 273.887 832.193 278.093C834.734 282.298 836.348 286.999 836.926 291.879C837.504 296.759 837.032 301.706 835.543 306.389L826.087 303.381C827.147 300.046 827.483 296.522 827.072 293.045C826.66 289.569 825.511 286.221 823.7 283.225C821.889 280.229 819.46 277.655 816.573 275.674C813.687 273.693 810.411 272.352 806.965 271.74C803.518 271.128 799.98 271.26 796.589 272.126C793.197 272.992 790.03 274.573 787.298 276.762C784.567 278.952 782.336 281.7 780.752 284.822C779.169 287.944 778.272 291.368 778.119 294.866L768.206 294.434Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-52-inside-45_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-53-inside-46_1843_42025">
      <path
        d="M734.085 365.443C731.621 366.436 729.354 367.862 727.392 369.655L731.374 374.012C732.815 372.695 734.48 371.647 736.291 370.917L734.085 365.443Z"
      />
    </mask>
    <path
      d="M734.085 365.443C731.621 366.436 729.354 367.862 727.392 369.655L731.374 374.012C732.815 372.695 734.48 371.647 736.291 370.917L734.085 365.443Z"
      mask="url(#path-53-inside-46_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-54-inside-47_1843_42025">
      <path
        d="M719.701 366.217C716.968 369.25 715.122 372.977 714.365 376.989C713.609 381.001 713.971 385.144 715.413 388.964L725.82 385.037C725.1 383.127 724.918 381.056 725.297 379.05C725.675 377.044 726.598 375.18 727.964 373.664L719.701 366.217Z"
      />
    </mask>
    <path
      d="M719.701 366.217C716.968 369.25 715.122 372.977 714.365 376.989C713.609 381.001 713.971 385.144 715.413 388.964L725.82 385.037C725.1 383.127 724.918 381.056 725.297 379.05C725.675 377.044 726.598 375.18 727.964 373.664L719.701 366.217Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-54-inside-47_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-55-inside-48_1843_42025">
      <path
        d="M719.493 390.221C720.594 392.934 722.22 395.403 724.278 397.487L732.192 389.67C731.163 388.628 730.35 387.394 729.8 386.037L719.493 390.221Z"
      />
    </mask>
    <path
      d="M719.493 390.221C720.594 392.934 722.22 395.403 724.278 397.487L732.192 389.67C731.163 388.628 730.35 387.394 729.8 386.037L719.493 390.221Z"
      mask="url(#path-55-inside-48_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-56-inside-49_1843_42025">
      <path
        d="M744.435 360.031C748.543 360.846 752.337 362.803 755.382 365.679C758.427 368.554 760.598 372.231 761.646 376.286L750.877 379.07C750.352 377.042 749.267 375.204 747.744 373.766C746.222 372.329 744.325 371.35 742.271 370.942L744.435 360.031Z"
      />
    </mask>
    <path
      d="M744.435 360.031C748.543 360.846 752.337 362.803 755.382 365.679C758.427 368.554 760.598 372.231 761.646 376.286L750.877 379.07C750.352 377.042 749.267 375.204 747.744 373.766C746.222 372.329 744.325 371.35 742.271 370.942L744.435 360.031Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-56-inside-49_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <path
      d="M731.704 354.678C735.794 353.346 740.146 353.026 744.388 353.745L742.089 366.335C739.992 366.004 737.846 366.162 735.82 366.796L731.704 354.678Z"
      fill="var(--zero-background-svg)"
      stroke="var(--zero-background-svg)"
      stroke-width="0.669328"
    />
    <mask fill="white" id="path-58-inside-50_1843_42025">
      <path
        d="M757.643 377.748C757.931 378.952 758.099 380.182 758.144 381.419L751.443 381.661C751.415 380.869 751.307 380.082 751.122 379.312L757.643 377.748Z"
      />
    </mask>
    <path
      d="M757.643 377.748C757.931 378.952 758.099 380.182 758.144 381.419L751.443 381.661C751.415 380.869 751.307 380.082 751.122 379.312L757.643 377.748Z"
      mask="url(#path-58-inside-50_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-59-inside-51_1843_42025">
      <path
        d="M758.151 381.64C758.167 382.318 758.147 382.997 758.089 383.674L751.408 383.104C751.445 382.671 751.458 382.237 751.448 381.802L758.151 381.64Z"
      />
    </mask>
    <path
      d="M758.151 381.64C758.167 382.318 758.147 382.997 758.089 383.674L751.408 383.104C751.445 382.671 751.458 382.237 751.448 381.802L758.151 381.64Z"
      mask="url(#path-59-inside-51_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-60-inside-52_1843_42025">
      <path
        d="M762.576 384.842C762.493 385.676 762.353 386.504 762.159 387.319L755.637 385.762C755.761 385.24 755.851 384.71 755.904 384.177L762.576 384.842Z"
      />
    </mask>
    <path
      d="M762.576 384.842C762.493 385.676 762.353 386.504 762.159 387.319L755.637 385.762C755.761 385.24 755.851 384.71 755.904 384.177L762.576 384.842Z"
      mask="url(#path-60-inside-52_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask
      fill="black"
      height="10.148"
      id="path-61-outside-53_1843_42025"
      maskUnits="userSpaceOnUse"
      width="10.148"
      x="747.746"
      y="382.982"
    >
      <rect
        fill="white"
        height="10.148"
        width="10.148"
        x="747.746"
        y="382.982"
      />
      <path
        d="M755.624 386.17C755.11 387.998 754.276 389.719 753.161 391.255L749.813 388.823C750.641 387.683 751.259 386.406 751.641 385.05L755.624 386.17Z"
      />
    </mask>
    <path
      d="M755.624 386.17C755.11 387.998 754.276 389.719 753.161 391.255L749.813 388.823C750.641 387.683 751.259 386.406 751.641 385.05L755.624 386.17Z"
      mask="url(#path-61-outside-53_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-linejoin="round"
      stroke-width="1.33866"
    />
    <mask
      fill="black"
      height="10.148"
      id="path-62-outside-54_1843_42025"
      maskUnits="userSpaceOnUse"
      width="10.148"
      x="744.923"
      y="387.109"
    >
      <rect
        fill="white"
        height="10.148"
        width="10.148"
        x="744.923"
        y="387.109"
      />
      <path
        d="M753.027 391.437C751.88 392.97 750.467 394.285 748.857 395.32L746.619 391.839C747.814 391.071 748.862 390.095 749.713 388.958L753.027 391.437Z"
      />
    </mask>
    <path
      d="M753.027 391.437C751.88 392.97 750.467 394.285 748.857 395.32L746.619 391.839C747.814 391.071 748.862 390.095 749.713 388.958L753.027 391.437Z"
      mask="url(#path-62-outside-54_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-linejoin="round"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-63-inside-55_1843_42025">
      <path
        d="M736.693 371.802C739.127 370.975 741.779 371.062 744.157 372.048C746.535 373.034 748.478 374.85 749.625 377.161C750.772 379.472 751.045 382.119 750.394 384.612C749.742 387.106 748.21 389.275 746.083 390.718L744.354 388.159C745.865 387.135 746.953 385.594 747.416 383.823C747.879 382.053 747.685 380.173 746.87 378.532C746.056 376.891 744.676 375.601 742.987 374.901C741.298 374.201 739.414 374.139 737.686 374.726L736.693 371.802Z"
      />
    </mask>
    <path
      d="M736.693 371.802C739.127 370.975 741.779 371.062 744.157 372.048C746.535 373.034 748.478 374.85 749.625 377.161C750.772 379.472 751.045 382.119 750.394 384.612C749.742 387.106 748.21 389.275 746.083 390.718L744.354 388.159C745.865 387.135 746.953 385.594 747.416 383.823C747.879 382.053 747.685 380.173 746.87 378.532C746.056 376.891 744.676 375.601 742.987 374.901C741.298 374.201 739.414 374.139 737.686 374.726L736.693 371.802Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-63-inside-55_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-64-inside-56_1843_42025">
      <path
        d="M752.301 403.041C748.069 405.956 742.959 407.317 737.838 406.891C732.716 406.466 727.9 404.28 724.208 400.706L731.944 392.713C733.79 394.5 736.198 395.593 738.759 395.806C741.319 396.018 743.875 395.338 745.991 393.88L752.301 403.041Z"
      />
    </mask>
    <path
      d="M752.301 403.041C748.069 405.956 742.959 407.317 737.838 406.891C732.716 406.466 727.9 404.28 724.208 400.706L731.944 392.713C733.79 394.5 736.198 395.593 738.759 395.806C741.319 396.018 743.875 395.338 745.991 393.88L752.301 403.041Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-64-inside-56_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-65-inside-57_1843_42025">
      <path
        d="M746.766 391.856C745.347 392.805 743.742 393.44 742.058 393.718C740.374 393.996 738.65 393.911 737.001 393.469C735.353 393.027 733.818 392.238 732.499 391.154C731.18 390.071 730.108 388.718 729.354 387.186C728.601 385.655 728.183 383.98 728.129 382.274C728.076 380.568 728.387 378.87 729.043 377.294C729.698 375.718 730.683 374.301 731.931 373.136C733.179 371.972 734.661 371.087 736.278 370.542L737.379 373.808C736.227 374.197 735.171 374.827 734.282 375.656C733.393 376.486 732.692 377.495 732.225 378.618C731.758 379.741 731.536 380.95 731.574 382.165C731.613 383.381 731.91 384.574 732.447 385.665C732.984 386.755 733.747 387.719 734.687 388.491C735.626 389.263 736.72 389.825 737.894 390.14C739.069 390.455 740.297 390.515 741.496 390.317C742.696 390.119 743.839 389.667 744.85 388.991L746.766 391.856Z"
      />
    </mask>
    <path
      d="M746.766 391.856C745.347 392.805 743.742 393.44 742.058 393.718C740.374 393.996 738.65 393.911 737.001 393.469C735.353 393.027 733.818 392.238 732.499 391.154C731.18 390.071 730.108 388.718 729.354 387.186C728.601 385.655 728.183 383.98 728.129 382.274C728.076 380.568 728.387 378.87 729.043 377.294C729.698 375.718 730.683 374.301 731.931 373.136C733.179 371.972 734.661 371.087 736.278 370.542L737.379 373.808C736.227 374.197 735.171 374.827 734.282 375.656C733.393 376.486 732.692 377.495 732.225 378.618C731.758 379.741 731.536 380.95 731.574 382.165C731.613 383.381 731.91 384.574 732.447 385.665C732.984 386.755 733.747 387.719 734.687 388.491C735.626 389.263 736.72 389.825 737.894 390.14C739.069 390.455 740.297 390.515 741.496 390.317C742.696 390.119 743.839 389.667 744.85 388.991L746.766 391.856Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-65-inside-57_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-66-inside-58_1843_42025">
      <path
        d="M527.131 163.138C524.475 163.051 521.826 163.44 519.307 164.287L521.189 169.882C523.04 169.259 524.987 168.973 526.938 169.037L527.131 163.138Z"
      />
    </mask>
    <path
      d="M527.131 163.138C524.475 163.051 521.826 163.44 519.307 164.287L521.189 169.882C523.04 169.259 524.987 168.973 526.938 169.037L527.131 163.138Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-66-inside-58_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-67-inside-59_1843_42025">
      <path
        d="M513.659 158.036C509.934 159.706 506.74 162.37 504.428 165.735C502.115 169.1 500.773 173.036 500.549 177.112L511.656 177.724C511.768 175.686 512.439 173.718 513.595 172.035C514.751 170.353 516.348 169.021 518.211 168.186L513.659 158.036Z"
      />
    </mask>
    <path
      d="M513.659 158.036C509.934 159.706 506.74 162.37 504.428 165.735C502.115 169.1 500.773 173.036 500.549 177.112L511.656 177.724C511.768 175.686 512.439 173.718 513.595 172.035C514.751 170.353 516.348 169.021 518.211 168.186L513.659 158.036Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-67-inside-59_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-68-inside-60_1843_42025">
      <path
        d="M503.774 179.911C503.686 182.838 504.176 185.753 505.217 188.49L515.614 184.537C515.094 183.168 514.849 181.71 514.893 180.247L503.774 179.911Z"
      />
    </mask>
    <path
      d="M503.774 179.911C503.686 182.838 504.176 185.753 505.217 188.49L515.614 184.537C515.094 183.168 514.849 181.71 514.893 180.247L503.774 179.911Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-68-inside-60_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-69-inside-61_1843_42025">
      <path
        d="M538.785 162.368C542.214 164.772 544.895 168.096 546.519 171.956C548.142 175.817 548.644 180.057 547.965 184.19L536.988 182.386C537.328 180.32 537.077 178.2 536.265 176.27C535.453 174.339 534.113 172.678 532.398 171.475L538.785 162.368Z"
      />
    </mask>
    <path
      d="M538.785 162.368C542.214 164.772 544.895 168.096 546.519 171.956C548.142 175.817 548.644 180.057 547.965 184.19L536.988 182.386C537.328 180.32 537.077 178.2 536.265 176.27C535.453 174.339 534.113 172.678 532.398 171.475L538.785 162.368Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-69-inside-61_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <path
      d="M529.302 152.328C533.582 152.762 537.693 154.227 541.282 156.598L534.094 167.187C532.309 166.037 530.282 165.315 528.173 165.076L529.302 152.328Z"
      fill="var(--zero-background-svg)"
      stroke="var(--zero-background-svg)"
      stroke-width="0.669328"
    />
    <mask fill="white" id="path-71-inside-62_1843_42025">
      <path
        d="M543.71 183.91C543.488 185.128 543.145 186.32 542.686 187.47L536.458 184.985C536.752 184.249 536.972 183.486 537.114 182.706L543.71 183.91Z"
      />
    </mask>
    <path
      d="M543.71 183.91C543.488 185.128 543.145 186.32 542.686 187.47L536.458 184.985C536.752 184.249 536.972 183.486 537.114 182.706L543.71 183.91Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-71-inside-62_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-72-inside-63_1843_42025">
      <path
        d="M542.603 187.675C542.344 188.302 542.051 188.915 541.725 189.511L535.843 186.291C536.052 185.91 536.239 185.518 536.405 185.116L542.603 187.675Z"
      />
    </mask>
    <path
      d="M542.603 187.675C542.344 188.302 542.051 188.915 541.725 189.511L535.843 186.291C536.052 185.91 536.239 185.518 536.405 185.116L542.603 187.675Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-72-inside-63_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-73-inside-64_1843_42025">
      <path
        d="M545.358 192.392C544.945 193.121 544.483 193.822 543.976 194.489L538.639 190.43C538.963 190.003 539.259 189.555 539.523 189.088L545.358 192.392Z"
      />
    </mask>
    <path
      d="M545.358 192.392C544.945 193.121 544.483 193.822 543.976 194.489L538.639 190.43C538.963 190.003 539.259 189.555 539.523 189.088L545.358 192.392Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-73-inside-64_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask
      fill="black"
      height="8.67455"
      id="path-74-outside-65_1843_42025"
      maskUnits="userSpaceOnUse"
      width="8.67455"
      x="530.797"
      y="187.054"
    >
      <rect
        fill="white"
        height="8.67455"
        width="8.67455"
        x="530.797"
        y="187.054"
      />
      <path
        d="M538.461 190.798C537.253 192.262 535.795 193.5 534.154 194.455L532.074 190.877C533.291 190.169 534.373 189.251 535.27 188.165L538.461 190.798Z"
      />
    </mask>
    <path
      d="M538.461 190.798C537.253 192.262 535.795 193.5 534.154 194.455L532.074 190.877C533.291 190.169 534.373 189.251 535.27 188.165L538.461 190.798Z"
      fill="var(--zero-background-svg)"
    />
    <path
      d="M538.461 190.798C537.253 192.262 535.795 193.5 534.154 194.455L532.074 190.877C533.291 190.169 534.373 189.251 535.27 188.165L538.461 190.798Z"
      mask="url(#path-74-outside-65_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-linejoin="round"
      stroke-width="1.33866"
    />
    <mask
      fill="black"
      height="8.67455"
      id="path-75-outside-66_1843_42025"
      maskUnits="userSpaceOnUse"
      width="8.67455"
      x="526.548"
      y="189.689"
    >
      <rect
        fill="white"
        height="8.67455"
        width="8.67455"
        x="526.548"
        y="189.689"
      />
      <path
        d="M533.958 194.567C532.289 195.506 530.466 196.139 528.575 196.435L527.934 192.347C529.337 192.127 530.69 191.658 531.928 190.961L533.958 194.567Z"
      />
    </mask>
    <path
      d="M533.958 194.567C532.289 195.506 530.466 196.139 528.575 196.435L527.934 192.347C529.337 192.127 530.69 191.658 531.928 190.961L533.958 194.567Z"
      fill="var(--zero-background-svg)"
    />
    <path
      d="M533.958 194.567C532.289 195.506 530.466 196.139 528.575 196.435L527.934 192.347C529.337 192.127 530.69 191.658 531.928 190.961L533.958 194.567Z"
      mask="url(#path-75-outside-66_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-linejoin="round"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-76-inside-67_1843_42025">
      <path
        d="M526.949 170.008C529.509 170.234 531.9 171.386 533.678 173.248C535.455 175.11 536.499 177.557 536.615 180.134C536.731 182.711 535.911 185.243 534.308 187.261C532.705 189.279 530.427 190.645 527.898 191.105L527.35 188.066C529.147 187.739 530.764 186.769 531.902 185.337C533.041 183.904 533.623 182.105 533.541 180.275C533.458 178.445 532.717 176.708 531.455 175.385C530.192 174.063 528.495 173.245 526.676 173.084L526.949 170.008Z"
      />
    </mask>
    <path
      d="M526.949 170.008C529.509 170.234 531.9 171.386 533.678 173.248C535.455 175.11 536.499 177.557 536.615 180.134C536.731 182.711 535.911 185.243 534.308 187.261C532.705 189.279 530.427 190.645 527.898 191.105L527.35 188.066C529.147 187.739 530.764 186.769 531.902 185.337C533.041 183.904 533.623 182.105 533.541 180.275C533.458 178.445 532.717 176.708 531.455 175.385C530.192 174.063 528.495 173.245 526.676 173.084L526.949 170.008Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-76-inside-67_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-77-inside-68_1843_42025">
      <path
        d="M528.608 204.89C523.559 205.847 518.334 205.027 513.821 202.57C509.308 200.112 505.785 196.167 503.851 191.406L514.156 187.219C515.123 189.6 516.885 191.572 519.141 192.801C521.398 194.03 524.01 194.44 526.535 193.961L528.608 204.89Z"
      />
    </mask>
    <path
      d="M528.608 204.89C523.559 205.847 518.334 205.027 513.821 202.57C509.308 200.112 505.785 196.167 503.851 191.406L514.156 187.219C515.123 189.6 516.885 191.572 519.141 192.801C521.398 194.03 524.01 194.44 526.535 193.961L528.608 204.89Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-77-inside-68_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-78-inside-69_1843_42025">
      <path
        d="M528.064 192.422C526.382 192.717 524.658 192.65 523.005 192.224C521.352 191.798 519.809 191.024 518.479 189.954C517.15 188.883 516.064 187.541 515.296 186.017C514.527 184.493 514.093 182.823 514.022 181.117C513.951 179.412 514.245 177.711 514.885 176.129C515.525 174.547 516.496 173.119 517.732 171.942C518.968 170.765 520.442 169.866 522.054 169.305C523.666 168.744 525.379 168.534 527.079 168.688L526.767 172.121C525.556 172.011 524.335 172.16 523.187 172.56C522.039 172.96 520.989 173.6 520.109 174.439C519.228 175.277 518.537 176.294 518.081 177.421C517.625 178.548 517.415 179.76 517.466 180.975C517.516 182.189 517.825 183.379 518.373 184.465C518.921 185.551 519.694 186.507 520.641 187.269C521.588 188.032 522.687 188.583 523.864 188.886C525.042 189.189 526.27 189.238 527.468 189.028L528.064 192.422Z"
      />
    </mask>
    <path
      d="M528.064 192.422C526.382 192.717 524.658 192.65 523.005 192.224C521.352 191.798 519.809 191.024 518.479 189.954C517.15 188.883 516.064 187.541 515.296 186.017C514.527 184.493 514.093 182.823 514.022 181.117C513.951 179.412 514.245 177.711 514.885 176.129C515.525 174.547 516.496 173.119 517.732 171.942C518.968 170.765 520.442 169.866 522.054 169.305C523.666 168.744 525.379 168.534 527.079 168.688L526.767 172.121C525.556 172.011 524.335 172.16 523.187 172.56C522.039 172.96 520.989 173.6 520.109 174.439C519.228 175.277 518.537 176.294 518.081 177.421C517.625 178.548 517.415 179.76 517.466 180.975C517.516 182.189 517.825 183.379 518.373 184.465C518.921 185.551 519.694 186.507 520.641 187.269C521.588 188.032 522.687 188.583 523.864 188.886C525.042 189.189 526.27 189.238 527.468 189.028L528.064 192.422Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-78-inside-69_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-79-inside-70_1843_42025">
      <path
        d="M271.531 446.641C286.552 454.985 302.947 460.571 319.937 463.133L325.63 425.392C313.146 423.509 301.102 419.405 290.066 413.275L271.531 446.641Z"
      />
    </mask>
    <path
      d="M271.531 446.641C286.552 454.985 302.947 460.571 319.937 463.133L325.63 425.392C313.146 423.509 301.102 419.405 290.066 413.275L271.531 446.641Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-79-inside-70_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-80-inside-71_1843_42025">
      <path
        d="M333.965 515.772C360.331 517.163 386.571 511.272 409.812 498.743C433.053 486.214 452.398 467.532 465.728 444.742L403.636 408.423C396.971 419.818 387.298 429.159 375.678 435.423C364.058 441.688 350.938 444.633 337.755 443.938L333.965 515.772Z"
      />
    </mask>
    <path
      d="M333.965 515.772C360.331 517.163 386.571 511.272 409.812 498.743C433.053 486.214 452.398 467.532 465.728 444.742L403.636 408.423C396.971 419.818 387.298 429.159 375.678 435.423C364.058 441.688 350.938 444.633 337.755 443.938L333.965 515.772Z"
      mask="url(#path-80-inside-71_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-81-inside-72_1843_42025">
      <path
        d="M455.435 419.117C464.587 402.541 470.376 384.321 472.472 365.502L400.979 357.542C399.932 366.951 397.037 376.061 392.461 384.35L455.435 419.117Z"
      />
    </mask>
    <path
      d="M455.435 419.117C464.587 402.541 470.376 384.321 472.472 365.502L400.979 357.542C399.932 366.951 397.037 376.061 392.461 384.35L455.435 419.117Z"
      mask="url(#path-81-inside-72_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-82-inside-73_1843_42025">
      <path
        d="M202.209 416.651C189.583 392.691 183.977 365.654 186.035 338.65C188.093 311.646 197.731 285.771 213.841 264.001L271.664 306.791C263.609 317.676 258.79 330.614 257.761 344.116C256.732 357.618 259.535 371.136 265.848 383.116L202.209 416.651Z"
      />
    </mask>
    <path
      d="M202.209 416.651C189.583 392.691 183.977 365.654 186.035 338.65C188.093 311.646 197.731 285.771 213.841 264.001L271.664 306.791C263.609 317.676 258.79 330.614 257.761 344.116C256.732 357.618 259.535 371.136 265.848 383.116L202.209 416.651Z"
      mask="url(#path-82-inside-73_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <path
      d="M227.687 504.951C202.874 489.239 182.448 467.496 168.314 441.752L244.137 400.313C251.215 413.147 261.407 423.995 273.774 431.86L227.687 504.951Z"
      stroke="var(--zero-background-svg)"
      stroke-width="0.669328"
    />
    <mask fill="white" id="path-84-inside-74_1843_42025">
      <path
        d="M237.49 278.177C242.365 271.827 247.861 265.978 253.896 260.717L282.386 293.405C278.524 296.772 275.007 300.515 271.887 304.579L237.49 278.177Z"
      />
    </mask>
    <path
      d="M237.49 278.177C242.365 271.827 247.861 265.978 253.896 260.717L282.386 293.405C278.524 296.772 275.007 300.515 271.887 304.579L237.49 278.177Z"
      mask="url(#path-84-inside-74_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-85-inside-75_1843_42025">
      <path
        d="M254.978 259.785C258.322 256.94 261.818 254.28 265.453 251.816L289.783 287.708C287.457 289.285 285.219 290.987 283.079 292.808L254.978 259.785Z"
      />
    </mask>
    <path
      d="M254.978 259.785C258.322 256.94 261.818 254.28 265.453 251.816L289.783 287.708C287.457 289.285 285.219 290.987 283.079 292.808L254.978 259.785Z"
      mask="url(#path-85-inside-75_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-86-inside-76_1843_42025">
      <path
        d="M253.054 224.505C257.585 221.528 262.312 218.861 267.202 216.52L285.92 255.633C282.79 257.131 279.765 258.838 276.866 260.744L253.054 224.505Z"
      />
    </mask>
    <path
      d="M253.054 224.505C257.585 221.528 262.312 218.861 267.202 216.52L285.92 255.633C282.79 257.131 279.765 258.838 276.866 260.744L253.054 224.505Z"
      mask="url(#path-86-inside-76_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask
      fill="black"
      height="60.1538"
      id="path-87-outside-77_1843_42025"
      maskUnits="userSpaceOnUse"
      width="59.8412"
      x="278.621"
      y="230.304"
    >
      <rect
        fill="white"
        height="60.1538"
        width="59.8412"
        x="278.621"
        y="230.304"
      />
      <path
        d="M288.032 254.046C299.306 249.191 311.352 246.376 323.61 245.732L325.014 272.454C315.919 272.932 306.981 275.021 298.615 278.623L288.032 254.046Z"
      />
    </mask>
    <path
      d="M288.032 254.046C299.306 249.191 311.352 246.376 323.61 245.732L325.014 272.454C315.919 272.932 306.981 275.021 298.615 278.623L288.032 254.046Z"
      mask="url(#path-87-outside-77_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-linejoin="round"
      stroke-width="1.33866"
    />
    <mask
      fill="black"
      height="56.7702"
      id="path-88-outside-78_1843_42025"
      maskUnits="userSpaceOnUse"
      width="57.7078"
      x="311.281"
      y="230.638"
    >
      <rect
        fill="white"
        height="56.7702"
        width="57.7078"
        x="311.281"
        y="230.638"
      />
      <path
        d="M325.07 245.665C337.442 245.19 349.799 246.934 361.557 250.814L353.171 276.225C344.447 273.346 335.278 272.052 326.097 272.405L325.07 245.665Z"
      />
    </mask>
    <path
      d="M325.07 245.665C337.442 245.19 349.799 246.934 361.557 250.814L353.171 276.225C344.447 273.346 335.278 272.052 326.097 272.405L325.07 245.665Z"
      mask="url(#path-88-outside-78_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-linejoin="round"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-89-inside-79_1843_42025">
      <path
        d="M292.871 407.655C278.811 398.79 268.456 385.105 263.73 369.142C259.003 353.179 260.224 336.021 267.168 320.853C274.111 305.685 286.305 293.537 301.486 286.664C316.666 279.791 333.803 278.659 349.715 283.478L343.891 302.577C332.591 299.154 320.422 299.958 309.641 304.839C298.861 309.72 290.201 318.347 285.27 329.119C280.34 339.891 279.472 352.075 282.829 363.411C286.185 374.747 293.539 384.465 303.523 390.761L292.871 407.655Z"
      />
    </mask>
    <path
      d="M292.871 407.655C278.811 398.79 268.456 385.105 263.73 369.142C259.003 353.179 260.224 336.021 267.168 320.853C274.111 305.685 286.305 293.537 301.486 286.664C316.666 279.791 333.803 278.659 349.715 283.478L343.891 302.577C332.591 299.154 320.422 299.958 309.641 304.839C298.861 309.72 290.201 318.347 285.27 329.119C280.34 339.891 279.472 352.075 282.829 363.411C286.185 374.747 293.539 384.465 303.523 390.761L292.871 407.655Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-89-inside-79_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-90-inside-80_1843_42025">
      <path
        d="M386.33 202.077C418.204 211.478 445.841 231.623 464.546 259.091C483.251 286.559 491.869 319.656 488.938 352.758L417.284 346.413C418.75 329.862 414.441 313.314 405.088 299.58C395.736 285.846 381.917 275.773 365.98 271.073L386.33 202.077Z"
      />
    </mask>
    <path
      d="M386.33 202.077C418.204 211.478 445.841 231.623 464.546 259.091C483.251 286.559 491.869 319.656 488.938 352.758L417.284 346.413C418.75 329.862 414.441 313.314 405.088 299.58C395.736 285.846 381.917 275.773 365.98 271.073L386.33 202.077Z"
      mask="url(#path-90-inside-80_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-91-inside-81_1843_42025">
      <path
        d="M352.652 275.411C363.195 278.679 372.917 284.162 381.169 291.492C389.421 298.823 396.012 307.832 400.499 317.916C404.987 328.001 407.268 338.927 407.191 349.965C407.113 361.002 404.678 371.896 400.049 381.916C395.42 391.936 388.703 400.851 380.349 408.065C371.994 415.279 362.195 420.624 351.607 423.744C341.02 426.863 329.888 427.684 318.957 426.152C308.026 424.621 297.548 420.771 288.226 414.862L300.159 396.036C306.8 400.246 314.263 402.988 322.05 404.079C329.836 405.171 337.766 404.586 345.308 402.363C352.85 400.141 359.831 396.334 365.782 391.195C371.733 386.056 376.518 379.706 379.815 372.568C383.113 365.43 384.847 357.67 384.903 349.808C384.958 341.945 383.333 334.162 380.136 326.978C376.939 319.795 372.245 313.378 366.366 308.156C360.488 302.934 353.562 299.028 346.052 296.7L352.652 275.411Z"
      />
    </mask>
    <path
      d="M352.652 275.411C363.195 278.679 372.917 284.162 381.169 291.492C389.421 298.823 396.012 307.832 400.499 317.916C404.987 328.001 407.268 338.927 407.191 349.965C407.113 361.002 404.678 371.896 400.049 381.916C395.42 391.936 388.703 400.851 380.349 408.065C371.994 415.279 362.195 420.624 351.607 423.744C341.02 426.863 329.888 427.684 318.957 426.152C308.026 424.621 297.548 420.771 288.226 414.862L300.159 396.036C306.8 400.246 314.263 402.988 322.05 404.079C329.836 405.171 337.766 404.586 345.308 402.363C352.85 400.141 359.831 396.334 365.782 391.195C371.733 386.056 376.518 379.706 379.815 372.568C383.113 365.43 384.847 357.67 384.903 349.808C384.958 341.945 383.333 334.162 380.136 326.978C376.939 319.795 372.245 313.378 366.366 308.156C360.488 302.934 353.562 299.028 346.052 296.7L352.652 275.411Z"
      mask="url(#path-91-inside-81_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-92-inside-82_1843_42025">
      <path
        d="M328.592 574.796C325.804 565.042 321.292 555.866 315.269 547.703L297.136 561.083C301.562 567.08 304.877 573.822 306.925 580.988L328.592 574.796Z"
      />
    </mask>
    <path
      d="M328.592 574.796C325.804 565.042 321.292 555.866 315.269 547.703L297.136 561.083C301.562 567.08 304.877 573.822 306.925 580.988L328.592 574.796Z"
      mask="url(#path-92-inside-82_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-93-inside-83_1843_42025">
      <path
        d="M331.386 519.871C320.96 508.284 307.546 499.789 292.613 495.317C277.681 490.845 261.805 490.568 246.726 494.517L257.486 535.601C265.025 533.626 272.963 533.765 280.429 536.001C287.895 538.237 294.602 542.484 299.816 548.278L331.386 519.871Z"
      />
    </mask>
    <path
      d="M331.386 519.871C320.96 508.284 307.546 499.789 292.613 495.317C277.681 490.845 261.805 490.568 246.726 494.517L257.486 535.601C265.025 533.626 272.963 533.765 280.429 536.001C287.895 538.237 294.602 542.484 299.816 548.278L331.386 519.871Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-93-inside-83_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-94-inside-84_1843_42025">
      <path
        d="M240.327 509.512C229.586 512.611 219.562 517.801 210.831 524.782L237.354 557.951C241.72 554.461 246.731 551.866 252.102 550.316L240.327 509.512Z"
      />
    </mask>
    <path
      d="M240.327 509.512C229.586 512.611 219.562 517.801 210.831 524.782L237.354 557.951C241.72 554.461 246.731 551.866 252.102 550.316L240.327 509.512Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-94-inside-84_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-95-inside-85_1843_42025">
      <path
        d="M345.011 616.256C340.28 631.529 331.333 645.157 319.2 655.571C307.068 665.986 292.242 672.764 276.428 675.127L270.152 633.123C278.059 631.942 285.472 628.553 291.539 623.346C297.605 618.139 302.078 611.325 304.444 603.688L345.011 616.256Z"
      />
    </mask>
    <path
      d="M345.011 616.256C340.28 631.529 331.333 645.157 319.2 655.571C307.068 665.986 292.242 672.764 276.428 675.127L270.152 633.123C278.059 631.942 285.472 628.553 291.539 623.346C297.605 618.139 302.078 611.325 304.444 603.688L345.011 616.256Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-95-inside-85_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <path
      d="M371.134 568.921C374.885 585.731 374.339 603.215 369.547 619.757L320.856 605.48C323.218 597.228 323.49 588.518 321.647 580.134L371.134 568.921Z"
      fill="var(--zero-background-svg)"
      stroke="var(--zero-background-svg)"
      stroke-width="0.669328"
    />
    <mask fill="white" id="path-97-inside-86_1843_42025">
      <path
        d="M272.472 659.336C267.786 659.952 263.05 660.1 258.334 659.776L260.086 634.236C263.104 634.443 266.135 634.349 269.134 633.954L272.472 659.336Z"
      />
    </mask>
    <path
      d="M272.472 659.336C267.786 659.952 263.05 660.1 258.334 659.776L260.086 634.236C263.104 634.443 266.135 634.349 269.134 633.954L272.472 659.336Z"
      mask="url(#path-97-inside-86_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-98-inside-87_1843_42025">
      <path
        d="M257.493 659.713C254.909 659.505 252.339 659.156 249.794 658.668L254.62 633.527C256.249 633.839 257.894 634.063 259.548 634.196L257.493 659.713Z"
      />
    </mask>
    <path
      d="M257.493 659.713C254.909 659.505 252.339 659.156 249.794 658.668L254.62 633.527C256.249 633.839 257.894 634.063 259.548 634.196L257.493 659.713Z"
      mask="url(#path-98-inside-87_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-99-inside-88_1843_42025">
      <path
        d="M243.568 675.244C240.434 674.596 237.347 673.736 234.328 672.672L242.843 648.529C244.775 649.21 246.75 649.76 248.756 650.175L243.568 675.244Z"
      />
    </mask>
    <path
      d="M243.568 675.244C240.434 674.596 237.347 673.736 234.328 672.672L242.843 648.529C244.775 649.21 246.75 649.76 248.756 650.175L243.568 675.244Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-99-inside-88_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask
      fill="black"
      height="32.0779"
      id="path-100-outside-89_1843_42025"
      maskUnits="userSpaceOnUse"
      width="31.3236"
      x="219.906"
      y="621.042"
    >
      <rect
        fill="white"
        height="32.0779"
        width="31.3236"
        x="219.906"
        y="621.042"
      />
      <path
        d="M241.299 648.311C234.566 645.631 228.362 641.777 222.975 636.929L233.545 625.187C237.542 628.784 242.145 631.644 247.141 633.632L241.299 648.311Z"
      />
    </mask>
    <path
      d="M241.299 648.311C234.566 645.631 228.362 641.777 222.975 636.929L233.545 625.187C237.542 628.784 242.145 631.644 247.141 633.632L241.299 648.311Z"
      fill="var(--zero-background-svg)"
    />
    <path
      d="M241.299 648.311C234.566 645.631 228.362 641.777 222.975 636.929L233.545 625.187C237.542 628.784 242.145 631.644 247.141 633.632L241.299 648.311Z"
      mask="url(#path-100-outside-89_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-linejoin="round"
      stroke-width="1.33866"
    />
    <mask
      fill="black"
      height="30.6607"
      id="path-101-outside-90_1843_42025"
      maskUnits="userSpaceOnUse"
      width="29.1521"
      x="206.838"
      y="609.065"
    >
      <rect
        fill="white"
        height="30.6607"
        width="29.1521"
        x="206.838"
        y="609.065"
      />
      <path
        d="M222.338 636.347C216.975 631.381 212.544 625.494 209.255 618.966L223.365 611.858C225.805 616.702 229.093 621.07 233.072 624.755L222.338 636.347Z"
      />
    </mask>
    <path
      d="M222.338 636.347C216.975 631.381 212.544 625.494 209.255 618.966L223.365 611.858C225.805 616.702 229.093 621.07 233.072 624.755L222.338 636.347Z"
      mask="url(#path-101-outside-90_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-linejoin="round"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-102-inside-91_1843_42025">
      <path
        d="M303.408 582.163C305.576 591.734 304.186 601.769 299.495 610.407C294.805 619.044 287.132 625.697 277.901 629.131C268.67 632.564 258.508 632.545 249.301 629.077C240.094 625.609 232.467 618.927 227.837 610.273L238.241 604.731C241.53 610.877 246.946 615.622 253.484 618.085C260.022 620.547 267.239 620.561 273.794 618.123C280.349 615.684 285.798 610.96 289.129 604.826C292.461 598.692 293.448 591.566 291.908 584.769L303.408 582.163Z"
      />
    </mask>
    <path
      d="M303.408 582.163C305.576 591.734 304.186 601.769 299.495 610.407C294.805 619.044 287.132 625.697 277.901 629.131C268.67 632.564 258.508 632.545 249.301 629.077C240.094 625.609 232.467 618.927 227.837 610.273L238.241 604.731C241.53 610.877 246.946 615.622 253.484 618.085C260.022 620.547 267.239 620.561 273.794 618.123C280.349 615.684 285.798 610.96 289.129 604.826C292.461 598.692 293.448 591.566 291.908 584.769L303.408 582.163Z"
      mask="url(#path-102-inside-91_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-103-inside-92_1843_42025">
      <path
        d="M178.567 628.969C169.185 611.738 166.058 591.79 169.716 572.514C173.374 553.238 183.593 535.824 198.636 523.228L225.901 555.79C218.379 562.088 213.27 570.795 211.441 580.433C209.611 590.071 211.175 600.045 215.866 608.661L178.567 628.969Z"
      />
    </mask>
    <path
      d="M178.567 628.969C169.185 611.738 166.058 591.79 169.716 572.514C173.374 553.238 183.593 535.824 198.636 523.228L225.901 555.79C218.379 562.088 213.27 570.795 211.441 580.433C209.611 590.071 211.175 600.045 215.866 608.661L178.567 628.969Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-103-inside-92_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-104-inside-93_1843_42025">
      <path
        d="M223.242 612.414C220.205 606.648 218.435 600.3 218.05 593.795C217.666 587.29 218.675 580.777 221.012 574.694C223.348 568.611 226.957 563.097 231.598 558.521C236.238 553.946 241.802 550.415 247.918 548.164C254.033 545.914 260.559 544.996 267.058 545.472C273.558 545.948 279.88 547.807 285.602 550.925C291.325 554.043 296.315 558.347 300.239 563.55C304.163 568.753 306.93 574.734 308.354 581.093L295.514 583.97C294.499 579.441 292.528 575.18 289.733 571.474C286.937 567.768 283.383 564.701 279.307 562.48C275.23 560.26 270.727 558.935 266.097 558.596C261.467 558.257 256.819 558.911 252.462 560.514C248.106 562.117 244.142 564.632 240.837 567.892C237.531 571.151 234.96 575.079 233.296 579.412C231.632 583.745 230.912 588.384 231.186 593.018C231.46 597.652 232.721 602.174 234.885 606.281L223.242 612.414Z"
      />
    </mask>
    <path
      d="M223.242 612.414C220.205 606.648 218.435 600.3 218.05 593.795C217.666 587.29 218.675 580.777 221.012 574.694C223.348 568.611 226.957 563.097 231.598 558.521C236.238 553.946 241.802 550.415 247.918 548.164C254.033 545.914 260.559 544.996 267.058 545.472C273.558 545.948 279.88 547.807 285.602 550.925C291.325 554.043 296.315 558.347 300.239 563.55C304.163 568.753 306.93 574.734 308.354 581.093L295.514 583.97C294.499 579.441 292.528 575.18 289.733 571.474C286.937 567.768 283.383 564.701 279.307 562.48C275.23 560.26 270.727 558.935 266.097 558.596C261.467 558.257 256.819 558.911 252.462 560.514C248.106 562.117 244.142 564.632 240.837 567.892C237.531 571.151 234.96 575.079 233.296 579.412C231.632 583.745 230.912 588.384 231.186 593.018C231.46 597.652 232.721 602.174 234.885 606.281L223.242 612.414Z"
      mask="url(#path-104-inside-93_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-105-inside-94_1843_42025">
      <path
        d="M489.139 535.105C484.636 530.288 479.307 526.316 473.403 523.377L466.876 536.491C471.214 538.65 475.129 541.568 478.438 545.108L489.139 535.105Z"
      />
    </mask>
    <path
      d="M489.139 535.105C484.636 530.288 479.307 526.316 473.403 523.377L466.876 536.491C471.214 538.65 475.129 541.568 478.438 545.108L489.139 535.105Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-105-inside-94_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-106-inside-95_1843_42025">
      <path
        d="M474.483 502.499C465.017 498.884 454.739 497.944 444.775 499.78C434.81 501.617 425.543 506.161 417.988 512.914L436.386 533.496C440.163 530.12 444.797 527.848 449.779 526.929C454.762 526.011 459.901 526.481 464.634 528.289L474.483 502.499Z"
      />
    </mask>
    <path
      d="M474.483 502.499C465.017 498.884 454.739 497.944 444.775 499.78C434.81 501.617 425.543 506.161 417.988 512.914L436.386 533.496C440.163 530.12 444.797 527.848 449.779 526.929C454.762 526.011 459.901 526.481 464.634 528.289L474.483 502.499Z"
      mask="url(#path-106-inside-95_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-107-inside-96_1843_42025">
      <path
        d="M418.729 523.486C413.433 528.461 409.171 534.434 406.188 541.06L431.361 552.392C432.852 549.079 434.983 546.093 437.631 543.605L418.729 523.486Z"
      />
    </mask>
    <path
      d="M418.729 523.486C413.433 528.461 409.171 534.434 406.188 541.06L431.361 552.392C432.852 549.079 434.983 546.093 437.631 543.605L418.729 523.486Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-107-inside-96_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-108-inside-97_1843_42025">
      <path
        d="M510.924 554.229C512.712 564.468 511.573 575.003 507.639 584.623C503.705 594.244 497.135 602.558 488.686 608.611L472.61 586.168C476.835 583.142 480.119 578.984 482.086 574.174C484.054 569.364 484.623 564.096 483.729 558.977L510.924 554.229Z"
      />
    </mask>
    <path
      d="M510.924 554.229C512.712 564.468 511.573 575.003 507.639 584.623C503.705 594.244 497.135 602.558 488.686 608.611L472.61 586.168C476.835 583.142 480.119 578.984 482.086 574.174C484.054 569.364 484.623 564.096 483.729 558.977L510.924 554.229Z"
      mask="url(#path-108-inside-97_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <path
      d="M511.998 519.267C519.07 527.817 523.891 538.002 526.02 548.891L493.848 555.014C492.762 549.601 490.365 544.538 486.868 540.267L511.998 519.267Z"
      fill="var(--zero-background-svg)"
      stroke="var(--zero-background-svg)"
      stroke-width="0.669328"
    />
    <mask fill="white" id="path-110-inside-98_1843_42025">
      <path
        d="M481.718 600.646C479.19 602.391 476.493 603.879 473.669 605.089L467.115 589.793C468.923 589.019 470.649 588.066 472.267 586.949L481.718 600.646Z"
      />
    </mask>
    <path
      d="M481.718 600.646C479.19 602.391 476.493 603.879 473.669 605.089L467.115 589.793C468.923 589.019 470.649 588.066 472.267 586.949L481.718 600.646Z"
      mask="url(#path-110-inside-98_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-111-inside-99_1843_42025">
      <path
        d="M473.164 605.302C471.607 605.947 470.017 606.507 468.399 606.978L463.743 591.002C464.778 590.701 465.796 590.342 466.792 589.93L473.164 605.302Z"
      />
    </mask>
    <path
      d="M473.164 605.302C471.607 605.947 470.017 606.507 468.399 606.978L463.743 591.002C464.778 590.701 465.796 590.342 466.792 589.93L473.164 605.302Z"
      mask="url(#path-111-inside-99_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-112-inside-100_1843_42025">
      <path
        d="M469.709 618.414C467.704 618.967 465.663 619.384 463.601 619.663L461.374 603.172C462.694 602.993 464 602.726 465.283 602.372L469.709 618.414Z"
      />
    </mask>
    <path
      d="M469.709 618.414C467.704 618.967 465.663 619.384 463.601 619.663L461.374 603.172C462.694 602.993 464 602.726 465.283 602.372L469.709 618.414Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-112-inside-100_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask
      fill="black"
      height="25.3544"
      id="path-113-outside-101_1843_42025"
      maskUnits="userSpaceOnUse"
      width="25.3544"
      x="441.571"
      y="586.048"
    >
      <rect
        fill="white"
        height="25.3544"
        width="25.3544"
        x="441.571"
        y="586.048"
      />
      <path
        d="M460.415 603.504C455.725 603.948 450.994 603.557 446.441 602.347L449.077 592.422C452.455 593.319 455.966 593.61 459.446 593.28L460.415 603.504Z"
      />
    </mask>
    <path
      d="M460.415 603.504C455.725 603.948 450.994 603.557 446.441 602.347L449.077 592.422C452.455 593.319 455.966 593.61 459.446 593.28L460.415 603.504Z"
      mask="url(#path-113-outside-101_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-linejoin="round"
      stroke-width="1.33866"
    />
    <mask
      fill="black"
      height="23.9458"
      id="path-114-outside-102_1843_42025"
      maskUnits="userSpaceOnUse"
      width="23.9458"
      x="429.788"
      y="583.504"
    >
      <rect
        fill="white"
        height="23.9458"
        width="23.9458"
        x="429.788"
        y="583.504"
      />
      <path
        d="M445.9 602.199C441.325 600.915 437.017 598.822 433.18 596.019L439.238 587.726C442.085 589.806 445.281 591.359 448.676 592.312L445.9 602.199Z"
      />
    </mask>
    <path
      d="M445.9 602.199C441.325 600.915 437.017 598.822 433.18 596.019L439.238 587.726C442.085 589.806 445.281 591.359 448.676 592.312L445.9 602.199Z"
      fill="var(--zero-background-svg)"
    />
    <path
      d="M445.9 602.199C441.325 600.915 437.017 598.822 433.18 596.019L439.238 587.726C442.085 589.806 445.281 591.359 448.676 592.312L445.9 602.199Z"
      mask="url(#path-114-outside-102_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-linejoin="round"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-115-inside-103_1843_42025">
      <path
        d="M476.751 546.829C480.841 551.724 483.01 557.942 482.855 564.33C482.7 570.717 480.232 576.84 475.909 581.561C471.585 586.283 465.7 589.283 459.346 590.004C452.991 590.725 446.599 589.119 441.355 585.484L445.733 579.194C449.457 581.776 453.996 582.917 458.509 582.405C463.021 581.892 467.201 579.762 470.271 576.409C473.341 573.056 475.094 568.708 475.204 564.172C475.314 559.636 473.774 555.22 470.869 551.744L476.751 546.829Z"
      />
    </mask>
    <path
      d="M476.751 546.829C480.841 551.724 483.01 557.942 482.855 564.33C482.7 570.717 480.232 576.84 475.909 581.561C471.585 586.283 465.7 589.283 459.346 590.004C452.991 590.725 446.599 589.119 441.355 585.484L445.733 579.194C449.457 581.776 453.996 582.917 458.509 582.405C463.021 581.892 467.201 579.762 470.271 576.409C473.341 573.056 475.094 568.708 475.204 564.172C475.314 559.636 473.774 555.22 470.869 551.744L476.751 546.829Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-115-inside-103_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-116-inside-104_1843_42025">
      <path
        d="M418.389 610.899C407.855 603.709 400.135 593.094 396.541 580.858C392.947 568.621 393.699 555.518 398.671 543.773L424.093 554.535C421.607 560.407 421.231 566.959 423.028 573.077C424.825 579.196 428.685 584.503 433.952 588.098L418.389 610.899Z"
      />
    </mask>
    <path
      d="M418.389 610.899C407.855 603.709 400.135 593.094 396.541 580.858C392.947 568.621 393.699 555.518 398.671 543.773L424.093 554.535C421.607 560.407 421.231 566.959 423.028 573.077C424.825 579.196 428.685 584.503 433.952 588.098L418.389 610.899Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-116-inside-104_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-117-inside-105_1843_42025">
      <path
        d="M439.332 588.085C435.867 585.649 432.962 582.5 430.812 578.85C428.662 575.201 427.316 571.134 426.866 566.922C426.415 562.71 426.87 558.45 428.199 554.428C429.528 550.406 431.701 546.714 434.573 543.6C437.444 540.486 440.948 538.021 444.849 536.371C448.751 534.721 452.96 533.924 457.194 534.032C461.429 534.141 465.591 535.152 469.403 537C473.215 538.848 476.588 541.489 479.297 544.746L472.72 550.215C470.79 547.895 468.388 546.014 465.672 544.697C462.957 543.381 459.992 542.66 456.975 542.583C453.959 542.506 450.961 543.074 448.182 544.249C445.402 545.425 442.907 547.181 440.861 549.399C438.815 551.617 437.267 554.247 436.321 557.112C435.374 559.977 435.05 563.011 435.371 566.012C435.692 569.012 436.65 571.909 438.182 574.509C439.713 577.109 441.783 579.352 444.251 581.087L439.332 588.085Z"
      />
    </mask>
    <path
      d="M439.332 588.085C435.867 585.649 432.962 582.5 430.812 578.85C428.662 575.201 427.316 571.134 426.866 566.922C426.415 562.71 426.87 558.45 428.199 554.428C429.528 550.406 431.701 546.714 434.573 543.6C437.444 540.486 440.948 538.021 444.849 536.371C448.751 534.721 452.96 533.924 457.194 534.032C461.429 534.141 465.591 535.152 469.403 537C473.215 538.848 476.588 541.489 479.297 544.746L472.72 550.215C470.79 547.895 468.388 546.014 465.672 544.697C462.957 543.381 459.992 542.66 456.975 542.583C453.959 542.506 450.961 543.074 448.182 544.249C445.402 545.425 442.907 547.181 440.861 549.399C438.815 551.617 437.267 554.247 436.321 557.112C435.374 559.977 435.05 563.011 435.371 566.012C435.692 569.012 436.65 571.909 438.182 574.509C439.713 577.109 441.783 579.352 444.251 581.087L439.332 588.085Z"
      mask="url(#path-117-inside-105_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-118-inside-106_1843_42025">
      <path
        d="M653.022 428.075C649.987 417.455 645.075 407.465 638.517 398.578L618.776 413.145C623.594 419.674 627.203 427.014 629.433 434.816L653.022 428.075Z"
      />
    </mask>
    <path
      d="M653.022 428.075C649.987 417.455 645.075 407.465 638.517 398.578L618.776 413.145C623.594 419.674 627.203 427.014 629.433 434.816L653.022 428.075Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-118-inside-106_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-119-inside-107_1843_42025">
      <path
        d="M656.066 368.278C644.714 355.663 630.11 346.414 613.853 341.546C597.596 336.677 580.312 336.376 563.895 340.675L575.609 385.404C583.818 383.254 592.459 383.404 600.588 385.839C608.717 388.273 616.019 392.897 621.694 399.205L656.066 368.278Z"
      />
    </mask>
    <path
      d="M656.066 368.278C644.714 355.663 630.11 346.414 613.853 341.546C597.596 336.677 580.312 336.376 563.895 340.675L575.609 385.404C583.818 383.254 592.459 383.404 600.588 385.839C608.717 388.273 616.019 392.897 621.694 399.205L656.066 368.278Z"
      mask="url(#path-119-inside-107_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-120-inside-108_1843_42025">
      <path
        d="M556.929 357.001C545.235 360.375 534.322 366.025 524.817 373.626L553.692 409.737C558.445 405.937 563.902 403.112 569.748 401.425L556.929 357.001Z"
      />
    </mask>
    <path
      d="M556.929 357.001C545.235 360.375 534.322 366.025 524.817 373.626L553.692 409.737C558.445 405.937 563.902 403.112 569.748 401.425L556.929 357.001Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-120-inside-108_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-121-inside-109_1843_42025">
      <path
        d="M670.9 473.214C665.749 489.842 656.008 504.679 642.799 516.017C629.591 527.355 613.449 534.735 596.233 537.307L589.4 491.578C598.009 490.292 606.079 486.602 612.684 480.933C619.288 475.264 624.158 467.846 626.734 459.532L670.9 473.214Z"
      />
    </mask>
    <path
      d="M670.9 473.214C665.749 489.842 656.008 504.679 642.799 516.017C629.591 527.355 613.449 534.735 596.233 537.307L589.4 491.578C598.009 490.292 606.079 486.602 612.684 480.933C619.288 475.264 624.158 467.846 626.734 459.532L670.9 473.214Z"
      mask="url(#path-121-inside-109_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <path
      d="M699.361 421.644C703.455 439.968 702.86 459.029 697.631 477.062L644.564 461.502C647.144 452.505 647.441 443.006 645.427 433.865L699.361 421.644Z"
      fill="var(--zero-background-svg)"
      stroke="var(--zero-background-svg)"
      stroke-width="0.669328"
    />
    <mask fill="white" id="path-123-inside-110_1843_42025">
      <path
        d="M591.926 520.114C586.824 520.785 581.668 520.946 576.534 520.593L578.442 492.788C581.727 493.013 585.027 492.91 588.292 492.481L591.926 520.114Z"
      />
    </mask>
    <path
      d="M591.926 520.114C586.824 520.785 581.668 520.946 576.534 520.593L578.442 492.788C581.727 493.013 585.027 492.91 588.292 492.481L591.926 520.114Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-123-inside-110_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-124-inside-111_1843_42025">
      <path
        d="M575.618 520.525C572.806 520.299 570.007 519.918 567.236 519.386L572.491 492.015C574.264 492.356 576.055 492.599 577.856 492.744L575.618 520.525Z"
      />
    </mask>
    <path
      d="M575.618 520.525C572.806 520.299 570.007 519.918 567.236 519.386L572.491 492.015C574.264 492.356 576.055 492.599 577.856 492.744L575.618 520.525Z"
      mask="url(#path-124-inside-111_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-125-inside-112_1843_42025">
      <path
        d="M560.458 537.434C557.045 536.728 553.684 535.792 550.398 534.633L559.668 508.348C561.771 509.09 563.922 509.689 566.106 510.141L560.458 537.434Z"
      />
    </mask>
    <path
      d="M560.458 537.434C557.045 536.728 553.684 535.792 550.398 534.633L559.668 508.348C561.771 509.09 563.922 509.689 566.106 510.141L560.458 537.434Z"
      mask="url(#path-125-inside-112_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask
      fill="black"
      height="34.6914"
      id="path-126-outside-113_1843_42025"
      maskUnits="userSpaceOnUse"
      width="34.6914"
      x="534.715"
      y="478.646"
    >
      <rect
        fill="white"
        height="34.6914"
        width="34.6914"
        x="534.715"
        y="478.646"
      />
      <path
        d="M557.987 508.112C550.656 505.194 543.902 500.998 538.038 495.72L549.545 482.936C553.896 486.853 558.908 489.966 564.347 492.131L557.987 508.112Z"
      />
    </mask>
    <path
      d="M557.987 508.112C550.656 505.194 543.902 500.998 538.038 495.72L549.545 482.936C553.896 486.853 558.908 489.966 564.347 492.131L557.987 508.112Z"
      mask="url(#path-126-outside-113_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-linejoin="round"
      stroke-width="1.33866"
    />
    <mask
      fill="black"
      height="34.2495"
      id="path-127-outside-114_1843_42025"
      maskUnits="userSpaceOnUse"
      width="32.7409"
      x="520.23"
      y="464.939"
    >
      <rect
        fill="white"
        height="34.2495"
        width="32.7409"
        x="520.23"
        y="464.939"
      />
      <path
        d="M537.344 495.086C531.505 489.679 526.681 483.271 523.1 476.163L538.461 468.425C541.118 473.699 544.697 478.454 549.03 482.466L537.344 495.086Z"
      />
    </mask>
    <path
      d="M537.344 495.086C531.505 489.679 526.681 483.271 523.1 476.163L538.461 468.425C541.118 473.699 544.697 478.454 549.03 482.466L537.344 495.086Z"
      mask="url(#path-127-outside-114_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-linejoin="round"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-128-inside-115_1843_42025">
      <path
        d="M625.604 436.095C627.965 446.515 626.451 457.441 621.344 466.845C616.237 476.248 607.884 483.491 597.834 487.229C587.784 490.967 576.721 490.947 566.697 487.171C556.673 483.395 548.37 476.121 543.329 466.698L554.656 460.665C558.236 467.356 564.133 472.522 571.251 475.203C578.369 477.885 586.226 477.9 593.363 475.245C600.5 472.59 606.432 467.447 610.059 460.769C613.685 454.091 614.76 446.332 613.084 438.932L625.604 436.095Z"
      />
    </mask>
    <path
      d="M625.604 436.095C627.965 446.515 626.451 457.441 621.344 466.845C616.237 476.248 607.884 483.491 597.834 487.229C587.784 490.967 576.721 490.947 566.697 487.171C556.673 483.395 548.37 476.121 543.329 466.698L554.656 460.665C558.236 467.356 564.133 472.522 571.251 475.203C578.369 477.885 586.226 477.9 593.363 475.245C600.5 472.59 606.432 467.447 610.059 460.769C613.685 454.091 614.76 446.332 613.084 438.932L625.604 436.095Z"
      mask="url(#path-128-inside-115_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-129-inside-116_1843_42025">
      <path
        d="M489.689 487.054C479.475 468.294 476.07 446.577 480.053 425.591C484.036 404.606 495.161 385.646 511.539 371.933L541.222 407.384C533.033 414.24 527.471 423.72 525.479 434.213C523.488 444.706 525.19 455.564 530.297 464.944L489.689 487.054Z"
      />
    </mask>
    <path
      d="M489.689 487.054C479.475 468.294 476.07 446.577 480.053 425.591C484.036 404.606 495.161 385.646 511.539 371.933L541.222 407.384C533.033 414.24 527.471 423.72 525.479 434.213C523.488 444.706 525.19 455.564 530.297 464.944L489.689 487.054Z"
      mask="url(#path-129-inside-116_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-130-inside-117_1843_42025">
      <path
        d="M538.327 469.03C535.02 462.753 533.093 455.842 532.674 448.76C532.256 441.678 533.355 434.587 535.899 427.964C538.442 421.341 542.372 415.338 547.424 410.357C552.475 405.376 558.533 401.531 565.191 399.081C571.85 396.631 578.954 395.632 586.03 396.15C593.106 396.668 599.989 398.693 606.219 402.087C612.449 405.481 617.882 410.168 622.154 415.832C626.426 421.496 629.438 428.008 630.99 434.931L617.01 438.064C615.905 433.132 613.759 428.493 610.716 424.459C607.673 420.424 603.803 417.085 599.365 414.667C594.927 412.249 590.024 410.807 584.983 410.438C579.943 410.069 574.882 410.781 570.139 412.526C565.396 414.272 561.081 417.01 557.482 420.558C553.884 424.107 551.085 428.383 549.273 433.101C547.461 437.819 546.678 442.869 546.976 447.914C547.274 452.959 548.647 457.882 551.002 462.354L538.327 469.03Z"
      />
    </mask>
    <path
      d="M538.327 469.03C535.02 462.753 533.093 455.842 532.674 448.76C532.256 441.678 533.355 434.587 535.899 427.964C538.442 421.341 542.372 415.338 547.424 410.357C552.475 405.376 558.533 401.531 565.191 399.081C571.85 396.631 578.954 395.632 586.03 396.15C593.106 396.668 599.989 398.693 606.219 402.087C612.449 405.481 617.882 410.168 622.154 415.832C626.426 421.496 629.438 428.008 630.99 434.931L617.01 438.064C615.905 433.132 613.759 428.493 610.716 424.459C607.673 420.424 603.803 417.085 599.365 414.667C594.927 412.249 590.024 410.807 584.983 410.438C579.943 410.069 574.882 410.781 570.139 412.526C565.396 414.272 561.081 417.01 557.482 420.558C553.884 424.107 551.085 428.383 549.273 433.101C547.461 437.819 546.678 442.869 546.976 447.914C547.274 452.959 548.647 457.882 551.002 462.354L538.327 469.03Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-130-inside-117_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-131-inside-118_1843_42025">
      <path
        d="M547.46 582.403C545.109 585.435 543.321 588.863 542.18 592.526L550.315 595.061C551.154 592.371 552.468 589.852 554.194 587.624L547.46 582.403Z"
      />
    </mask>
    <path
      d="M547.46 582.403C545.109 585.435 543.321 588.863 542.18 592.526L550.315 595.061C551.154 592.371 552.468 589.852 554.194 587.624L547.46 582.403Z"
      mask="url(#path-131-inside-118_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-132-inside-119_1843_42025">
      <path
        d="M530.088 593.836C528.887 599.607 529.298 605.597 531.275 611.15C533.251 616.704 536.718 621.606 541.295 625.32L551.415 612.849C549.126 610.992 547.393 608.541 546.404 605.765C545.416 602.988 545.211 599.993 545.811 597.107L530.088 593.836Z"
      />
    </mask>
    <path
      d="M530.088 593.836C528.887 599.607 529.298 605.597 531.275 611.15C533.251 616.704 536.718 621.606 541.295 625.32L551.415 612.849C549.126 610.992 547.393 608.541 546.404 605.765C545.416 602.988 545.211 599.993 545.811 597.107L530.088 593.836Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-132-inside-119_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-133-inside-120_1843_42025">
      <path
        d="M547.298 623.917C550.646 626.499 554.47 628.394 558.552 629.494L562.732 613.988C560.691 613.438 558.779 612.49 557.105 611.199L547.298 623.917Z"
      />
    </mask>
    <path
      d="M547.298 623.917C550.646 626.499 554.47 628.394 558.552 629.494L562.732 613.988C560.691 613.438 558.779 612.49 557.105 611.199L547.298 623.917Z"
      mask="url(#path-133-inside-120_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-134-inside-121_1843_42025">
      <path
        d="M556.427 568.122C562.142 566.148 568.298 565.827 574.187 567.197C580.077 568.566 585.46 571.57 589.718 575.863L578.315 587.172C576.186 585.026 573.494 583.524 570.55 582.839C567.605 582.154 564.527 582.315 561.67 583.302L556.427 568.122Z"
      />
    </mask>
    <path
      d="M556.427 568.122C562.142 566.148 568.298 565.827 574.187 567.197C580.077 568.566 585.46 571.57 589.718 575.863L578.315 587.172C576.186 585.026 573.494 583.524 570.55 582.839C567.605 582.154 564.527 582.315 561.67 583.302L556.427 568.122Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-134-inside-121_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <path
      d="M536.447 570.725C540.643 565.977 545.944 562.336 551.883 560.125L558.28 577.775C555.346 578.894 552.721 580.697 550.622 583.033L536.447 570.725Z"
      fill="var(--zero-background-svg)"
      stroke="var(--zero-background-svg)"
      stroke-width="0.669328"
    />
    <mask fill="white" id="path-136-inside-122_1843_42025">
      <path
        d="M585.788 580.602C587.024 581.893 588.128 583.304 589.084 584.814L580.906 589.993C580.294 589.026 579.587 588.123 578.796 587.297L585.788 580.602Z"
      />
    </mask>
    <path
      d="M585.788 580.602C587.024 581.893 588.128 583.304 589.084 584.814L580.906 589.993C580.294 589.026 579.587 588.123 578.796 587.297L585.788 580.602Z"
      mask="url(#path-136-inside-122_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-137-inside-123_1843_42025">
      <path
        d="M589.254 585.084C589.768 585.919 590.237 586.781 590.657 587.666L581.912 591.818C581.643 591.252 581.343 590.7 581.014 590.166L589.254 585.084Z"
      />
    </mask>
    <path
      d="M589.254 585.084C589.768 585.919 590.237 586.781 590.657 587.666L581.912 591.818C581.643 591.252 581.343 590.7 581.014 590.166L589.254 585.084Z"
      mask="url(#path-137-inside-123_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-138-inside-124_1843_42025">
      <path
        d="M597.105 585.856C597.608 586.957 598.036 588.09 598.387 589.249L589.121 592.053C588.897 591.312 588.623 590.587 588.301 589.882L597.105 585.856Z"
      />
    </mask>
    <path
      d="M597.105 585.856C597.608 586.957 598.036 588.09 598.387 589.249L589.121 592.053C588.897 591.312 588.623 590.587 588.301 589.882L597.105 585.856Z"
      mask="url(#path-138-inside-124_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask
      fill="black"
      height="15.5178"
      id="path-139-outside-125_1843_42025"
      maskUnits="userSpaceOnUse"
      width="15.5178"
      x="579.464"
      y="589.21"
    >
      <rect
        fill="white"
        height="15.5178"
        width="15.5178"
        x="579.464"
        y="589.21"
      />
      <path
        d="M589.4 592.573C590.089 595.225 590.302 597.979 590.028 600.705L584.084 600.109C584.287 598.086 584.129 596.043 583.618 594.075L589.4 592.573Z"
      />
    </mask>
    <path
      d="M589.4 592.573C590.089 595.225 590.302 597.979 590.028 600.705L584.084 600.109C584.287 598.086 584.129 596.043 583.618 594.075L589.4 592.573Z"
      mask="url(#path-139-outside-125_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-linejoin="round"
      stroke-width="1.33866"
    />
    <mask
      fill="black"
      height="15.5178"
      id="path-140-outside-126_1843_42025"
      maskUnits="userSpaceOnUse"
      width="15.5178"
      x="578.551"
      y="596.363"
    >
      <rect
        fill="white"
        height="15.5178"
        width="15.5178"
        x="578.551"
        y="596.363"
      />
      <path
        d="M589.993 601.03C589.679 603.776 588.875 606.444 587.621 608.907L582.297 606.195C583.228 604.367 583.825 602.388 584.058 600.35L589.993 601.03Z"
      />
    </mask>
    <path
      d="M589.993 601.03C589.679 603.776 588.875 606.444 587.621 608.907L582.297 606.195C583.228 604.367 583.825 602.388 584.058 600.35L589.993 601.03Z"
      mask="url(#path-140-outside-126_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-linejoin="round"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-141-inside-127_1843_42025">
      <path
        d="M555.34 588.433C557.773 585.631 561.144 583.81 564.826 583.308C568.509 582.806 572.254 583.657 575.366 585.704C578.477 587.75 580.745 590.853 581.747 594.435C582.749 598.018 582.418 601.838 580.815 605.186L576.798 603.254C577.936 600.876 578.171 598.163 577.46 595.619C576.748 593.075 575.138 590.871 572.928 589.418C570.718 587.965 568.059 587.36 565.444 587.717C562.828 588.073 560.435 589.366 558.707 591.356L555.34 588.433Z"
      />
    </mask>
    <path
      d="M555.34 588.433C557.773 585.631 561.144 583.81 564.826 583.308C568.509 582.806 572.254 583.657 575.366 585.704C578.477 587.75 580.745 590.853 581.747 594.435C582.749 598.018 582.418 601.838 580.815 605.186L576.798 603.254C577.936 600.876 578.171 598.163 577.46 595.619C576.748 593.075 575.138 590.871 572.928 589.418C570.718 587.965 568.059 587.36 565.444 587.717C562.828 588.073 560.435 589.366 558.707 591.356L555.34 588.433Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-141-inside-127_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-142-inside-128_1843_42025">
      <path
        d="M597.537 616.026C594.382 622.741 588.999 628.156 582.304 631.353C575.608 634.549 568.013 635.329 560.808 633.56L564.637 617.964C568.239 618.848 572.037 618.458 575.385 616.86C578.733 615.262 581.424 612.554 583.001 609.196L597.537 616.026Z"
      />
    </mask>
    <path
      d="M597.537 616.026C594.382 622.741 588.999 628.156 582.304 631.353C575.608 634.549 568.013 635.329 560.808 633.56L564.637 617.964C568.239 618.848 572.037 618.458 575.385 616.86C578.733 615.262 581.424 612.554 583.001 609.196L597.537 616.026Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-142-inside-128_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-143-inside-129_1843_42025">
      <path
        d="M582.495 606.108C581.417 608.324 579.877 610.283 577.98 611.856C576.082 613.428 573.871 614.577 571.493 615.225C569.116 615.874 566.628 616.007 564.195 615.616C561.762 615.224 559.44 614.318 557.386 612.957C555.332 611.595 553.592 609.811 552.284 607.723C550.975 605.635 550.128 603.291 549.798 600.849C549.469 598.407 549.665 595.923 550.373 593.562C551.082 591.202 552.287 589.021 553.907 587.164L557.656 590.435C556.502 591.758 555.644 593.312 555.139 594.993C554.635 596.674 554.495 598.444 554.73 600.184C554.964 601.923 555.568 603.593 556.5 605.08C557.432 606.568 558.672 607.839 560.135 608.808C561.598 609.778 563.252 610.424 564.985 610.703C566.718 610.981 568.491 610.887 570.184 610.425C571.878 609.963 573.453 609.144 574.804 608.024C576.156 606.904 577.253 605.508 578.021 603.93L582.495 606.108Z"
      />
    </mask>
    <path
      d="M582.495 606.108C581.417 608.324 579.877 610.283 577.98 611.856C576.082 613.428 573.871 614.577 571.493 615.225C569.116 615.874 566.628 616.007 564.195 615.616C561.762 615.224 559.44 614.318 557.386 612.957C555.332 611.595 553.592 609.811 552.284 607.723C550.975 605.635 550.128 603.291 549.798 600.849C549.469 598.407 549.665 595.923 550.373 593.562C551.082 591.202 552.287 589.021 553.907 587.164L557.656 590.435C556.502 591.758 555.644 593.312 555.139 594.993C554.635 596.674 554.495 598.444 554.73 600.184C554.964 601.923 555.568 603.593 556.5 605.08C557.432 606.568 558.672 607.839 560.135 608.808C561.598 609.778 563.252 610.424 564.985 610.703C566.718 610.981 568.491 610.887 570.184 610.425C571.878 609.963 573.453 609.144 574.804 608.024C576.156 606.904 577.253 605.508 578.021 603.93L582.495 606.108Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-143-inside-129_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-144-inside-130_1843_42025">
      <path
        d="M142.178 480.759C138.803 482.584 135.783 484.997 133.259 487.887L139.677 493.492C141.531 491.37 143.751 489.596 146.23 488.256L142.178 480.759Z"
      />
    </mask>
    <path
      d="M142.178 480.759C138.803 482.584 135.783 484.997 133.259 487.887L139.677 493.492C141.531 491.37 143.751 489.596 146.23 488.256L142.178 480.759Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-144-inside-130_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-145-inside-131_1843_42025">
      <path
        d="M121.669 484.202C118.239 488.997 116.195 494.642 115.761 500.521C115.326 506.399 116.517 512.284 119.204 517.53L133.499 510.21C132.155 507.587 131.559 504.644 131.777 501.705C131.994 498.766 133.016 495.943 134.731 493.546L121.669 484.202Z"
      />
    </mask>
    <path
      d="M121.669 484.202C118.239 488.997 116.195 494.642 115.761 500.521C115.326 506.399 116.517 512.284 119.204 517.53L133.499 510.21C132.155 507.587 131.559 504.644 131.777 501.705C131.994 498.766 133.016 495.943 134.731 493.546L121.669 484.202Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-145-inside-131_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-146-inside-132_1843_42025">
      <path
        d="M125.264 518.672C127.283 522.386 130.016 525.664 133.306 528.319L143.393 515.823C141.748 514.495 140.382 512.856 139.372 510.999L125.264 518.672Z"
      />
    </mask>
    <path
      d="M125.264 518.672C127.283 522.386 130.016 525.664 133.306 528.319L143.393 515.823C141.748 514.495 140.382 512.856 139.372 510.999L125.264 518.672Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-146-inside-132_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-147-inside-133_1843_42025">
      <path
        d="M156.151 471.318C162.177 471.82 167.938 474.014 172.772 477.645C177.607 481.277 181.318 486.2 183.479 491.847L168.48 497.586C167.399 494.763 165.544 492.302 163.126 490.486C160.709 488.67 157.829 487.573 154.816 487.322L156.151 471.318Z"
      />
    </mask>
    <path
      d="M156.151 471.318C162.177 471.82 167.938 474.014 172.772 477.645C177.607 481.277 181.318 486.2 183.479 491.847L168.48 497.586C167.399 494.763 165.544 492.302 163.126 490.486C160.709 488.67 157.829 487.573 154.816 487.322L156.151 471.318Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-147-inside-133_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <path
      d="M136.82 465.629C142.576 462.98 148.897 461.791 155.222 462.167L153.945 480.896C150.809 480.735 147.679 481.324 144.816 482.614L136.82 465.629Z"
      fill="var(--zero-background-svg)"
      stroke="var(--zero-background-svg)"
      stroke-width="0.669328"
    />
    <mask fill="white" id="path-149-inside-134_1843_42025">
      <path
        d="M177.971 494.594C178.58 496.274 179.02 498.011 179.285 499.779L169.711 501.213C169.542 500.082 169.26 498.97 168.87 497.895L177.971 494.594Z"
      />
    </mask>
    <path
      d="M177.971 494.594C178.58 496.274 179.02 498.011 179.285 499.779L169.711 501.213C169.542 500.082 169.26 498.97 168.87 497.895L177.971 494.594Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-149-inside-134_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-150-inside-135_1843_42025">
      <path
        d="M179.33 500.095C179.464 501.066 179.545 502.043 179.572 503.023L169.894 503.289C169.877 502.662 169.826 502.036 169.74 501.415L179.33 500.095Z"
      />
    </mask>
    <path
      d="M179.33 500.095C179.464 501.066 179.545 502.043 179.572 503.023L169.894 503.289C169.877 502.662 169.826 502.036 169.74 501.415L179.33 500.095Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-150-inside-135_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-151-inside-136_1843_42025">
      <path
        d="M186.2 503.971C186.215 505.182 186.149 506.392 186.002 507.593L176.393 506.416C176.488 505.647 176.53 504.873 176.52 504.098L186.2 503.971Z"
      />
    </mask>
    <path
      d="M186.2 503.971C186.215 505.182 186.149 506.392 186.002 507.593L176.393 506.416C176.488 505.647 176.53 504.873 176.52 504.098L186.2 503.971Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-151-inside-136_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask
      fill="black"
      height="14.6375"
      id="path-152-outside-137_1843_42025"
      maskUnits="userSpaceOnUse"
      width="14.6375"
      x="165.352"
      y="502.827"
    >
      <rect
        fill="white"
        height="14.6375"
        width="14.6375"
        x="165.352"
        y="502.827"
      />
      <path
        d="M176.438 507.003C175.997 509.708 175.08 512.313 173.728 514.697L168.531 511.751C169.534 509.982 170.215 508.049 170.542 506.042L176.438 507.003Z"
      />
    </mask>
    <path
      d="M176.438 507.003C175.997 509.708 175.08 512.313 173.728 514.697L168.531 511.751C169.534 509.982 170.215 508.049 170.542 506.042L176.438 507.003Z"
      fill="var(--zero-background-svg)"
    />
    <path
      d="M176.438 507.003C175.997 509.708 175.08 512.313 173.728 514.697L168.531 511.751C169.534 509.982 170.215 508.049 170.542 506.042L176.438 507.003Z"
      mask="url(#path-152-outside-137_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-linejoin="round"
      stroke-width="1.33866"
    />
    <mask
      fill="black"
      height="14.6375"
      id="path-153-outside-138_1843_42025"
      maskUnits="userSpaceOnUse"
      width="14.6375"
      x="161.628"
      y="509.001"
    >
      <rect
        fill="white"
        height="14.6375"
        width="14.6375"
        x="161.628"
        y="509.001"
      />
      <path
        d="M173.565 514.98C172.168 517.365 170.355 519.481 168.213 521.227L164.439 516.596C166.028 515.3 167.373 513.73 168.41 511.96L173.565 514.98Z"
      />
    </mask>
    <path
      d="M173.565 514.98C172.168 517.365 170.355 519.481 168.213 521.227L164.439 516.596C166.028 515.3 167.373 513.73 168.41 511.96L173.565 514.98Z"
      fill="var(--zero-background-svg)"
    />
    <path
      d="M173.565 514.98C172.168 517.365 170.355 519.481 168.213 521.227L164.439 516.596C166.028 515.3 167.373 513.73 168.41 511.96L173.565 514.98Z"
      mask="url(#path-153-outside-138_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-linejoin="round"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-154-inside-139_1843_42025">
      <path
        d="M146.951 489.458C150.309 487.878 154.128 487.573 157.7 488.602C161.271 489.63 164.353 491.921 166.373 495.05C168.393 498.179 169.214 501.933 168.684 505.616C168.153 509.298 166.307 512.659 163.489 515.074L160.595 511.683C162.596 509.968 163.907 507.582 164.284 504.967C164.66 502.352 164.077 499.686 162.643 497.464C161.208 495.242 159.02 493.615 156.483 492.884C153.947 492.154 151.235 492.37 148.851 493.492L146.951 489.458Z"
      />
    </mask>
    <path
      d="M146.951 489.458C150.309 487.878 154.128 487.573 157.7 488.602C161.271 489.63 164.353 491.921 166.373 495.05C168.393 498.179 169.214 501.933 168.684 505.616C168.153 509.298 166.307 512.659 163.489 515.074L160.595 511.683C162.596 509.968 163.907 507.582 164.284 504.967C164.66 502.352 164.077 499.686 162.643 497.464C161.208 495.242 159.02 493.615 156.483 492.884C153.947 492.154 151.235 492.37 148.851 493.492L146.951 489.458Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-154-inside-139_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-155-inside-140_1843_42025">
      <path
        d="M174.408 531.744C168.809 536.613 161.698 539.393 154.282 539.613C146.866 539.832 139.602 537.478 133.726 532.949L143.528 520.228C146.467 522.492 150.098 523.67 153.806 523.56C157.514 523.45 161.07 522.06 163.869 519.626L174.408 531.744Z"
      />
    </mask>
    <path
      d="M174.408 531.744C168.809 536.613 161.698 539.393 154.282 539.613C146.866 539.832 139.602 537.478 133.726 532.949L143.528 520.228C146.467 522.492 150.098 523.67 153.806 523.56C157.514 523.45 161.07 522.06 163.869 519.626L174.408 531.744Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-155-inside-140_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <mask fill="white" id="path-156-inside-141_1843_42025">
      <path
        d="M164.653 516.596C162.771 518.188 160.572 519.358 158.201 520.03C155.83 520.703 153.343 520.86 150.906 520.493C148.469 520.126 146.139 519.243 144.071 517.902C142.004 516.561 140.246 514.794 138.917 512.719C137.588 510.644 136.717 508.309 136.363 505.871C136.01 503.432 136.181 500.946 136.866 498.579C137.551 496.211 138.734 494.018 140.336 492.145C141.937 490.272 143.92 488.763 146.152 487.719L148.261 492.226C146.671 492.97 145.258 494.045 144.118 495.379C142.977 496.713 142.134 498.276 141.646 499.962C141.158 501.648 141.036 503.419 141.288 505.156C141.54 506.893 142.16 508.557 143.107 510.035C144.054 511.513 145.306 512.772 146.779 513.727C148.252 514.682 149.911 515.311 151.647 515.573C153.383 515.834 155.155 515.722 156.844 515.243C158.532 514.764 160.1 513.93 161.44 512.797L164.653 516.596Z"
      />
    </mask>
    <path
      d="M164.653 516.596C162.771 518.188 160.572 519.358 158.201 520.03C155.83 520.703 153.343 520.86 150.906 520.493C148.469 520.126 146.139 519.243 144.071 517.902C142.004 516.561 140.246 514.794 138.917 512.719C137.588 510.644 136.717 508.309 136.363 505.871C136.01 503.432 136.181 500.946 136.866 498.579C137.551 496.211 138.734 494.018 140.336 492.145C141.937 490.272 143.92 488.763 146.152 487.719L148.261 492.226C146.671 492.97 145.258 494.045 144.118 495.379C142.977 496.713 142.134 498.276 141.646 499.962C141.158 501.648 141.036 503.419 141.288 505.156C141.54 506.893 142.16 508.557 143.107 510.035C144.054 511.513 145.306 512.772 146.779 513.727C148.252 514.682 149.911 515.311 151.647 515.573C153.383 515.834 155.155 515.722 156.844 515.243C158.532 514.764 160.1 513.93 161.44 512.797L164.653 516.596Z"
      fill="var(--zero-background-svg)"
      mask="url(#path-156-inside-141_1843_42025)"
      stroke="var(--zero-background-svg)"
      stroke-width="1.33866"
    />
    <path
      d="M434 606C395.88 638.373 330.031 731.451 371.592 844.783C423.544 986.447 225.973 998.998 217.058 1067C209.925 1121.4 223.038 1132.26 230.486 1130.89"
      stroke="var(--zero-background-svg)"
      stroke-width="3.51547"
    />
  </svg>
</section>
