import { Inject, Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { HttpService } from '@core/services/http.service';
import { ProjectModel } from '@ddb/models/project.model';
import { HttpPaginatedResponse } from '@ddb/types/http-pagination-response';
import { PROJECT_ENDPOINT } from '@ddb/tokens/project-endpoint.token';
import { Observable as Obs } from 'rxjs';

type Response = HttpPaginatedResponse & { projects: ProjectModel[] };

@Injectable({ providedIn: 'root' })
export class ProjectService {
  constructor(
    @Inject(PROJECT_ENDPOINT) private readonly endpoint: string,
    private readonly http: HttpService,
  ) {}

  public getByIds(
    resourceIds: string[],
    after: string | null = null,
  ): Obs<Response> {
    const headers = new HttpHeaders().set('Version', '2');
    const params = {
      project_id: resourceIds,
      ...(after && { after }),
    };
    return this.http.client
      .get<Response>(this.endpoint, { headers, params, observe: 'response' })
      .pipe(this.http.trackError(), this.http.body());
  }

  public getByProjectNumber(
    projectNumber: string,
    after: string | null = null,
  ): Obs<Response> {
    const headers = new HttpHeaders().set('Version', '2');
    const params = {
      search: projectNumber,
      sort_by: 'created_at',
      page_limit: 10,
      ...(after && { after }),
    };
    return this.http.client
      .get<Response>(this.endpoint, { headers, params, observe: 'response' })
      .pipe(this.http.trackError(), this.http.body());
  }
}
