import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { AssemblyModel } from '@veracity/models/assembly.model';
import { AssemblyAssumptionModel } from '@veracity/models/assembly-assumption.model';

export const CONFIG: MatDialogConfig = { disableClose: false };

export interface Data {
  assembly: AssemblyModel;
  assumptions: AssemblyAssumptionModel[];
}

export type Result = undefined;

@Component({
  templateUrl: './assembly-modal.component.html',
  styleUrls: ['./assembly-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssemblyModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly data: Data,
  ) {}
}
