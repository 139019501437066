import {
  HttpResponse as Res,
  HttpStatusCode,
  HttpClient,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AssetModel } from '@asset/models/asset.model';
import { Data as NotificationData } from '@core/modals/notification-modal/notification-modal.component';
import { NotificationService } from '@core/services/notification.service';
import { Assessment } from '@core/types/assessment';
import { AssessmentType, humanize } from '@core/enums/assessment-type';
import { ApplicationInsightsService } from '@core/services/application-insights.service';
import {
  OperatorFunction as OF,
  of,
  tap,
  switchMap,
  iif,
  throwError,
  catchError,
  map,
} from 'rxjs';

export const HTTP_ACTION = { InvalidLocation: 'invalid-location' } as const;

export type HttpAction = (typeof HTTP_ACTION)[keyof typeof HTTP_ACTION];

export interface Config {
  skipSystemNotification?: boolean;
  skipPerformAction?: boolean;
  catchErrorFor?: 'asset' | AssessmentType;
}

@Injectable({ providedIn: 'root' })
export class HttpService {
  constructor(
    private readonly insights: ApplicationInsightsService,
    private readonly notification: NotificationService,
    private readonly router: Router,
    public readonly client: HttpClient,
  ) {}

  public openSystemNotification<T>(config?: Config): OF<Res<T>, Res<T>> {
    return (response$) =>
      response$.pipe(
        switchMap((response) =>
          iif(
            () => !config?.skipSystemNotification,
            of(response).pipe(
              tap((response) => {
                if (
                  response.headers.has('x-system-notification') &&
                  response.headers.get('x-system-notification') === 'true' &&
                  response.headers.has('x-type') &&
                  response.headers.has('x-title') &&
                  response.headers.has('x-message')
                ) {
                  this.notification.open({
                    type: response.headers.get('x-type')!,
                    title: response.headers.get('x-title')!,
                    message: response.headers.get('x-message')!,
                  } as NotificationData);
                }
              }),
            ),
            of(response),
          ),
        ),
      );
  }

  public performAction<T>(config?: Config): OF<Res<T>, Res<T>> {
    return (response$) =>
      response$.pipe(
        switchMap((response) =>
          iif(
            () => !config?.skipPerformAction,
            of(response).pipe(
              tap((response) => {
                const action = response.headers.get(
                  'x-action',
                ) as HttpAction | null;
                switch (action) {
                  case HTTP_ACTION.InvalidLocation:
                    const asset = response.body! as unknown as AssetModel;
                    this.router.navigate(['asset', asset.id, 'edit'], {
                      state: { noLoad: true },
                    });
                    break;
                }
              }),
            ),
            of(response),
          ),
        ),
      );
  }

  public catchAssetError<T>(): OF<Res<T>, Res<T>> {
    return (response$) =>
      response$.pipe(
        // TODO: Remove this in favour of the backend trigguring the system notification
        // using the HTTP headers in the asset response
        tap((response) => {
          if (
            response.status === HttpStatusCode.PartialContent &&
            response.headers.get('x-system-notification') !== 'true'
          ) {
            const asset: AssetModel = response.body as unknown as AssetModel;
            this.notification.open({
              type: 'warning',
              title: 'Incomplete asset',
              message: `Asset "${asset.name}" contains missing parameters.  Please update the asset in DDB`,
            });
          }
        }),
        catchError((response: Res<T>) => {
          if (response.status === HttpStatusCode.Gone) {
            this.notification.open({
              type: 'warning',
              title: 'Asset archived',
              message: 'Asset has been archived.',
            });
            this.router.navigate(['collection']);
          }
          return throwError(() => response);
        }),
      );
  }

  public catchAssessmentError<T>(
    assessment: AssessmentType,
  ): OF<Res<Assessment<T>>, Res<Assessment<T>>> {
    return (response$) =>
      response$.pipe(
        // TODO: Remove this in favour of the backend opening a system notification
        // using the x-system-notification header. This is a temporary solution to
        // display a system notification when an assessment contains missing.
        // Open a system notification if the response status is 206 Partial Content.
        // This indicates that the assessment contains missing or invalid parameters.
        tap((response) => {
          if (response.status === HttpStatusCode.PartialContent) {
            // 204 - No Content repsonses represent an unstarted empty assessment.
            const humanized = humanize(assessment);
            this.notification.open({
              type: 'warning',
              title: `Incomplete ${humanized} assessment`,
              message: `Assessment ${humanized} assessment contains missing or invalid parameters.`,
            });
          }
        }),
        map((response) =>
          response.status === HttpStatusCode.Ok
            ? response
            : new Res<Assessment<T>>({
                ...response,
                url: '',
                body: null,
              }),
        ),
        catchError(() => of(new Res<Assessment<T>>({ body: null }))),
      );
  }

  public redirectForbidden<T>(): OF<Res<T>, Res<T>> {
    return (response$) =>
      response$.pipe(
        catchError((response) => {
          if (response.status === HttpStatusCode.Forbidden) {
            // TODO: Remove this in favour of the backend opening a system notification
            // using the x-system-notification header. This is a temporary solution to
            // display a system notification when the user is forbidden to access a resource.
            this.notification.open({
              type: 'error',
              title: 'Forbidden to access resource',
              message: 'You are not authorized to access this resource.',
            });

            this.router.navigate(['unauthorized']);
          }
          return throwError(() => response);
        }),
      );
  }

  public body<T>(): OF<Res<T>, T> {
    return (response$) =>
      response$.pipe(
        switchMap((response) =>
          iif(
            () => response.ok,
            of(response.body as T),
            throwError(() => response),
          ),
        ),
      );
  }

  public trackError<T>(): OF<T, T> {
    return (response$) =>
      response$.pipe(
        catchError((error) => {
          this.insights.instance?.trackException(error);
          return throwError(() => error);
        }),
      );
  }
}
