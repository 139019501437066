import { inject, InjectionToken } from '@angular/core';
import { WINDOW } from '@core/tokens/window.token';
import { DeviceType } from '@core/enums/device-type';

const MOBILE_REGEX =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

const TABLET_REGEX = /iPad/i;

export const DEVICE_TYPE = new InjectionToken<DeviceType>('DEVICE_TYPE', {
  factory: () => {
    const { userAgent } = inject(WINDOW).navigator;
    const isMobile = MOBILE_REGEX.test(userAgent);
    const isTablet = TABLET_REGEX.test(userAgent);
    return isTablet
      ? DeviceType.Tablet
      : isMobile
      ? DeviceType.Mobile
      : DeviceType.Desktop;
  },
});
