import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SubsystemName } from '@core/models/subsystem';

@Component({
  selector: 'zero-total-parameter-icon[subsystemName]',
  templateUrl: './total-parameter-icon.component.html',
  styleUrls: ['./total-parameter-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TotalParameterIconComponent {
  @Input() subsystemName!: SubsystemName;
}
