import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { AssetModel } from '@asset/models/asset.model';

@Component({
  selector: 'zero-asset-import-card',
  templateUrl: './asset-import-card.component.html',
  styleUrls: ['./asset-import-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetImportCardComponent {
  @Input() name!: AssetModel['name'];
  @Output() import = new EventEmitter<void>();
}
