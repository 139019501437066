import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AuthStore } from '@core/state/auth.store';
import { RUNTIME_CONFIGURATION } from '@core/tokens/runtime-configuration.token';
import { RuntimeConfigurationModel } from '@core/models/runtime-configuration.model';
import { Theme } from '@core/models/theme';
import { SettingsStore } from '@core/state/settings.store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'zero-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingPageComponent implements OnInit, OnDestroy {
  private readonly subscriptions = new Subscription();
  readonly authenticated$ = this.authStore.authenticated$;
  readonly user$ = this.authStore.user$;
  public logoURL = '';
  public readonly env = this.runtimeConfiguration.env;

  constructor(
    private readonly authStore: AuthStore,
    private readonly settingsStore: SettingsStore,
    private ref: ChangeDetectorRef,
    @Inject(RUNTIME_CONFIGURATION)
    private readonly runtimeConfiguration: RuntimeConfigurationModel,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.settingsStore.theme$.subscribe({
        next: (theme: Theme) => {
          switch (theme) {
            case 'light':
              this.logoURL = this.runtimeConfiguration.appDefaults.lightLogoURL;
              break;
            case 'dark':
              this.logoURL = this.runtimeConfiguration.appDefaults.darkLogoURL;
              break;
          }
          this.ref.markForCheck();
        },
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
