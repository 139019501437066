<ng-container *ngIf="data">
  <mat-icon [ngClass]="data.type">
    {{ data.type === 'success' ? 'check_circle' : data.type === 'info' ? 'info'
    : data.type === 'warning' ? 'warning' : 'error' }}
  </mat-icon>
  <div>
    <h3 [ngClass]="data.type">{{ data.title }}</h3>
    <p>{{ data.message }}</p>
  </div>
  <button mat-icon-button (click)="dismiss()">
    <mat-icon>close</mat-icon>
  </button>
</ng-container>
