import { AssessmentLevel } from '@core/enums/assessment-level';

export enum AssessmentType {
  Benchmark = 'benchmark',
  Embodied = 'embodied',
  Operational = 'operational',
  EnergyModel = 'energy-model',
  Detailed = 'detailed',
}

export function humanize(assessmentType: AssessmentType): string {
  switch (assessmentType) {
    case AssessmentType.Benchmark:
      return 'Benchmark';
    case AssessmentType.Embodied:
      return 'Embodied';
    case AssessmentType.Operational:
      return 'Operational';
    case AssessmentType.EnergyModel:
      return 'Energy Model';
    case AssessmentType.Detailed:
      return 'Detailed';
  }
}

export function level(assessmentType: AssessmentType): AssessmentLevel {
  switch (assessmentType) {
    case AssessmentType.Benchmark:
      return AssessmentLevel.Benchmark;
    case AssessmentType.Embodied:
      return AssessmentLevel.HighLevel;
    case AssessmentType.Operational:
      return AssessmentLevel.HighLevel;
    case AssessmentType.EnergyModel:
      return AssessmentLevel.HighLevel;
    case AssessmentType.Detailed:
      return AssessmentLevel.Detailed;
  }
}
