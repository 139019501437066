import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpEntityStore } from '@core/state/http-entity.store';
import { ProjectService } from '@ddb/services/project.service';
import { ProjectModel } from '@ddb/models/project.model';
import { tapResponse } from '@ngrx/component-store';
import { switchMap, expand, reduce, EMPTY, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProjectStore extends HttpEntityStore<ProjectModel> {
  constructor(
    private readonly project: ProjectService,
    private readonly snackBar: MatSnackBar,
  ) {
    super();
  }

  public readonly loadById = this.effect<string>((projectNumber$) =>
    projectNumber$.pipe(
      switchMap((projectNumber) => {
        this.setLoading(projectNumber);
        return this.project.getByProjectNumber(projectNumber).pipe(
          expand((response) =>
            response.paging.next && response.paging.cursors?.after
              ? this.project.getByProjectNumber(
                  projectNumber,
                  response.paging.cursors.after,
                )
              : EMPTY,
          ),
          reduce(
            (acc, response) => acc.concat(response.projects),
            <ProjectModel[]>[],
          ),
          map((projects) => {
            const project = projects[0];
            if (!project) throw new Error('Project not found');
            return project;
          }),
          tapResponse(
            (project) => {
              this.setSuccess([projectNumber, project]);
            },
            (error: HttpErrorResponse) => {
              this.setError([projectNumber, error]);
              this.snackBar.open(
                `Failed to load project ${projectNumber}`,
                'Dismiss',
              );
            },
          ),
        );
      }),
    ),
  );
}
