import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Optional,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';

export const CONFIG: MatDialogConfig = { disableClose: true };

type ConfirmRejectAction = { confirm: string; reject: string };

export type AcknowledgementAction = string | ConfirmRejectAction;

export interface Data {
  title: string;
  message: string;
  action: AcknowledgementAction;
  skippable: boolean;
}

interface ConfirmRejectResult {
  confirm: boolean;
  skip?: boolean;
}

export type Result = boolean | ConfirmRejectResult;

export function resultIsBoolean(result: Result): result is boolean {
  return typeof result === 'boolean';
}

export function resultIsObject(result: Result): result is ConfirmRejectResult {
  return typeof result === 'object';
}

@Component({
  templateUrl: './acknowledgement-modal.component.html',
  styleUrls: ['./acknowledgement-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcknowledgementModalComponent {
  public readonly checkboxControl = new FormControl<boolean>(false);

  constructor(
    private readonly dialogRef: MatDialogRef<AcknowledgementModalComponent>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public readonly data?: Data,
  ) {}

  public actionType(): 'string' | 'object' {
    return typeof this.data?.action === 'string' ? 'string' : 'object';
  }

  public confirmationText(): string {
    return typeof this.data?.action === 'object'
      ? this.data.action.confirm
      : '';
  }

  public rejectionText(): string {
    return typeof this.data?.action === 'object'
      ? this.data?.action.reject
      : '';
  }

  public handleAcknowledgement(action: 'confirm' | 'reject'): void {
    switch (this.data?.skippable) {
      case true:
        this.dialogRef.close({
          confirm: action === 'confirm',
          skip: this.checkboxControl.value,
        });
        break;
      case false:
        this.dialogRef.close(action === 'confirm');
        break;
    }
  }
}
