import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatRipple } from '@angular/material/core';

@Component({
  selector: 'zero-copy-to-clipboard[text]',
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: ['./copy-to-clipboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyToClipboardComponent {
  @Input() text!: string;
  @ViewChild(MatRipple) ripple!: MatRipple;

  constructor(private readonly clipboard: Clipboard) {}

  @HostListener('click')
  handleClick(): void {
    this.clipboard.copy(this.text);
    this.ripple.launch({});
  }
}
