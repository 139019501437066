import { Component, Input } from '@angular/core';

@Component({
  selector: 'zero-external-link[href]',
  templateUrl: './external-link.component.html',
  styleUrls: ['./external-link.component.scss'],
})
export class ExternalLinkComponent {
  @Input() href!: string;
}
