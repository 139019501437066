export const isGraphError = (e) => {
    const test = e;
    return (test.statusCode &&
        'code' in test &&
        'body' in test &&
        test.date &&
        'message' in test &&
        'name' in test &&
        'requestId' in test);
};
