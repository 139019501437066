import { Inject, Injectable } from '@angular/core';
import { HttpService } from '@core/services/http.service';
import { UserRolesModel } from '@ddb/models/user-roles.model';
import { HttpPaginatedResponse } from '@ddb/types/http-pagination-response';
import { USERS_ROLES_ENDPOINT } from '@ddb/tokens/users-roles-endpoint.token';
import { Observable as Obs, map } from 'rxjs';

type Response = HttpPaginatedResponse & { users_roles: UserRolesModel[] };

@Injectable({ providedIn: 'root' })
export class UsersRolesService {
  constructor(
    private readonly http: HttpService,
    @Inject(USERS_ROLES_ENDPOINT) private readonly endpoint: string,
  ) {}

  public getAll(email: string): Obs<Response> {
    const params = { emails: email };
    return this.http.client
      .get<Response>(this.endpoint, { params, observe: 'response' })
      .pipe(this.http.trackError(), this.http.body());
  }

  public get(resource_id: string, after: string | null = null): Obs<Response> {
    const params = {
      resource_id,
      order: 'asc',
      sort_by: 'email',
      page_limit: 10,
      ...(after && { after }),
    };
    return this.http.client
      .get<Response>(this.endpoint, { params, observe: 'response' })
      .pipe(this.http.trackError(), this.http.body());
  }

  public create(email: string, roleIds: string[]): Obs<UserRolesModel> {
    const data = { user_roles: { emails: [email], role_ids: roleIds } };
    return this.http.client
      .put<{ users_roles: UserRolesModel[] }>(this.endpoint, data, {
        observe: 'response',
      })
      .pipe(
        this.http.trackError(),
        this.http.body(),
        map(({ users_roles }) => users_roles[0]),
      );
  }

  public delete(userId: string, roleIds: string[]): Obs<null> {
    const body = {
      users_roles: roleIds.map((roleId) => ({
        user_id: userId,
        role_id: roleId,
      })),
    };
    return this.http.client
      .delete<null>(this.endpoint, { body, observe: 'response' })
      .pipe(this.http.trackError(), this.http.body());
  }
}
