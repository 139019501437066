import { Inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
} from '@angular/common/http';
import { ApplicationInsightsService } from '@core/services/application-insights.service';
import { PERFORMANCE } from '@core/tokens/performance.token';
import { MONITOR_REQUEST_CONFIG } from '@core/tokens/monitor-request-config.token';
import { finalize } from 'rxjs';

@Injectable()
export class MonitorRequestTimeInterceptor implements HttpInterceptor {
  constructor(
    private readonly applicationInsightsService: ApplicationInsightsService,
    @Inject(MONITOR_REQUEST_CONFIG)
    private readonly monitorRequestConfig: RegExp[],
    @Inject(PERFORMANCE) private readonly performance: Performance,
  ) {}

  intercept<T>(request: HttpRequest<T>, next: HttpHandler) {
    const start = this.performance.now();
    return next.handle(request).pipe(
      finalize(() => {
        if (this._shouldMonitor(request)) {
          this.applicationInsightsService.instance?.trackMetric({
            name: `Request Time: ${request.method} ${request.url}`,
            average: this.performance.now() - start,
            sampleCount: 1,
          });
        }
      }),
    );
  }

  private _shouldMonitor<T>(request: HttpRequest<T>) {
    for (const url of this.monitorRequestConfig) {
      if (url.test(request.url)) return true;
    }
    return false;
  }
}
