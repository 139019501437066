import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  ViewChild,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl,
} from '@angular/forms';
import { IDynamicPerson, MgtPeoplePicker } from '@microsoft/mgt';
import { Contact, Person, User } from '@microsoft/microsoft-graph-types';
import { GraphService } from '@core/services/graph.service';
import { NotificationService } from '@core/services/notification.service';
import { MatFormFieldControl } from '@angular/material/form-field';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Subject, catchError, forkJoin, of, takeUntil } from 'rxjs';

type PeoplePickerValue = string | string[] | null;

function isUser(dynamicPerson: IDynamicPerson): dynamicPerson is User {
  return (dynamicPerson as User).mail !== undefined;
}

function isPerson(dynamicPerson: IDynamicPerson): dynamicPerson is Person {
  return (dynamicPerson as Person).scoredEmailAddresses !== undefined;
}

function isContact(dynamicPerson: IDynamicPerson): dynamicPerson is Contact {
  return (dynamicPerson as Contact).emailAddresses !== undefined;
}

@Component({
  selector: 'zero-people-picker',
  templateUrl: './people-picker.component.html',
  styleUrls: ['./people-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PeoplePickerComponent),
      multi: true,
    },
    {
      provide: MatFormFieldControl,
      useExisting: forwardRef(() => PeoplePickerComponent),
    },
  ],
})
export class PeoplePickerComponent
  implements
    AfterViewInit,
    OnDestroy,
    DoCheck,
    ControlValueAccessor,
    MatFormFieldControl<PeoplePickerValue>
{
  private readonly _destroyed$ = new Subject<void>();
  @ViewChild('peoplePicker')
  private readonly _mgtPeoplePicker?: ElementRef<MgtPeoplePicker>;
  private _defaultPeople: IDynamicPerson[] = [];
  private _placeholder: MgtPeoplePicker['placeholder'] = 'Start typing a name';
  private _disabled = false;
  private _required = false;

  readonly stateChanges = new Subject<void>();
  @Input() selectionMode: MgtPeoplePicker['selectionMode'] = 'single';

  @Input() get placeholder(): MgtPeoplePicker['placeholder'] {
    return this._placeholder;
  }

  set placeholder(value: MgtPeoplePicker['placeholder']) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  set value(emails: PeoplePickerValue) {
    this.writeValue(emails);
    this.stateChanges.next();
  }

  static nextId = 0;

  @HostBinding()
  readonly id = `zero-people-picker-${PeoplePickerComponent.nextId++}`;

  ngControl: NgControl | null = null;
  focused = false;
  touched = false;

  onFocusIn(): void {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent): void {
    if (
      this._mgtPeoplePicker?.nativeElement.contains(
        event.relatedTarget as Element,
      )
    ) {
      this.touched = true;
      this.focused = false;
      this._onTouched();
      this.stateChanges.next();
    }
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled;
    this.stateChanges.next();
  }

  get empty(): boolean {
    if (this._mgtPeoplePicker) {
      return this._mgtPeoplePicker.nativeElement.selectedPeople.length === 0;
    }
    return true;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(req: BooleanInput) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return this.ngControl?.invalid ?? false;
  }

  set errorState(value: boolean | null | undefined) {
    if (value !== undefined && value !== null) {
      if (this.ngControl) {
        this.ngControl.control?.setErrors({
          ...this.ngControl.control?.errors,
          errorState: value,
        });
      }
    }
  }

  private updateErrorState(): void {
    const oldState = this.errorState;
    const newState = this.ngControl?.invalid;
    if (oldState !== newState) {
      this.errorState = newState;
      this.stateChanges.next();
    }
  }

  controlType = 'zero-people-picker';

  onContainerClick(event: MouseEvent): void {
    if (
      (event.target as Element).tagName.toLowerCase() !=
      this._mgtPeoplePicker?.nativeElement.tagName.toLowerCase()
    ) {
      this._mgtPeoplePicker?.nativeElement.focus();
    }
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy = '';

  setDescribedByIds(ids: string[]): void {
    this._mgtPeoplePicker?.nativeElement.setAttribute(
      'aria-describedby',
      ids.join(' '),
    );
  }

  constructor(
    private graphService: GraphService,
    private readonly notification: NotificationService,
  ) {}

  ngAfterViewInit(): void {
    if (this._mgtPeoplePicker) {
      this._mgtPeoplePicker.nativeElement.selectedPeople = this._defaultPeople;
    }
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
    this.stateChanges.complete();
  }

  handleSelectionChanged(event: Event): void {
    const emails = (event as CustomEvent<IDynamicPerson[]>).detail
      .map((dynamicPerson) => {
        return isUser(dynamicPerson)
          ? dynamicPerson.mail?.toLowerCase()
          : isPerson(dynamicPerson)
          ? dynamicPerson.scoredEmailAddresses?.[0].address?.toLowerCase()
          : isContact(dynamicPerson)
          ? dynamicPerson.emailAddresses?.[0].address?.toLowerCase()
          : null;
      })
      .filter((email) => {
        return email !== undefined && email !== null;
      }) as string[];
    this._onTouched();
    this._onChange(this.selectionMode === 'single' ? emails[0] : emails);
  }

  private _onChange(emails: PeoplePickerValue) {}

  /**
   * On Touched Callback.
   * Function to call when the control has been touched.
   */
  private _onTouched() {}

  registerOnChange(onChange: (emails: PeoplePickerValue) => void): void {
    this._onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this._onTouched = onTouched;
  }

  writeValue(emails: PeoplePickerValue): void {
    if (emails === null) emails = [];
    if (typeof emails === 'string') emails = [emails];
    forkJoin(
      emails.map((email) =>
        this.graphService.getUser(email).pipe(
          catchError(() => {
            return of(email);
          }),
        ),
      ),
    )
      .pipe(takeUntil(this._destroyed$))
      .subscribe((users) => {
        const failedEmails = users.filter((user) => typeof user === 'string');
        if (failedEmails.length > 0) {
          this.notification.open({
            type: 'error',
            title: 'Failed to fetch user details',
            message: `Failed to fetch user details for ${failedEmails.join(
              ', ',
            )}.`,
          });
        }
        const successfulUsers = users.filter(
          (user) => typeof user !== 'string',
        ) as User[];
        if (this._mgtPeoplePicker) {
          this._mgtPeoplePicker.nativeElement.selectedPeople = successfulUsers;
        } else {
          this._defaultPeople = successfulUsers;
        }
      });
  }
}
