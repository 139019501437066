import { inject, InjectionToken } from '@angular/core';
import { RUNTIME_CONFIGURATION } from '@core/tokens/runtime-configuration.token';

function projectEndpointFactory(): string {
  return `${inject(RUNTIME_CONFIGURATION).api.zero.baseUrl}/projects`;
}

export const PROJECT_ENDPOINT = new InjectionToken<string>('PROJECT_ENDPOINT', {
  factory: projectEndpointFactory,
});
