<mat-form-field>
  <mat-label>{{ label }}</mat-label>
  <mat-select
    #select
    [disabled]="disabled"
    [required]="required"
    (blur)="handleBlur()"
    (valueChange)="handleValueChange($event)"
  >
    <mat-option *ngFor="let option of options" [value]="option">
      {{ formatOption(option) }}
    </mat-option>
  </mat-select>
  <mat-spinner *ngIf="loading" diameter="20" matSuffix></mat-spinner>
  <button
    *ngIf="clearable && !disabled && !loading"
    matSuffix
    mat-icon-button
    aria-label="Clear"
    (click)="handleClearSelection($event)"
  >
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
