import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { AssetModel } from '@asset/models/asset.model';
import { Router } from '@angular/router';
import { ProjectStore } from '@ddb/state/project.store';
import { ProjectModel } from '@ddb/models/project.model';
import { HttpDataState } from '@core/types/http-data-state';
import { Observable } from 'rxjs';

@Component({
  selector: 'zero-asset-card[asset]',
  templateUrl: './asset-card.component.html',
  styleUrls: ['./asset-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetCardComponent implements OnInit {
  @Input() public asset!: AssetModel;
  public flipped = false;

  constructor(
    private readonly projectStore: ProjectStore,
    private readonly router: Router,
  ) {}

  public project(): Observable<HttpDataState<ProjectModel>> {
    return this.projectStore.selectById(this.asset.project_number);
  }

  public ngOnInit(): void {
    this.projectStore.loadById(this.asset.project_number);
  }

  public navigateToAssetProfile(): void {
    this.router.navigate(['asset', this.asset.id]);
  }
}
