import { Injectable } from '@angular/core';
import { SubsystemName } from '@core/models/subsystem';
import { SystemName } from '@asset/enums/system-name';
import { AggregatedCarbonStage } from '@core/enums/aggregated-carbon-stage';
import { CarbonStage } from '@core/enums/carbon-stage';
import { AnyCarbonStage } from '@core/types/any-carbon-stage';

@Injectable({ providedIn: 'root' })
export class UtilsService {
  convertObjectPropsToApiDateFormat<T extends object>(
    obj: T,
    keys: Array<keyof T>,
  ) {
    (Object.keys(obj) as Array<keyof T>).forEach((key) => {
      if (keys.includes(key)) {
        (obj as any)[key] = new Date((obj as any)[key])
          .toISOString()
          .split('T')[0];
      }
    });
    return obj as T;
  }

  safeCSSClassName(className: string): string {
    return className
      .toString()
      .replace(/&/g, 'and')
      .replace(/ /g, '-')
      .replace(/\//g, '-')
      .toLowerCase()!;
  }

  getSubsystemsNames(systemName: SystemName) {
    switch (systemName) {
      case SystemName.SubStructure:
        return [
          SubsystemName.BasementFrame,
          SubsystemName.Foundation,
          SubsystemName.BasementPerimeter,
        ];
      case SystemName.SuperStructure:
        return [
          SubsystemName.Floorplate,
          SubsystemName.VerticalStructure,
          SubsystemName.Stability,
          SubsystemName.Roof,
          SubsystemName.Stairs,
        ];
      case SystemName.MechanicalServices:
        return [
          SubsystemName.Heating,
          SubsystemName.Cooling,
          SubsystemName.Ventilation,
          SubsystemName.Process,
        ];
      case SystemName.ElectricalServices:
        return [
          SubsystemName.Electrical,
          SubsystemName.Telecomms,
          SubsystemName.Lighting,
          SubsystemName.Renewables,
          SubsystemName.VerticalTransport,
          SubsystemName.Security,
        ];
      case SystemName.PublicHealthAndHydraulics:
        return [
          SubsystemName.HotWater,
          SubsystemName.ColdWater,
          SubsystemName.WasteWater,
          SubsystemName.Fire,
          SubsystemName.Fuel,
          SubsystemName.Specialist,
        ];
      case SystemName.BuildingEnvelope:
        return [SubsystemName.Facade, SubsystemName.RoofFinishes];
      case SystemName.SpacePlan:
        return [
          SubsystemName.Partitions,
          SubsystemName.FloorFinishes,
          SubsystemName.CeilingFinishes,
          SubsystemName.WallFinishes,
          SubsystemName.ArchitecturalMetalwork,
        ];
    }
  }

  filter(
    items: Array<{ [key: string]: any }>,
    term: string | null | undefined,
    excludes: any = [],
  ): Array<{ [key: string]: any }> {
    if (!term || !items) return items;
    const toCompare = term.toLowerCase();

    const checkItem = (item: any, term: string) => {
      if (
        typeof item === 'string' &&
        item.toString().toLowerCase().includes(toCompare)
      ) {
        return true;
      }

      for (const property in item) {
        if (
          item[property] === null ||
          item[property] == undefined ||
          excludes.includes(property)
        ) {
          continue;
        }
        // special conditions for confidential/public
        // and active/inactive
        // search terms as condition property is a
        // boolean value
        // confidential
        if (
          property === 'confidential' &&
          'confidential'.includes(toCompare) &&
          item[property]
        ) {
          return true;
        }
        // public
        if (
          property === 'confidential' &&
          'public'.includes(toCompare) &&
          !item[property]
        ) {
          return true;
        }
        // active
        if (
          property === 'active' &&
          'active'.includes(toCompare) &&
          item[property]
        ) {
          return true;
        }
        // inactive
        if (
          property === 'active' &&
          toCompare.startsWith('in') &&
          !item[property]
        ) {
          return true;
        }
        // all other properties
        if (typeof item[property] === 'object') {
          if (checkItem(item[property], term)) {
            return true;
          }
        } else if (
          item[property].toString().toLowerCase().includes(toCompare)
        ) {
          return true;
        }
      }
      return false;
    };

    const filteredItems = items.filter(function (item) {
      return checkItem(item, term);
    });
    return filteredItems;
  }

  sortByBoolean<T extends Record<string, any>>(
    values: Array<T>,
    column: string,
    order: 'asc' | 'dsc',
    parentKey?: string[],
  ) {
    const sortedValues = values.sort((a, b) => {
      const aValue: string = parentKey
        ? this.getNestedProperty(a, parentKey)[column]
        : a[column];
      const bValue: string = parentKey
        ? this.getNestedProperty(b, parentKey)[column]
        : b[column];
      if (aValue === undefined || bValue === undefined) return 0;
      if (aValue === bValue) return 0;
      return aValue < bValue ? -1 : 1;
    });
    return order === 'asc' ? sortedValues : sortedValues.reverse();
  }

  sortByString<T extends Record<string, any>>(
    values: Array<T>,
    column: string,
    order: 'asc' | 'dsc',
    parentKey?: string[],
  ) {
    const sortedValues = values.sort((a, b) => {
      const aValue: string = parentKey
        ? this.getNestedProperty(a, parentKey)[column]
        : a[column];
      const bValue: string = parentKey
        ? this.getNestedProperty(b, parentKey)[column]
        : b[column];
      if (aValue === undefined || bValue === undefined) return 0;
      if (aValue === bValue) return 0;
      return aValue < bValue ? -1 : 1;
    });
    return order === 'asc' ? sortedValues : sortedValues.reverse();
  }

  sortByDate<T extends Record<string, any>>(
    values: Array<T>,
    column: string,
    order: 'asc' | 'dsc',
    parentKey?: string[],
  ) {
    const sortedValues = values.sort((a, b) => {
      const aValue: string = parentKey
        ? this.getNestedProperty(a, parentKey)[column]
        : a[column];
      const bValue: string = parentKey
        ? this.getNestedProperty(b, parentKey)[column]
        : b[column];
      const aDate = new Date(aValue).getTime();
      const bDate = new Date(bValue).getTime();
      if (
        aValue === undefined ||
        aDate === undefined ||
        bValue === undefined ||
        bDate === undefined
      )
        return 0;
      return bDate - aDate;
    });
    return order === 'asc' ? sortedValues : sortedValues.reverse();
  }

  private getNestedProperty(object: any, prop: string[]) {
    return prop.reduce(
      (object: any, key: string) =>
        object && object[key] !== 'undefined' ? object[key] : undefined,
      object,
    );
  }

  isInstance<T>(obj: T | unknown, keys: Array<keyof T>): obj is T {
    for (const key of keys) {
      if ((obj as T)[key] === undefined) return false;
    }
    return true;
  }

  isDefined<T>(value: T | null | undefined): value is T {
    return value !== null && value !== undefined;
  }

  carbonParameters(stages: AnyCarbonStage[] = []) {
    return new Set([
      ...new Set(
        stages.includes(AggregatedCarbonStage.A1_A5 as AnyCarbonStage)
          ? ([AggregatedCarbonStage.A1_A5] as AnyCarbonStage[])
          : ([
              CarbonStage.A1_A3,
              CarbonStage.A4,
              CarbonStage.A5,
            ] as AnyCarbonStage[]),
      ),
      ...new Set(
        stages.includes(AggregatedCarbonStage.B1_B5 as AnyCarbonStage)
          ? ([AggregatedCarbonStage.B1_B5] as AnyCarbonStage[])
          : ([
              CarbonStage.B1,
              CarbonStage.B2,
              CarbonStage.B3,
              CarbonStage.B4,
              CarbonStage.B5,
            ] as AnyCarbonStage[]),
      ),
      ...new Set(
        stages.includes(AggregatedCarbonStage.B6_B7 as AnyCarbonStage)
          ? ([AggregatedCarbonStage.B6_B7] as AnyCarbonStage[])
          : ([CarbonStage.B6, CarbonStage.B7] as AnyCarbonStage[]),
      ),
      ...new Set(
        stages.includes(AggregatedCarbonStage.C1_C4 as AnyCarbonStage)
          ? ([AggregatedCarbonStage.C1_C4] as AnyCarbonStage[])
          : ([
              CarbonStage.C1,
              CarbonStage.C2,
              CarbonStage.C3,
              CarbonStage.C4,
            ] as AnyCarbonStage[]),
      ),
      CarbonStage.BIOGENIC,
      CarbonStage.D,
    ]);
  }

  getCSSVar(varName: string) {
    const style = getComputedStyle(document.body);
    return style.getPropertyValue(varName);
  }
}
