import { HumanReadableOption } from '@core/types/human-readable-option';

export enum AssetStatus {
  Brief = 'Brief',
  Concept = 'Concept',
  Detail = 'Detail',
  Construction = 'Construction',
  InUse = 'InUse',
}

export function HumanReadableOption(
  status: AssetStatus,
): HumanReadableOption<AssetStatus> {
  switch (status) {
    case AssetStatus.Brief:
      return { value: AssetStatus.Brief, display_name: AssetStatus.Brief };
    case AssetStatus.Concept:
      return { value: AssetStatus.Concept, display_name: AssetStatus.Concept };
    case AssetStatus.Detail:
      return { value: AssetStatus.Detail, display_name: AssetStatus.Detail };
    case AssetStatus.Construction:
      return {
        value: AssetStatus.InUse,
        display_name: AssetStatus.Construction,
      };
    case AssetStatus.InUse:
      return { value: AssetStatus.InUse, display_name: 'In Use' };
  }
}
