import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'projectNumber' })
export class ProjectNumberPipe implements PipeTransform {
  transform(projectNumber: string): string {
    switch (projectNumber.length) {
      case 6:
        return `${projectNumber}-00`;
      case 8:
        return `${projectNumber.substring(0, 6)}-${projectNumber.substring(
          6,
          8,
        )}`;
      default:
        return projectNumber;
    }
  }
}
