import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import {
  NotificationModalComponent,
  Data,
  CONFIG,
} from '@core/modals/notification-modal/notification-modal.component';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(private readonly snackBar: MatSnackBar) {}

  public open(data: Data): MatSnackBarRef<NotificationModalComponent> {
    return this.snackBar.openFromComponent<NotificationModalComponent, Data>(
      NotificationModalComponent,
      { ...CONFIG, data },
    );
  }
}
