<ng-container *ngIf="content.length < limit">
  <p>{{ content }}</p>
</ng-container>
<ng-container *ngIf="content.length > limit">
  <ng-container *ngIf="readMore">
    <p>{{ content }}</p>
    <strong *ngIf="content.length > limit" (click)="readMore = false"
      >Read Less</strong
    >
  </ng-container>
  <ng-container *ngIf="!readMore">
    <p>{{ content | slice: 0 : limit }}...</p>
    <strong *ngIf="content.length > limit" (click)="readMore = true"
      >Read More</strong
    >
  </ng-container>
</ng-container>
