import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'zero-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadMoreComponent {
  @Input() content!: string;
  @Input() limit = 100;
  readMore = false;
}
