import { InjectionToken, inject } from '@angular/core';
import { ZERO_BASEURL } from '@core/tokens/zero-baseurl.token';

export const GROUPS_OF_GROUPS_ENDPOINT = new InjectionToken<string>(
  'GROUPS_OF_GROUPS_ENDPOINT',
  {
    providedIn: 'root',
    factory: () => `${inject(ZERO_BASEURL)}/ads/groups-of-groups`,
  },
);
