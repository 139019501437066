import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Optional,
} from '@angular/core';
import { MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';

export const ID = 'loading-modal';

export const CONFIG: MatDialogConfig = { disableClose: true, id: ID };

export type Operation = symbol;

export type Data = {
  operation: symbol;
  message?: string;
};

export type Result = undefined;

@Component({
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingModalComponent {
  constructor(
    public ref: ChangeDetectorRef,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: Data,
  ) {}
}
