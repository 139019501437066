import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect } from '@angular/material/select';

export type Value<T> = T;

@Component({
  selector: 'zero-loading-select-control[label]',
  templateUrl: './loading-select-control.component.html',
  styleUrls: ['./loading-select-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LoadingSelectControlComponent),
      multi: true,
    },
  ],
})
export class LoadingSelectControlComponent<T> implements ControlValueAccessor {
  @Input() public label = '';
  @Input() public loading = false;
  @Input() public disabled = false;
  @Input() public required = false;
  @Input() public clearable = false;
  @Input() public options: Value<T>[] = [];
  @Input() public formatOption(option: T): string {
    return JSON.stringify(option);
  }
  @ViewChild('select') public select!: MatSelect;
  private onChange?: (value: Value<T> | null) => void;
  private onTouched?: () => void;

  public handleBlur(): void {
    this.onTouched?.();
  }

  public handleValueChange(value: Value<T> | null): void {
    this.onTouched?.();
    this.onChange?.(value);
  }

  public handleClearSelection(event: Event): void {
    event.stopPropagation();
    this.select.writeValue(null);
    this.onTouched?.();
    this.onChange?.(null);
  }

  public registerOnChange(onChange: (value: Value<T> | null) => void): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  public writeValue(obj: T | null): void {
    this.select?.writeValue(obj);
  }
}
